const avatarColors = [
    "Aquamarine",
    "BlueViolet",
    "Brown",
    "BurlyWood",
    "CadetBlue",
    "Coral",
    "Chocolate",
    "CornflowerBlue",
    "Crimson",
    "DarkGoldenRod",
    "DarkCyan",
    "DarkBlue",
    "DarkGreen",
    "DarkGrey",
    "DarkOliveGreen",
    "DarkMagenta",
    "DarkKhaki",
    "DarkOrange",
    "DarkOrchid",
    "DarkRed",
    "DarkSalmon",
    "DarkSeaGreen",
    "DarkSlateBlue",
    "DarkSlateGrey",
    "DarkViolet",
    "DimGrey",
    "FireBrick",
    "IndianRed",
    "Indigo",
    "LightSeaGreen",
    "LightCoral",
    "LightSlateGrey",
    "MediumAquaMarine",
    "Maroon",
    "MediumPurple",
    "MediumTurquoise",
    "Navy",
    "OliveDrab",
    "PaleVioletRed",
    "Peru",
    "RebeccaPurple",
    "SteelBlue",
    "Teal",
    "Tan",
    "SeaGreen",
];

export const getAvatarColor = (fullName) => {
  let hash = 0;
  for (let i = 0; i < fullName.length; i++) {
    let character = fullName.charCodeAt(i);
    hash = ((hash<<5)-hash) + character;
    hash = hash & hash; // convert to 32 bit integer
  }
  const index = Math.abs(hash) % avatarColors.length;
  return avatarColors[index];
};

