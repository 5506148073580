import { makeStyles } from "@material-ui/core";

const loaderDot = {
    animation: "$toggle 0.6s linear infinite",
    fontWeight: 500,
    fontSize: "5.2rem"
};

export default makeStyles((theme) => ({
    "@keyframes toggle": {
        "0%": {
            opacity: 1
        },
        "100%": {
            opacity: 0
        }
    },
    loader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        padding: "20px",
        color: theme.palette.primary.main,
        fontSize: "1.5rem",
        transform: "translate(-50%, -50%)"
      },
    loaderDot1: {
        ...loaderDot,
        animationDelay: "0s",
    },
    loaderDot2: {
        ...loaderDot,
        animationDelay: "0.2s",
    },
    loaderDot3: {
        ...loaderDot,
        animationDelay: "0.4s",
    },

}))
