import React from 'react';
import './Loader.scss';
const Loading = () => {
    return (
        <div className="column mt-4">
            <div className="loader-container mx-auto my-auto animation-6">
                <div className="shape shape1"/>
                <div className="shape shape2"/>
                <div className="shape shape3"/>
                <div className="shape shape4"/>
            </div>
        </div>
    );
};

export default Loading;
