import React, { useState } from "react";
import { Box, Button, Collapse, Typography } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import moment from "moment";

import { formatAmount, formatDate } from "../../../utils/converters";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import { enMonths } from "../../../utils/constants";

function ChangeLogBlock({ author, date, changeLog }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [showMessageBody, setShowMessageBody] = useState(false);
    const complexObjects = ["invoiceOrder", "invoiceAttachments", "invoicePayments", "email", "criteria"];

    const valueExists = (val) => {
        if (val === null || val === undefined) return false;
        if (typeof val === "string" && val.trim().length === 0) return false;

        return true;
    };
    return (
        <Box my={1}>
            <Box
                p={1}
                bgcolor="#dfdfdf"
                display="flex"
                justifyContent="space-between"
                borderRadius="4px 4px 0 0"
            >
                <Typography variant="subtitle2">{author}</Typography>
                <Typography variant="subtitle2" color="textSecondary">
                    {moment(date).format("DD/MM/YYYY • H:mm")}
                </Typography>
            </Box>
            <Box p={1} border="2px solid #dfdfdf" borderRadius="0 0 4px 4px">
                {Object.keys(changeLog)
                    .filter(
                        (k) =>
                            !complexObjects.includes(k) &&
              (valueExists(changeLog[k].prev) || valueExists(changeLog[k].new))
                    )
                    .map((k, index) => (
                        <div key={index}>
                            <Typography variant="body2" display="inline">
                                {t(k)} :{" "}
                            </Typography>
                            {valueExists(changeLog[k].prev) && (
                                <Typography
                                    variant="body2"
                                    display="inline"
                                    className={classes.prev}
                                >
                                    {typeof changeLog[k].prev === "number" ? formatAmount(changeLog[k].prev) : changeLog[k].prev}
                                </Typography>
                            )}
                            {valueExists(changeLog[k].prev) &&
                valueExists(changeLog[k].new) && (
                                <ArrowRightAltIcon fontSize="small" />
                            )}
                            {valueExists(changeLog[k].new) && (
                                <Typography
                                    variant="body2"
                                    display="inline"
                                    className={classes.new}
                                >
                                    {typeof changeLog[k].new === "number" ? formatAmount(changeLog[k].new) : changeLog[k].new}
                                </Typography>
                            )}
                        </div>
                    ))}
                {changeLog.invoicePayments &&
          (changeLog.invoicePayments.prev || changeLog.invoicePayments.new) && (
                    <>
                        <Typography variant="body2" display="inline">
                            {t("payments")} :{" "}
                        </Typography>
                        {changeLog.invoicePayments.new && (
                            <Typography
                                variant="body2"
                                className={classes.new}
                                gutterBottom
                            >
                                <AddOutlinedIcon
                                    fontSize="small"
                                    className={classes.itemIcon}
                                />
                                {(()=>{
                                    const [amount, date, exchangeRate, invoiceCurrency, defaultCurrency] = changeLog.invoicePayments.new.split("|");
                                    const isCurrencySame = invoiceCurrency === defaultCurrency;
                                    let output = `${t("amount")}: ${formatAmount(Number(amount))} ${invoiceCurrency !== undefined ? invoiceCurrency : ""}`;
                                    if(!isCurrencySame && exchangeRate && invoiceCurrency && defaultCurrency){
                                        output += ` | ${t("exchangeRate")}: ${Number(exchangeRate).toFixed(3)} | ${t("amount")}: ${formatAmount(Number(amount * exchangeRate))} ${defaultCurrency}`;
                                    }
                                    output += ` | ${t("date")}: ${date}`;
                                    return output;
                                })()}
                            </Typography>
                        )}
                    </>
                )}
                {changeLog.invoiceOrder &&
          changeLog.invoiceOrder.reference &&
          (changeLog.invoiceOrder.reference.prev ||
            changeLog.invoiceOrder.reference.new) && (
                    <div>
                        <Typography variant="body2" display="inline">
                            {t("order_reference")} :{" "}
                        </Typography>
                        {changeLog.invoiceOrder.reference.prev && (
                            <Typography
                                variant="body2"
                                display="inline"
                                className={classes.prev}
                            >
                                {changeLog.invoiceOrder.reference.prev}
                            </Typography>
                        )}
                        {changeLog.invoiceOrder.reference.prev &&
                changeLog.invoiceOrder.reference.new && (
                            <ArrowRightAltIcon fontSize="small" />
                        )}
                        {changeLog.invoiceOrder.reference.new && (
                            <Typography
                                variant="body2"
                                display="inline"
                                className={classes.new}
                            >
                                {changeLog.invoiceOrder.reference.new}
                            </Typography>
                        )}
                    </div>
                )}
                {changeLog.invoiceOrder && changeLog.invoiceOrder.orderItems && (
                    <>
                        <Typography variant="body2">{t("order_items")} : </Typography>
                        {changeLog.invoiceOrder.orderItems.removed &&
              changeLog.invoiceOrder.orderItems.removed.map((item, index) => (
                  <div key={index}>
                      <Typography
                          variant="body2"
                          className={classes.prev}
                          gutterBottom
                      >
                          <RemoveOutlinedIcon
                              fontSize="small"
                              className={classes.itemIcon}
                          />
                          {`${t("reference")}: ${item.reference || "N/A"} | ${t(
                              "description"
                          )}: ${item.description} | ${t("quantity")}: ${
                              item.quantity
                          } | ${t("unit_price")}: ${item.unitPrice}`}
                      </Typography>
                  </div>
              ))}
                        {changeLog.invoiceOrder.orderItems.added &&
              changeLog.invoiceOrder.orderItems.added.map((item, index) => (
                  <div key={index}>
                      <Typography
                          variant="body2"
                          className={classes.new}
                          gutterBottom
                      >
                          <AddOutlinedIcon
                              fontSize="small"
                              className={classes.itemIcon}
                          />
                          {`${t("reference")}: ${item.reference || "N/A"} | ${t(
                              "description"
                          )}: ${item.description} | ${t("quantity")}: ${
                              item.quantity
                          } | ${t("unit_price")}: ${item.unitPrice}`}
                      </Typography>
                  </div>
              ))}
                    </>
                )}
                {changeLog.invoiceAttachments && (
                    <>
                        <Typography variant="body2">{t("attachments")} : </Typography>
                        {changeLog.invoiceAttachments.removed &&
              changeLog.invoiceAttachments.removed.map((item, index) => (
                  <div key={index}>
                      <Typography
                          variant="body2"
                          className={classes.prev}
                          gutterBottom
                      >
                          <RemoveOutlinedIcon
                              fontSize="small"
                              className={classes.itemIcon}
                          />
                          {`${t("invoiceAttachmentName")}: ${
                              item.invoiceAttachmentName
                          } | ${t("invoiceAttachmentType")}: ${
                              item.invoiceAttachmentType
                          }`}
                      </Typography>
                  </div>
              ))}
                        {changeLog.invoiceAttachments.added &&
              changeLog.invoiceAttachments.added.map((item, index) => (
                  <div key={index}>
                      <Typography
                          variant="body2"
                          className={classes.new}
                          gutterBottom
                      >
                          <AddOutlinedIcon
                              fontSize="small"
                              className={classes.itemIcon}
                          />
                          {`${t("invoiceAttachmentName")}: ${
                              item.invoiceAttachmentName
                          } | ${t("invoiceAttachmentType")}: ${
                              item.invoiceAttachmentType
                          }`}
                      </Typography>
                  </div>
              ))}
                    </>
                )}
                {changeLog.email &&
                  <>
                      <Typography variant="body2">{(changeLog.email.type === "ISSUE") ? t("issue_email_sent") : t("reminder_email")} </Typography>
                      <Typography variant="body2">{t("recipient")} : {changeLog.email.recipient}</Typography>
                      {changeLog.email.cciEmail && <Typography variant="body2">{t("Cc")} : {changeLog.email.ccEmails.join("; ")}</Typography>}
                      {changeLog.email.cciEmail && <Typography variant="body2">{t("bcc")} : {changeLog.email.cciEmail}</Typography>}
                      <Typography variant="body2">{t("object")} : {changeLog.email.object}</Typography>
                      <Typography variant="body2">
                          {t("message")} : 
                          <Button 
                              size="small"
                              variant="outlined"
                              className={classes.messageBodyBtn}
                              onClick={() => setShowMessageBody(!showMessageBody)}
                          >
                              <Typography className={classes.emailBodyBtnText} variant="body2">{!showMessageBody ? <><ArrowDropDownIcon />{t("show")}</> : <><ArrowDropUpIcon /> {t("hide")}</>}</Typography>
                          </Button>
                      </Typography>
                      <Collapse in={showMessageBody}>
                          <Typography variant="body2" style={{marginTop: "0.5rem"}} dangerouslySetInnerHTML={{__html: changeLog.email.body}}></Typography>
                      </Collapse>
                  </>
                }
                {
                    changeLog.criteria &&
                    <>
                        <Typography variant="body2">{t("export_criteria")}</Typography>
                    
                        {Object.entries(JSON.parse(changeLog.criteria)).map(([key, val]) => {
                            if((val === null) || (val.length === 0) || (val === "")) return "";
                            let value = val;
                            if(Array.isArray(value)){
                                value = value.map(item => t(item)).join(", ");
                            }

                            if(moment(value).isValid() && (key !== "year") && (key !== "month")){
                                value = formatDate(value);
                            }

                            if(key === "month"){
                                value = enMonths[value];
                            }
                            
                            return (
                                <div key={key} >
                                    <Typography variant="body2">{t(key)} : {value}</Typography>
                                </div>
                            );
                        })}
                    </>
                }
            </Box>
        </Box>
    );
}

export default ChangeLogBlock;
