import { onError, onSuccess } from "../../../../utils/http";
import { errorNotification, successNotification } from "../../../../utils/notification";
import {
    GET_FINANCIAL_INVOICE_COMPANY,
    GET_INVOICING_CONFIG,
    PUT_FINANCIAL_INVOICE_COMPANY,
    PUT_INVOICING_CONFIG,
    SET_FINANCIAL_INVOICE_COMPANY,
    SET_INVOICING_CONFIG
} from '../../constants';
import { FINANCIAL_INVOICING_ENDPOINT } from "../../constants/endpoint";

export const getInvoicingConfig = () => {
    return {
        type: GET_INVOICING_CONFIG,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoicingConfig`,
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setInvoicingConfig(response.data));
                return response;
            })
        },
    };
};

export const putInvoicingConfig = (data) => {
    return {
        type: PUT_INVOICING_CONFIG,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoicingConfig`,
            method: "put",
            data: data
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "update_success");
                store.dispatch(setInvoicingConfig(response.data));
                return response;
            }),
            onError: onError(({ response }) => {
                errorNotification('', "update_error");
                return response;
            })
        }
    };
};

export const setInvoicingConfig = (data) => {
    return {
        type: SET_INVOICING_CONFIG,
        payload: data
    };
};

export const getInvoiceCompany = () => {
    return {
        type: GET_FINANCIAL_INVOICE_COMPANY,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoiceCompany`,
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setInvoiceCompany(response.data));
                return response;
            })
        }
    };
};

export const putInvoiceCompany = (data) => {
    return {
        type: PUT_FINANCIAL_INVOICE_COMPANY,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoiceCompany`,
            method: 'put',
            data: data
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "update_success");
                store.dispatch(setInvoiceCompany(response.data));
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "update_error");
                throw error;
            })
        }
    };
};

export const setInvoiceCompany = (data) => {
    return {
        type: SET_FINANCIAL_INVOICE_COMPANY,
        payload: data
    };
};