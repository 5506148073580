import { alpha, makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    emptyPdfContainerIcon: {
        width: "100px",
        margin: theme.spacing(-2, 0, 2, 0)
    },
    emptyPdfContainer: {
        margin: theme.spacing(2, 0),
        border: `5px solid ${alpha(theme.palette.primary.main, 0.5)}`,
        backgroundColor: alpha(theme.palette.primary.main, 0.06),
        borderRadius: "4px",
        height: "100%",
        width: "100%"
    },
}));