import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    input: {
        backgroundColor: "#fbfbfb",
        borderRadius: 4,
        '&:focus': {
            backgroundColor: "#fbfbfb",
            borderRadius: 4
        },
        minWidth: "50px"
    },
    month: {
        '&:first-letter': {
            textTransform: "capitalize"
        }
    }
}));