import React from 'react';
import routes from './routes';
import { RoutingWithAuth } from '../../../utils/routing';

/**
 * Base component for managing routes under /analytics
 * /analytics is the home route for this component
 * /analytics/[valid routes] should redirect to the corresponding component in pages src/views/pages where further routing should be managed
 * /analytics/[invalid routes] should redirect to 404 or home route
 */
function AnalyticsBase(props) {

    return (
        <RoutingWithAuth
            routes={routes}
            basePath="/analytics"
            homePath="/analytics/aged-trial-balance"
            {...props}
        />
    );
}

export default AnalyticsBase;