import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withStyles } from '@material-ui/core/styles';
import useStyles from './style';
import clsx from 'clsx';

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#D5E6F7",
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        boxShadow: theme.shadows[1]
    },
    arrow: {
        color: theme.palette.grey[500]
    }
}))(Tooltip);


function InfoTooltip({ text, style, className }) {
    const classes = useStyles();

    return (
        <CustomTooltip
            title={
                <Typography variant="caption">
                    {text}
                </Typography>
            }
            arrow
            interactive
        >
            <InfoOutlinedIcon style={style} className={clsx(classes.icon, className)} />
        </CustomTooltip>
    );
}

export default InfoTooltip;
