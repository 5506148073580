import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    breadcrumbsTitle: {
        fontWeight: 'normal'
    },
    breadcrumbsPath: {
        marginTop: theme.spacing(2), 
    }
}));