import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { PrivateRoute } from '../routes';


export const getTransformedRoutes = (routes) => {
    const transformedRoutes = [];
    routes.forEach(
        ({
            name,
            path,
            component,
            permissions,
            roles,
            exact
        }) => {
            transformedRoutes.push({
                name,
                path,
                component,
                permissions,
                roles,
                exact
            });
        }
    );
    return transformedRoutes;
};

export const RoutingWithAuth = ({basePath, homePath, notFoundPath, routes, ...props}) => {
    const { t } = useTranslation();
    const user = useSelector(({ Auth }) => Auth.user);
    
    return (
        <Switch>
            {/* HOME */}
            {
                homePath &&
                homePath !== basePath && 
                <Redirect 
                    exact={true}
                    path={basePath}
                    to={homePath}
                />
            }

            {/* ROUTING */}
            {
                getTransformedRoutes(routes).map(
                    (
                        {
                            name,
                            path,
                            component,
                            permissions,
                            roles,
                            exact
                        },
                        index
                    ) => (
                        <PrivateRoute
                            {...props}
                            key={index}
                            path={path}
                            exact={exact}
                            name={t(name)}
                            user={user}
                            history={props.history}
                            component={component}
                            permission={permissions}
                            roles={roles}
                        />
                    )
                )
            }

            {/* 404 */}
            <Redirect 
                exact={false}
                path={basePath}
                to={notFoundPath ?? basePath}
            />
        </Switch>
    );
};