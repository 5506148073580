import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    paper: {
        width: "450px",
        padding: theme.spacing(0, 2, 2, 2)
    },
    prev: {
        backgroundColor: 'rgba(255, 0, 0, 0.18)',
        borderRadius: "2px",
        padding: "0 3px"
    },
    new: {
        backgroundColor: 'rgba(0, 255, 0, 0.18)',
        borderRadius: "2px",
        padding: "0 3px"
    },
    itemIcon: {
        margin: "0 8px 0 -3px",
        color: "#555"
    },
    messageBodyBtn: {
        padding: "0.1rem 0",
        paddingRight: "0.4rem",
        marginLeft: "0.5rem"
    },
    emailBodyBtnText: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));