import { getInitialPagination } from "../../../../utils/common-state";
import { CLEAR_FINANCIAL_PAYMENTS_FILTER, CLEAR_FINANCIAL_PAYMENTS_PAGINATION, CLEAR_FINANCIAL_PAYMENTS_SORT, SET_FINANCIAL_MONTH_PAYMENTS, SET_FINANCIAL_PAYMENTS, SET_FINANCIAL_PAYMENTS_FILTER, SET_FINANCIAL_PAYMENTS_PAGINATION, SET_FINANCIAL_PAYMENTS_SORT } from "../../constants";

const initialState = {
    list: {
        sort: JSON.parse(localStorage.getItem("payments"))?.sort || {
            col: { id: 0 },
            type: "desc",
        },
        page: 0,
        size: getInitialPagination(false).size,
        data: [],
    },
    config: {
        pagination: JSON.parse(localStorage.getItem("payments"))?.pagination || getInitialPagination(false) 
    },
    filter: {
        responsables: [],
        clients: [],
        invoicingEntities: [],
        month: null,
        year: new Date().getFullYear(),
        projects: [],
        projectTypes: [],
        afterDate: null,
        beforeDate: null,
        ...JSON.parse(localStorage.getItem("payments"))?.filter || {}
    },
    monthPayments: {
        list: {
            sort: {
                col: { id: 0 },
                type: "desc",
            },
            data: [],
        },
        selected: null,
    },
    searchResults: {
        list: {
            maxLength: 0,
            data: []
        }
    }
};

export default (state = initialState, action) => {
    switch(action.type){
        case SET_FINANCIAL_PAYMENTS:
            return setPayments(state, action.payload);
        case SET_FINANCIAL_MONTH_PAYMENTS:
            return setMonthPayments(state, action.payload);
        case SET_FINANCIAL_PAYMENTS_PAGINATION:
            return setPaymentsPagination(state, action.payload);
        case CLEAR_FINANCIAL_PAYMENTS_PAGINATION:
            return clearPaymentsPagination(state, action.payload);
        case SET_FINANCIAL_PAYMENTS_FILTER:
            return setPaymentsFilter(state, action.payload);
        case SET_FINANCIAL_PAYMENTS_SORT:
            return setPaymentsSort(state, action.payload);
        case CLEAR_FINANCIAL_PAYMENTS_FILTER:
            return clearPaymentsFilter(state);
        case CLEAR_FINANCIAL_PAYMENTS_SORT:
            return clearPaymentsSort(state);
        default: 
            return state;
    }
};

const setPayments = (state, data) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(data.page),
                size: parseInt(data.size),
                maxLength: parseInt(data.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(data.page),
            size: parseInt(data.size),
            data: data.payments
        }
        
    };
};

const setMonthPayments = (state, data) => {
    return {
        ...state,
        monthPayments: {
            ...state.monthPayments,
            list: {
                ...state.monthPayments.list,
                data: data
            }
        }
    };
};

const setPaymentsPagination = (state, pagination) => {
    const localStorageData = JSON.parse(localStorage.getItem("payments")) || {};
    localStorage.setItem("payments", JSON.stringify({
        ...localStorageData,
        pagination
    }));
    return {
        ...state,
        config: {
            ...state.config,
            pagination: pagination
        }
    };
};

const clearPaymentsPagination = (state) => {
    const localStorageData = JSON.parse(localStorage.getItem("payments")) || {};
    localStorage.setItem("payments", JSON.stringify({
        ...localStorageData,
        pagination: {
            ...getInitialPagination(false),
            size: localStorageData?.pagination?.size || getInitialPagination(false).size
        },
    }));
    return {
        ...state,
        config: {
            ...state.config,
            pagination: JSON.parse(localStorage.getItem("payments"))?.pagination || getInitialPagination(false)
        }
    };
};

const setPaymentsFilter = (state, filter) => {
    const localStorageData = JSON.parse(localStorage.getItem("payments")) || {};
    localStorage.setItem("payments", JSON.stringify({
        ...localStorageData,
        filter
    }));
    return {
        ...state,
        filter: {
            ...state.filter,
            ...filter
        }
    };
};

const clearPaymentsFilter = (state) => {
    const localStorageData = JSON.parse(localStorage.getItem("payments")) || {};
    localStorage.setItem("payments", JSON.stringify({
        ...localStorageData,
        filter: initialState.list.filter
    }));
    return {
        ...state,
        filter: initialState.list.filter
    };
};

const setPaymentsSort = (state, sort) => {
    const localStorageData = JSON.parse(localStorage.getItem("payments")) || {};
    localStorage.setItem("payments", JSON.stringify({
        ...localStorageData,
        sort
    }));
    return {
        ...state,
        list: {
            ...state.list,
            sort: sort
        }
    };
};

const clearPaymentsSort = (state) => {
    const localStorageData = JSON.parse(localStorage.getItem("payments")) || {};
    localStorage.setItem("payments", JSON.stringify({
        ...localStorageData,
        sort: {
            ...initialState.invoices.list.sort,
        },
    }));
    return {
        ...state,
        list: {
            ...state.list,
            sort: initialState.list.sort
        }
    };
};