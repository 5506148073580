import { alpha, makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    dropzone: {
        textAlign: "center",
        padding: theme.spacing(4, 2),
        margin: theme.spacing(2, 0),
        border: `2px dashed ${theme.palette.primary.main}`,
        backgroundColor: alpha(theme.palette.primary.main, 0.06),
        borderRadius: "4px",
        boxShadow: theme.shadows[25],
        cursor: "pointer"
    },
    uploadContainer: {
        padding: theme.spacing(2, 0),
        margin: theme.spacing(2, 0)
    },
    pdfContainer: {
        margin: theme.spacing(2, 0),
        borderRadius: "4px",
        height: "100%",
        width: "100%"
    },
    configContainer: {
        padding: theme.spacing(1, 2),
        margin: theme.spacing(2, 0),
        boxShadow: theme.shadows[25]
    },
}));