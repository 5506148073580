import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    dialogPaper: {
        width: "85%",
        height: "90%"
    },
    dialogContent: {
        "& .MuiDialogContent-root": {
            backgroundColor: "#f0f0f0",
            borderTop: `1px solid #f0f0f0`,
            borderBottom: "1px solid #f0f0f0"
        }
    },
    dialogPdf: {
        "& .MuiDialogContent-root": {
            overflow: "hidden",
            padding: 0
        }
    },
    invoiceStatusChip: {
        borderRadius: "4px",
        margin: theme.spacing(0, 1),
    },
    popover: {
        "& .MuiPopover-paper": {
            padding: theme.spacing(2),
            backgroundColor: "#FFD9D9"
        }
    },
    actionBtnsGroup :{
        width: "20%",
        display: "flex",
        justifyContent : "space-evenly"
    }
}));
