import moment from "moment";

const _percentColors = [
    { pct: 0.0, color: { r: 0x00, g: 0xff, b: 0 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 1.0, color: { r: 0xff, g: 0x00, b: 0 } },
];

export const formatAmount = (amount, currency) => {
    return `${amount?.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1\xA0")} ${currency?.symbol ?? ""}`;
};

export const formatDate = (date) => {
    return moment(date).format("DD/MM/yyyy");
};

export const formatDateTime = (dateTime) => {
    return moment(dateTime).format("DD/MM/yyyy HH:mm:ss");
};

export const capitalize = (str) => {
    return `${str.toString().charAt(0).toUpperCase()}${str
        .toString()
        .slice(1)}`;
};

export const truncateText = (str, maxlength) => {
    return (str.length > maxlength) ?
        str.slice(0, maxlength - 1) + '…' : str;
};

export const getColorFromPercentage = (pct) => {
    for (var i = 1; i < _percentColors.length - 1; i++) {
        if (pct < _percentColors[i].pct) {
            break;
        }
    }
    var lower = _percentColors[i - 1];
    var upper = _percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    };
    return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
};

export const processFormattedAmount = (amount) => {
    return parseFloat(amount.replaceAll(" ","") || 0);
};

const getDefaultValue = (type) => {
    switch(type){
        case "textList":
            return [];
        default:
            return "";
    }
};

export const adjustDefaultValues = (rtable, obj) => {
    rtable.columns.forEach(col => {
        if(obj[col.apiName] === ""){
            obj = {...obj, 
                [col.apiName] : getDefaultValue(col.type)
            };
        }
    });
    return obj;
};