import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        "& .MuiInputBase-root": {
            flexWrap: "nowrap"
        },
        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
            minWidth: "10px"
        }
    },
    paper: {
        boxShadow: theme.shadows[8]
    },
    addButton: {
        borderRadius: 0
    },
    option: {
        padding: theme.spacing(0, 1)
    }
}));