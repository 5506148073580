import React, { useEffect, useState } from 'react';
import {
    Grid,
    MenuItem,
    TextField
} from '@material-ui/core';
import useStyles from './style';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { enMonthsLower } from '../../../utils/constants';
import { useSelector } from 'react-redux';

function FinancialDatePicker({ filter, type }) {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [yearsRange, setYearsRange] = useState([]);
    const invoicesYears = useSelector(({ invoicing }) => invoicing.invoicesYears);

    const handleYearChange = (e) => {
        history.push(`/${type}/${e.target.value}` + (filter.month ? `/${enMonthsLower[filter.month - 1]}` : ""));
    };

    const handleMonthChange = (e) => {
        history.push(`/${type}/${filter.year}` + (e.target.value !== "all" ? `/${e.target.value}` : ""));
    };

    useEffect(() => {
        setYearsRange(invoicesYears);
    }, [invoicesYears]);

    return (
        <Grid container spacing={1}>
            <Grid item>
                <TextField
                    select
                    value={filter.year ? filter.year : ""}
                    variant="outlined"
                    size="small"
                    inputProps={{
                        className: classes.input,
                    }}
                    onChange={e => handleYearChange(e)}
                    fullWidth
                >
                    {
                        yearsRange.map((year, i) =>
                            <MenuItem value={year} key={i}> {year} </MenuItem>
                        )
                    }
                </TextField>
            </Grid>
            <Grid item>
                <TextField
                    select
                    value={filter.month ? enMonthsLower[filter.month - 1] : "all"}
                    variant="outlined"
                    size="small"
                    inputProps={{
                        className: clsx(classes.input, classes.month)
                    }}
                    onChange={handleMonthChange}
                    fullWidth
                >
                    <MenuItem value="all">{t('all_year')}</MenuItem>
                    {
                        enMonthsLower.map((m, i) =>
                            <MenuItem value={enMonthsLower[i]} key={i}>
                                <span className={classes.month}> {t(m)} </span>
                            </MenuItem>
                        )
                    }
                </TextField>
            </Grid>
        </Grid>
    );
}

export default FinancialDatePicker;
