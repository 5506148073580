import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    paper: {
        width: "450px",
        padding: theme.spacing(0, 2, 2, 2)
    },
    prev: {
        backgroundColor: 'rgba(255, 0, 0, 0.18)',
        borderRadius: "2px",
        padding: "0 3px"
    },
    new: {
        backgroundColor: 'rgba(0, 255, 0, 0.18)',
        borderRadius: "2px",
        padding: "0 3px"
    },
    itemIcon: {
        margin: "0 8px 0 -3px",
        color: "#555"
    },
}));