import React, { useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Button, Typography, Paper, Popper, Box, Checkbox } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import { getAllInvoicingClient } from '../../redux/actions/rt';
import { PhotoSizeSelectLargeTwoTone } from '@material-ui/icons';


function InvoicingClientSelect({
    value,
    handleValueChange,
    variant = "standard",
    disabled = false,
    size = "medium",
    inputStyle,
    inputLabel,
    showAddButton = false,
    filtered = false,
    multiselect = false,
    checkmark = false
}) {

    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const filter = useSelector(({ invoicing }) => invoicing.filter);
    const clients = useSelector(({ rt }) =>{
        return rt.invoicingClients;
    });

    useEffect(() => {
        dispatch(getAllInvoicingClient(filtered));
    }, [filter.year, filter.month]); //eslint-disable-line react-hooks/exhaustive-deps

    const PaperComponent = (props) => {
        return <Paper {...props} className={classes.paper}></Paper>;
    };

    const PaperComponentWithAddButton = (props) => { 
        return (
            <Paper className={classes.paper}>
                <div {...props} style={{marginBottom: 0}}>
                </div>
                <Button
                    fullWidth
                    size="small"
                    color="primary"
                    className={classes.addButton}
                    onMouseDown={() => history.push("/administration/reference-tables/clients")}
                >
                    <Typography variant="caption"><AddIcon fontSize="small" /> {t('add')} </Typography>
                </Button>
            </Paper>
        );
    };

    const uniSelect = () => (
        <Autocomplete
            disabled={disabled}
            value={value}
            options={clients && clients.sort((a, b) => a.name.localeCompare(b.name))}
            onChange={(e, val) => handleValueChange(val)}
            autoHighlight
            getOptionLabel={(client) => `${client.name}`}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(client) => `${client.name}`}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    variant={variant}
                    label={inputLabel || t('client_col')}
                    size={size}
                    style={inputStyle}
                />
            )}
            noOptionsText={t('no_client_found')}
            clearText={t('clear')}
            openText={t('open')}
            closeText={t('close')}
            PaperComponent={showAddButton ? PaperComponentWithAddButton : PaperComponent}
        />
    );

    const multiSelect = () => (
        <Autocomplete
            multiple={true}
            disabled={disabled}
            value={value}
            disableCloseOnSelect
            options={clients && clients.sort((a, b) => a.name.localeCompare(b.name))}
            onChange={(e, val) => handleValueChange(val)}
            getOptionLabel={(client) => `${client.name}`}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(client) => (
                <Box style={{flexShrink: 0}} >
                    {
                        checkmark &&
                        <Checkbox 
                            size="small" 
                            checked={value.map(v => v.id).includes(client.id)} 
                        />
                    }
                    {client.name}
                </Box>
            )}
            renderTags={(value) => (
                <Typography 
                    noWrap={true}
                >
                    {value.map(o => o.name).join(' | ')}
                </Typography>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    variant={variant}
                    label={inputLabel || t('client_col')}
                    size={size}
                    style={inputStyle}
                />
            )}
            noOptionsText={t('no_client_found')}
            clearText={t('clear')}
            openText={t('open')}
            closeText={t('close')}
            classes={{ paper: classes.paper, option: classes.option }}
            className={classes.root}
            // PopperComponent={props => (
            //     <Popper
            //         {...props}
            //         style={{minWidth: props.style.width}}
            //         placement="bottom-start"
            //     />
            // )}
        />
    );

    return multiselect
        ? multiSelect()
        : uniSelect();

}

export default InvoicingClientSelect;