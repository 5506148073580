import { SET_ALL_RESPONSABLES, SET_CURRENT_COLLABORATOR } from "../../constants";

const initialState = {
    responsables: [],
    currentCollaborator: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_RESPONSABLES:
            return setAllResponsables(state, action.payload);
        case SET_CURRENT_COLLABORATOR:
            return setCurrentCollaborator(state, action.payload);
        default:
            return state;
    }
};

const setAllResponsables = (state, data) => {
    return {
        ...state,
        responsables: data
    };
};

const setCurrentCollaborator = (state, data) => {
    return {
        ...state,
        currentCollaborator: data
    };
};