import React, { useState } from 'react';
import {
    Grid,
    TextField,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    FormControlLabel,
    Checkbox,
    Box,
    Popper
} from '@material-ui/core';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { importInvoices } from '../../redux/actions/invoicing';
import InfoTooltip from '../../../App/components/InfoTooltip';
import importTemplate from '../../../App/assets/invoices-import-template.xlsx';
import UploadFiles from '../../../App/components/UploadFiles';
import { capitalize } from "../../../utils/converters";
import { Autocomplete } from '@material-ui/lab';

const fields = ["number", "client", "invoicingEntity", "label",
    "project", "responsable", "status", "orderReference", "triggerAction",
    "amountExclTax", "amountInclTax", "expectedDate",
    "issueDate", "expectedSettlementDate", "settlementDate", "currency", "exchangeRate"
];

const INVOICE_XSLX_TEMPLATE = {
    file: null,
    numberCol: "Numéro",
    clientCol: "Client",
    invoicingEntityCol: "Entité à facturer",
    labelCol: "Libellé",
    projectCol: "Projet",
    responsableCol: "Responsable",
    statusCol: "Statut",
    orderReferenceCol: "Référence",
    triggerActionCol: "Déclenchement",
    amountExclTaxCol: "Montant HT",
    amountInclTaxCol: "Montant TTC",
    taxCols: [],
    expectedDateCol: "Date prévisionnelle",
    issueDateCol: "Date d'émission",
    expectedSettlementDateCol: "Date de règlement prévisionnelle",
    settlementDateCol: "Date de règlement",
    currencyCol: "Devise",
    exchangeRateCol: "Taux de change",
    headerRow: 0,
    firstDataRow: 1,
    createClientIfNotFound: true,
    createTaxPercentIfNotFound : true,
    createInvoicingEntityIfNotFound: true
};

function ImportInvoices({ onSubmit }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const taxTypesList = useSelector(({rt})=> rt.taxTypes);

    const [fileData, setFileData] = useState({...INVOICE_XSLX_TEMPLATE,taxCols:taxTypesList.filter(type=>type.isRequired).map(type=>type.label)});

    const handleUpload = (data) => {
        const taxTypes = () =>{
            const includedTaxes = fileData.taxCols;
            const requiredTypes = taxTypesList.filter(type=>type.isRequired).map(type=>type.label);
            requiredTypes.forEach(type => {
                if(!fileData.taxCols.includes(type)){
                    includedTaxes.push(type)
                }
            });
            setFileData({...fileData, taxCols: includedTaxes});
            return includedTaxes.map(tax=>tax.toLowerCase());
        }
        const fileDataLowerFields = {
            ...fileData,
            numberCol: fileData.numberCol.toLowerCase(),
            clientCol: fileData.clientCol.toLowerCase(),
            invoicingEntityCol: fileData.invoicingEntityCol.toLowerCase(),
            labelCol: fileData.labelCol.toLowerCase(),
            projectCol: fileData.projectCol.toLowerCase(),
            responsableCol: fileData.responsableCol.toLowerCase(),
            statusCol: fileData.statusCol.toLowerCase(),
            orderReferenceCol: fileData.orderReferenceCol.toLowerCase(),
            triggerActionCol: fileData.triggerActionCol.toLowerCase(),
            amountExclTaxCol: fileData.amountExclTaxCol.toLowerCase(),
            amountInclTaxCol: fileData.amountInclTaxCol.toLowerCase(),
            taxCols: taxTypes(),
            expectedDateCol: fileData.expectedDateCol.toLowerCase(),
            issueDateCol: fileData.issueDateCol.toLowerCase(),
            expectedSettlementDateCol: fileData.expectedSettlementDateCol.toLowerCase(),
            settlementDateCol: fileData.settlementDateCol.toLowerCase(),
            currencyCol: fileData.currencyCol.toLowerCase(),
            exchangeRateCol: fileData.exchangeRateCol.toLowerCase()
        };
        return dispatch(importInvoices({ ...fileDataLowerFields, file: data }));
    };

    const handleUploadResponse = (response, setMessage, setSeverity, setErrors) => {
        if (response.status === 200) {
            // setSeverity("success");
            // setErrors([]);
            // setMessage(t('import_invoices_created_success', { nbr: response.data.length }));
            onSubmit();
        } else if (response.error.response.status === 400) {
            setSeverity("error");
            const errorsObj = response.error.response.data.data.details;
            setErrors(errorsObj.map(x => {
                const arr = x.message.split(".");
                let key = "";
                let msg = "";
                
                if(x.field === "header") {
                    key = capitalize(t(x.field));
                } else if (x.field.startsWith("row-")) {
                    key = `${capitalize(t("row"))} ${x.field.split("-")[1]}`;
                } else {
                    key = t("unexpected.error");
                }

                if(arr.length === 2) {
                    const first = fileData[arr[0]] ?? t(arr[0]);
                    msg = t(arr[1], {first});
                } else if (arr.length === 3) {
                    const first = fileData[arr[0]] ?? t(arr[0]);
                    const second = fileData[arr[2]] ?? t(arr[2]);
                    msg = t(arr[1], {first, second});
                } else {
                    msg = null;
                }

                return (
                    <>
                        <b>{key}{msg ? ":" : ""} </b> 
                        {
                            msg &&
                            <span dangerouslySetInnerHTML={{ __html: msg }} />
                        }
                    </>
                );
            }));
            if (Object.keys(errorsObj).length === 1) {
                setMessage(t('import_failed_one_error_occured'));
            } else {
                setMessage(t('import_failed_n_errors_occured', { n: Object.keys(errorsObj).length }));
            }
        } else {
            setSeverity("error");
            setErrors({ [t('unknown')]: t(response.error.response.data.data.detail) });
            setMessage(t('import_failed_unknown_error_occured'));
        }    
    };

    const handleError = (err, setMessage, setErrors) => {
        setErrors([]);
        setMessage(t("import_failed_unknown_error_occured"));
    };

    const downloadTemplate = () => {
        const link = document.createElement("a");
        link.href = importTemplate;
        link.download = `invoices-import-template.xlsx`;
        link.click();
    };

    const renderUploadFilesNotes = () => {
        return (
            <Box className={classes.downloadLink}>
                {t('download_template')}:
                <button 
                    className={classes.downloadLink} 
                    onClick={downloadTemplate}
                >
                    {t('template')}.xlsx
                </button>
            </Box>
        );
    };

    return (
        <>
            <UploadFiles 
                types={["xlsx"]}
                onSubmit={handleUpload}
                onResponse={handleUploadResponse}
                onError={handleError}
                renderNotes={renderUploadFilesNotes}
            />
            <Grid container component={Paper} className={classes.configContainer}>
                <Grid container justifyContent="space-between">
                    <Grid item xs>
                        <FormControlLabel
                            checked={fileData.createClientIfNotFound}
                            control={<Checkbox color="primary" />}
                            label={<Typography variant="body2">{t('create_non_existant_clients')}</Typography>}
                            labelPlacement="end"
                            onChange={(e) => setFileData({ ...fileData, createClientIfNotFound: e.target.checked })}
                        />
                    </Grid>
                    <Grid item xs>
                        <FormControlLabel
                            checked={fileData.createInvoicingEntityIfNotFound}
                            control={<Checkbox color="primary" />}
                            label={<Typography variant="body2">{t('create_non_existant_invoice_entities')}</Typography>}
                            labelPlacement="end"
                            onChange={(e) => setFileData({ ...fileData, createInvoicingEntityIfNotFound: e.target.checked })}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Grid item xs>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body2" style={{ marginRight: "30px" }}>{t('header_row')}</Typography>
                            <TextField
                                type="number"
                                size="small"
                                value={fileData.headerRow + 1}
                                onChange={(e) => setFileData({ ...fileData, headerRow: e.target.value - 1 })}
                                style={{ width: "50px" }}
                                InputProps={{ inputProps: { min: 1 } }}
                                error={fileData.headerRow < 0}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body2" style={{ marginRight: "30px" }}>{t('first_data_row')}</Typography>
                            <TextField
                                type="number"
                                size="small"
                                value={fileData.firstDataRow + 1}
                                onChange={(e) => setFileData({ ...fileData, firstDataRow: e.target.value - 1 })}
                                style={{ width: "50px" }}
                                InputProps={{ inputProps: { min: 2 } }}
                                error={fileData.firstDataRow < 1}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid style={{marginTop: "1rem"}} container justifyContent="space-between">
                    <Grid item xs>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body2" style={{ marginRight: "30px" }}>{t("included_tax_types")}</Typography>
                            <Autocomplete
                                    multiple={true}
                                    value={fileData.taxCols}
                                    options={taxTypesList.map(type=>type.label)}
                                    onChange={(e,val)=>setFileData({...fileData,taxCols:val})}
                                    getOptionLabel={(option) => option}
                                    filterSelectedOptions
                                    renderTags={(value) => (
                                        <Typography 
                                            noWrap={true}
                                        >
                                            {value.map(o => o).join(' | ')}
                                        </Typography>
                                    )}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        style={{
                                            backgroundColor: "#fbfbfb",
                                            borderRadius: 4,
                                        }}
                                    />
                                    )}
                                    size='small'
                                    className={classes.root}
                                    classes={{ paper: classes.paper, option: classes.option }}
                                    style={{width:"60%"}}
                                    PopperComponent={props => (
                                        <Popper 
                                            {...props} 
                                            style={{minWidth: props.style.width}} 
                                            placement="bottom-start"
                                        />
                                    )}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs>
                    <FormControlLabel
                            checked={fileData.createTaxPercentIfNotFound}
                            control={<Checkbox color="primary" />}
                            label={<Typography variant="body2">{t('create_non_existant_tax_rates')}</Typography>}
                            labelPlacement="end"
                            onChange={(e) => setFileData({ ...fileData, createTaxPercentIfNotFound: e.target.checked })}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('invoice_fields')}</TableCell>
                                <TableCell>{t('corresponding_column_in_file')} <InfoTooltip text={t('corresponding_column_in_file_tooltip_text')} /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                fields.map(field => (
                                    <TableRow key={field}>
                                        <TableCell><Typography> {t(field)} </Typography></TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                defaultValue={fileData[`${field}Col`] || ""}
                                                onChange={e => setFileData({ ...fileData, [`${field}Col`]: e.target.value.trim() })}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    );
}

export default ImportInvoices;