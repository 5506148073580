import { get } from "lodash";

export const hasPermission = (userPermissions, permissions) => {
    if(!permissions || (Array.isArray(permissions) && permissions.length === 0)) return true;

    if(Array.isArray(permissions)){
        return permissions.every(permission => get(userPermissions, permission, false));
    }

    return get(userPermissions, permissions, false);
};

export const hasRoles = (userRoles, roles) => {
    if(!roles || (Array.isArray(roles) && roles.length === 0)) return true;

    if(Array.isArray(roles)){
        return roles.some(role => userRoles?.includes(role));
    }

    return userRoles?.includes(roles);
};
