import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    listHeader: {
        padding: theme.spacing(0, 2, 2, 2)
    },
    invoicesPaper: {
        boxShadow: theme.shadows[25],
        padding: theme.spacing(1.5, 2)
    },
    title: {
        fontSize: "1.15rem",
        fontWeight: "500",
        marginBottom: theme.spacing(2)
    },
    subtitle: {
        fontSize: "1rem",
        fontWeight: "400"
    }
}));