import {
    GET_ALL_RT_INVOICING_CLIENT,
    SET_ALL_RT_INVOICING_CLIENT,
    POST_RT_INVOICING_CLIENT,
    PUT_RT_INVOICING_CLIENT,
    DELETE_RT_INVOICING_CLIENT
} from '../../constants';
import { errorNotification, successNotification } from "../../../../utils/notification";
import { FINANCIAL_RT_ENDPOINT } from '../../constants/endpoint';
import { onSuccess, onError, onRequest } from '../../../../utils/http';


export const getAllInvoicingClient = (filtered) => {
    return {
        type: GET_ALL_RT_INVOICING_CLIENT,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/invoicingClient`
        },
        meta: {
            onRequest: onRequest(({ request, store }) => {
                if (filtered) {
                    const filter = store.getState().invoicing.filter;
                    if (filter.month) {
                        request.params = {
                            year: filter.year,
                            month: filter.month
                        };
                    } else {
                        request.params = {
                            year: filter.year
                        };
                    }
                }
                return request;
            }),
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setAllInvoicingClient(response.data));
                return response;
            })
        }
    };
};

export const setAllInvoicingClient = (data) => {
    return {
        type: SET_ALL_RT_INVOICING_CLIENT,
        payload: data
    };
};

export const postInvoicingClient = (invoicingClient) => {
    return {
        type: POST_RT_INVOICING_CLIENT,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/invoicingClient`,
            method: 'post',
            data: invoicingClient
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "create_success");
                store.dispatch(getAllInvoicingClient());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    };
};

export const putInvoicingClient = (id, invoicingClient) => {
    return {
        type: PUT_RT_INVOICING_CLIENT,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/invoicingClient/${id}`,
            method: 'put',
            data: invoicingClient
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "update_success");
                store.dispatch(getAllInvoicingClient());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "update_error");
                throw error;
            })
        }
    };
};

export const deleteInvoicingClient = (id) => {
    return {
        type: DELETE_RT_INVOICING_CLIENT,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/invoicingClient/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "delete_success");
                store.dispatch(getAllInvoicingClient());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', error.response.status === 409 ? "ressource_in_use_error" : "delete_error");
                throw error;
            })
        }
    };
};