import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CustomDatePicker from "../../../App/components/CustomDatePicker";
import {
    calculatePayedAmount,
    calculateRemainingAmount,
} from "../../../utils/invoice-helper";
import { formatAmount, processFormattedAmount } from "../../../utils/converters";
import {
    addInvoicePayment,
    settleInvoices,
} from "../../redux/actions/invoicing";
import useStyles from "./style";
import CustomDialog from "../../../App/components/CustomDialog";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { NumericFormat } from "react-number-format";

function SettlementDialog({
    fetch,
    invoicesToSettle,
    setOpen,
    open,
    setSelected,
    backButton
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [settlementDate, setSettlementDate] = useState(new Date());
    const [paymentDetails, setPaymentDetails] = useState({amount: 0.00, exchangeRate: invoicesToSettle[0].currency.exchangeRate, convertedAmount: 0.00});
    const defaultCurrency = useSelector(({ rt }) => rt.currencies).find(currency => currency.isDefault);
    const [saving, setSaving] = useState(false);

    const SettlementDialogTitle = () => {
        if (invoicesToSettle.length === 1) {
            return (
                <Typography variant="h6">
                    {t("settlement_title_one", {
                        invoiceNumber: invoicesToSettle[0].number,
                    })}
                </Typography>
            );
        } else if (invoicesToSettle.length > 1) {
            return (
                <Typography variant="h6">
                    {t("settlement_title_multiple", { count: invoicesToSettle.length })}
                </Typography>
            );
        } else return <></>;
    };

    const getMinSettlementDate = () => {
        return invoicesToSettle
            .map((i) => new Date(i.issueDate))
            .sort((d1, d2) => d2 - d1)[0];
    };

    const renderSettlementDialogContent = () => {
        return (<Grid container justifyContent="center" style={{overflow: "hidden"}} spacing={2}>
            {(invoicesToSettle.length === 1) &&
        <Grid spacing={3} container item xs={12}>
            <Grid item xs={4} className={classes.statsCard}>
                <Card className={classes.statsBox}>
                    <CardContent className={classes.statsCardContent}>
                        <Box height="65%">
                            <Typography variant="caption">{t("total_amount_ttc")}</Typography>
                        </Box>
                        <Box height="35%">
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="h6" display="inline">{formatAmount(invoicesToSettle[0].amountInclTax, invoicesToSettle[0].currency)}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4} className={classes.statsCard}>
                <Card className={classes.statsBox}>
                    <CardContent className={classes.statsCardContent}>
                        <Box height="65%">
                            <Typography variant="caption">{t("payed_amount_ttc")}</Typography>
                        </Box>
                        <Box height="35%">
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="h6" display="inline">{formatAmount(calculatePayedAmount(invoicesToSettle[0].invoicePayments), invoicesToSettle[0].currency)}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4} className={classes.statsCard}>
                <Card className={classes.statsBox}>
                    <CardContent className={classes.statsCardContent}>
                        <Box height="65%">
                            <Typography variant="caption">{t("remaining_amount_ttc")}</Typography>
                        </Box>
                        <Box height="35%">
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="h6" display="inline">{formatAmount(calculateRemainingAmount(invoicesToSettle[0].amountInclTax, invoicesToSettle[0].invoicePayments), invoicesToSettle[0].currency)}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
            }
            {
                invoicesToSettle.length > 1 &&
            <Grid item xs={12}>
                {
                    invoicesToSettle.map(invoice =>
                        <Chip
                            key={invoice.id}
                            size="small"
                            label={invoice.number}
                            className={classes.invoiceNumberChip}
                        />
                    )
                }
            </Grid>
            }
            <Grid container spacing={2} item xs={12}>
                <Grid item xs={invoicesToSettle.length > 1 ? 12 : 6}>
                    <CustomDatePicker
                        label={t("settlement_date")}
                        onChange={value => setSettlementDate(value)}
                        emptyLabel={t('settlement_date')}
                        className={classes.datePicker}
                        selectedDate={settlementDate}
                        minDate={getMinSettlementDate()}
                        maxDate={moment().add(7, "days").format("yyyy-MM-DD")}
                        required
                    />
                </Grid>
                {invoicesToSettle.some(invoice => {
                    return invoice.currency.code !== defaultCurrency.code;
                }) ? 
                    <Grid item xs={6}>
                        <TextField
                            label={`${t("exchangeRate")} ${invoicesToSettle.find(inv=>inv.currency.code !== defaultCurrency.code).currency.code}->${defaultCurrency.code}`}
                            value={paymentDetails.exchangeRate}
                            variant="outlined"
                            fullWidth
                            onChange={(e) => setPaymentDetails({...paymentDetails, exchangeRate: e.target.value, convertedAmount: (paymentDetails.amount * processFormattedAmount(e.target.value)).toFixed(2)})}
                            size="small"
                            inputProps={{
                                onBlur: (e) => {
                                    if (!e.target.value) e.target.value = Number(0).toFixed(3);
                                },
                            }}
                        />
                    </Grid> : 
                    (invoicesToSettle.length === 1) &&
                <Grid item xs={6}>
                    <NumericFormat
                        customInput={TextField}
                        className={classes.inputField}
                        label={t("amount")}
                        value={paymentDetails.amount}
                        type="text"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=" "
                        InputProps={{
                            endAdornment: <InputAdornment classes={{ root: classes.endAdornment }} position="end">{invoicesToSettle[0].currency.symbol}</InputAdornment>,
                        }}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => setPaymentDetails({...paymentDetails, amount: processFormattedAmount(e.target.value)})}
                        size="small"
                        inputProps={{
                            onClick: (e) => {
                                if (paymentDetails.amount === 0) e.target.value = null;
                            },
                            onBlur: (e) => {
                                if (!e.target.value) e.target.value = Number(0).toFixed(2);
                            },
                        }}
                    />
                </Grid>}    
            </Grid> 
            <Grid container className={classes.amounts} spacing={2} item xs={12} justifyContent="space-between">
                {((invoicesToSettle.length === 1) && (defaultCurrency.code !== invoicesToSettle[0].currency.code)) &&
        <>
            <Grid item xs={5}>
                <NumericFormat
                    customInput={TextField}
                    className={classes.inputField}
                    label={t("amount")}
                    value={paymentDetails.amount}
                    type="text"
                    InputProps={{
                        endAdornment: <InputAdornment classes={{ root: classes.endAdornment }} position="end">{invoicesToSettle[0].currency.symbol}</InputAdornment>,
                    }}
                    variant="outlined"
                    fullWidth
                    thousandsGroupStyle="thousand"
                    thousandSeparator=" "
                    onChange={(e) => setPaymentDetails({...paymentDetails, amount: processFormattedAmount(e.target.value), convertedAmount: (processFormattedAmount(e.target.value) * paymentDetails.exchangeRate).toFixed(2)})}
                    size="small"
                    inputProps={{
                        onClick: (e) => {
                            if (paymentDetails.amount === 0) e.target.value = null;
                        },
                        onBlur: (e) => {
                            if (!e.target.value) e.target.value = Number(0).toFixed(2);
                        },
                    }}
                />
            </Grid>  
            <Grid container justifyContent="center" alignItems="center" item xs={1}>
                <ArrowForwardIcon style={{fontSize:"2rem"}} />
            </Grid>
            <Grid container justifyContent="flex-start" alignItems="center" item xs={5}>   
                <NumericFormat
                    customInput={TextField}
                    className={classes.inputField}
                    variant="outlined"
                    size="small"
                    label={t("converted_amount")}
                    type="text"
                    fullWidth
                    value={paymentDetails.convertedAmount}
                    thousandsGroupStyle="thousand"
                    thousandSeparator=" "
                    onChange={(e)=> setPaymentDetails({...paymentDetails, exchangeRate: (processFormattedAmount(e.target.value) / paymentDetails.amount).toFixed(3), convertedAmount: processFormattedAmount(e.target.value)})}
                    InputProps={{
                        endAdornment: <InputAdornment classes={{ root: classes.endAdornment }} position="end">{defaultCurrency.symbol}</InputAdornment>,
                    }}
                    inputProps={{
                        onClick: (e) => {
                            if (paymentDetails.amount === 0) e.target.value = null;
                        },
                        onBlur: (e) => {
                            if (!e.target.value) e.target.value = Number(0).toFixed(2);
                        },
                    }}
                />
            </Grid>
        </>}
            </Grid>

        </Grid>);
    };


    const renderSettlementDialogActions = () => (
        <Box className={classes.actionsSection} style={{justifyContent: backButton ? "space-between" : "flex-end"}}>
            {backButton && backButton}
            <Box>
                <Button
                    variant="contained"
                    disableElevation
                    onClick={() => {
                        setOpen(false);
                        setSettlementDate(new Date());
                    }}
                >
                    {t("cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={
                        invoicesToSettle.length > 1
                            ? handleInvoicesSettlement
                            : handleInvoicePayment
                    }
                    disabled={saving}
                    style={{
                        marginLeft: "1rem"
                    }}
                >
                    {t("validate")}
                </Button>
            </Box>
        </Box>
    );

    const handleInvoicesSettlement = () => {
        const invoicesIds = invoicesToSettle.map((i) => i.id);
        if (invoicesIds.length > 0) {
            setSaving(true);
            dispatch(settleInvoices(invoicesIds, settlementDate, paymentDetails.exchangeRate)).then((res) => {
                if (res.status === 200) {
                    fetch();
                    if (setSelected) setSelected([]);
                    setOpen(false);
                    setSettlementDate(new Date());
                }
                setSaving(false);
            });
        } else {
            console.error("invoicesIds can't be empty.");
        }
    };

    const handleInvoicePayment = () =>{
        setSaving(true);
        dispatch(addInvoicePayment(moment(settlementDate).format("yyyy-MM-DD"),  paymentDetails, invoicesToSettle[0])).then(res => {
            if (res.status === 200) {
                fetch();
                if (setSelected) setSelected([]);
                setOpen(false);
                setSettlementDate(new Date());
            }
            setSaving(false);
        });
    };

    useEffect(() => {
        if (invoicesToSettle.length === 1)
            setPaymentDetails({
                ...paymentDetails,
                amount: calculateRemainingAmount(
                    invoicesToSettle[0].amountInclTax,
                    invoicesToSettle[0].invoicePayments
                ),
                convertedAmount: invoicesToSettle[0].currency.exchangeRate * calculateRemainingAmount(
                    invoicesToSettle[0].amountInclTax,
                    invoicesToSettle[0].invoicePayments
                )
            });
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CustomDialog
            open={open}
            onClose={() => setOpen(false)}
            title={<SettlementDialogTitle />}
            renderContent={renderSettlementDialogContent}
            renderActions={renderSettlementDialogActions}
            classes={{
                paper: classes.dialogPaper,
            }}
            saving={saving}
            disableBackdropClick={true}
        />
    );
}

export default SettlementDialog;
