import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    searchDialog: {
        '& .MuiDialog-paper': {
            height: "70%",
            backgroundColor: "transparent",
            boxShadow: "none"
        },
        '& .MuiBackdrop-root': {
            opacity: 1
        }
    },
    invoiceItem: {
        padding: theme.spacing(1.5),
        cursor: "pointer",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.short,
        }),
        "&:hover": {
            transform: "scale(1.04)"
        }
    },
    searchInput: {
        backgroundColor: "#fff",
        borderRadius: "4px",
        marginBottom: "8px",
        fontSize: "1.2rem",
        fontWeight: 500
    },
    closeButton: {
        position: "absolute",
        top: "2px",
        right: "4px",
        backgroundColor: "#fff",
        boxShadow: theme.shadows[1],
        color: "#555",
        '&:hover': {
            backgroundColor: "#eee"
        }
    },
    pagination: {
        background: "whitesmoke",
        borderRadius: "10px"
    }
}));
