import React, { useState } from 'react';
import {
    Box,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Paper,
    Slide,
    TablePagination,
    TextField,
    Typography
} from '@material-ui/core';
import useStyles from './style';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getInvoiceStatusColor, getInvoiceDate, calculateRemainingAmount } from '../../../utils/invoice-helper';
import { clearSearchInvoices, getSearchInvoices } from '../../redux/actions/invoicing';
import CloseIcon from '@material-ui/icons/Close';
import { enMonthsLower } from '../../../utils/constants';
import CustomDialog from '../../../App/components/CustomDialog';
import { formatAmount } from '../../../utils/converters';
import { getInitialPagination } from '../../../utils/common-state';
import { useEffect } from 'react';

var searchTimeout = 0;
const MAX_RESULTS = 5;

function InvoiceSearch({ open, setOpen }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const searchResults = useSelector(({ invoicing }) => invoicing.invoices.searchResults.list);
    const [pagination, setPagination] = useState(getInitialPagination(false));


    const search = (query) => {
        if (!query || /^\s*$/.test(query)) {
            if(searchTimeout) {
                clearTimeout(searchTimeout);
                setLoading(false);
            }
            dispatch(clearSearchInvoices());
        } else {
            setLoading(true);

            if(searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
                dispatch(getSearchInvoices(query.trim())).then(() => {
                    setLoading(false);
                });
            }, 250);
        }
    };

    const clearSearch = () => {
        setOpen(false);
        dispatch(clearSearchInvoices());
    };

    useEffect(() => {
        setPagination(getInitialPagination(false));
        search(searchQuery);
    }, [searchQuery]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLoading(true);
        dispatch(getSearchInvoices(searchQuery.trim(), pagination.page)).then(() => {
            setLoading(false);
        });
    }, [pagination]); //eslint-disable-line react-hooks/exhaustive-deps

    const gotoInvoice = (invoice) => {
        const invoiceDate = new Date(getInvoiceDate(invoice));
        const month = enMonthsLower[invoiceDate.getMonth()];
        history.push(`/invoicing/${invoiceDate.getFullYear()}/${month}/invoices/${invoice.id}`);
        clearSearch();
    };

    const renderTitle = () => {
        return (
            <DialogTitle>
                <Paper>
                    <TextField
                        placeholder={t('invoice_search_placeholder')}
                        variant="filled"
                        fullWidth
                        inputProps={{ className: classes.searchInput }}
                        onChange={e => setSearchQuery(e.target.value)}
                        autoFocus
                    />
                    <IconButton className={classes.closeButton} onClick={clearSearch}>
                        <CloseIcon />
                    </IconButton>
                    {
                        loading &&
                        <Box width="100%">
                            <LinearProgress />
                        </Box>
                    }
                </Paper>
            </DialogTitle>
        );
    };

    const renderContent = () => {
        return (
            <Grid container spacing={2}>
                {
                    searchResults.data.map(invoice =>
                        <Grid item xs={4} key={invoice.id}>
                            <Paper
                                key={invoice.number}
                                className={classes.invoiceItem}
                                style={{ borderLeft: `5px solid ${getInvoiceStatusColor(invoice.status.code).color}` }}
                                onClick={() => gotoInvoice(invoice)}
                            >
                                <div>
                                    <Typography variant="subtitle2" display="inline">{t('client')} : </Typography>
                                    <Typography variant="body2" display="inline">{invoice.client.name}</Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2" display="inline">{t('number')} : </Typography>
                                    <Typography variant="body2" display="inline">{invoice.number || "N/A"}</Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2" display="inline">{t('label')} : </Typography>
                                    <Typography variant="body2" display="inline">{invoice.label}</Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2" display="inline">{t('order_reference')} : </Typography>
                                    <Typography variant="body2" display="inline">{invoice.invoiceOrder.reference}</Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2" display="inline">{t('remaining_to_pay')} : </Typography>
                                    <Typography variant="body2" display="inline">{formatAmount(calculateRemainingAmount(invoice.amountInclTax, invoice.invoicePayments), invoice.currency)}</Typography>
                                </div>
                            </Paper>
                        </Grid>
                    )
                }
                {
                    searchResults.maxLength > MAX_RESULTS &&
                    <Grid container justifyContent="flex-end" style={{padding: "8px"}}>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={searchResults.maxLength ?? 0}
                            rowsPerPage={6}
                            page={pagination.page}
                            className={classes.pagination}
                            onPageChange={(_event, newPage) =>
                                setPagination({ ...pagination, page: newPage })
                            }
                        />
                    </Grid>
                    
                }
                
            </Grid>
        );
    };

    return (
        
        <CustomDialog
            open={open}
            onClose={clearSearch}
            className={classes.searchDialog}
            maxWidth="md"
            fullWidth
            TransitionComponent={Slide}
            renderTitle={renderTitle}
            renderContent={renderContent}
        />
    );

}

export default InvoiceSearch;