import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    toolbar: {
        padding: theme.spacing(0.5, 1.5),
        backgroundColor: theme.palette.primary.main
    },
    yearChip: {
        padding: theme.spacing(0, 0, 0, 1),
        borderRadius: "4px",
        backgroundColor: "#fff",
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: "#fff"
        }
    },
    toolbarDate: {
        color: "#eee",
        '&:first-letter': {
            textTransform: "capitalize"
        }
    },
    resetStandard: {
        right: theme.spacing(3)
    },
    resetOutlined: {
        right: theme.spacing(4.75)
    },
    resetFilled: {
        right: theme.spacing(4.5)
    },
    reset: {
        position: "absolute",
        color: "rgba(0, 0, 0, 0.75)"
    },
    adornment: {
        width: theme.spacing(6),
        height: theme.spacing(3),
        justifyContent: "right"
    }

}));