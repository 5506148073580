import { onError, onSuccess } from "../../../../utils/http";
import { errorNotification, successNotification } from "../../../../utils/notification";
import {
    DELETE_RT_PROJECT_STATUS,
    GET_ALL_RT_PROJECT_STATUS,
    POST_RT_PROJECT_STATUS,
    PUT_RT_PROJECT_STATUS,
    SET_ALL_RT_PROJECT_STATUS,
} from "../../constants";

import { FINANCIAL_RT_ENDPOINT } from "../../constants/endpoint";

export const getAllProjectStatus = () => {
    return {
        type: GET_ALL_RT_PROJECT_STATUS,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/projectStatus`,
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllProjectStatus(response.data));
                return response;
            },
        },
    };
};

export const setAllProjectStatus = (data) => {
    return {
        type: SET_ALL_RT_PROJECT_STATUS,
        payload: data,
    };
};

export const postProjectStatus = (projectStatus) => {
    return {
        type: POST_RT_PROJECT_STATUS,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/projectStatus`,
            method: 'post',
            data: projectStatus
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "create_success");
                store.dispatch(getAllProjectStatus());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    };
};

export const putProjectStatus = (id, projectStatus) => {
    return {
        type: PUT_RT_PROJECT_STATUS,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/projectStatus/${id}`,
            method: 'put',
            data: projectStatus
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "update_success");
                store.dispatch(getAllProjectStatus());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "update_error");
                throw error;
            })
        }
    };
};

export const deleteProjectStatus = (id) => {
    return {
        type: DELETE_RT_PROJECT_STATUS,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/projectStatus/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "delete_success");
                store.dispatch(getAllProjectStatus());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', error.response.status === 409 ? "ressource_in_use_error" : "delete_error");
                throw error;
            })
        }
    };
};