import { SET_FINANCIAL_INVOICE_COMPANY, SET_INVOICING_CONFIG } from "../../constants";

const initialState = {
    invoicingConfig: null,
    invoiceCompany: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_INVOICING_CONFIG:
            return setInvoicingConfig(state, action.payload);
        case SET_FINANCIAL_INVOICE_COMPANY:
            return setInvoiceCompany(state, action.payload);
        default:
            return state;
    }
};

const setInvoicingConfig = (state, data) => {
    return {
        ...state,
        invoicingConfig: data
    };
};

const setInvoiceCompany = (state, data) => {
    return {
        ...state,
        invoiceCompany: data
    };
};