import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: `translate(-45%, -50%)`,
        backgroundColor: "#FFFFFF",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: '5px',
        width: "500px"
    },
    cardTitle: {
        color: "#585858",
        fontSize: '20px'
    },
    icon: {
        color: '#585858',
        fontSize: '25px',
        fontWeight: "bold",
        height: '100%',
        paddingTop: '10px'
    },
    cardAction: {
        minWidth: '125px',
        height: '45px',
        borderRadius: '2px',
        margin: theme.spacing(1, 2)
    },
    btnOutlinedPrimary: {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
    btnOutlinedSecondary: {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
    },
    btnOutlinedWarning: {
        borderColor: theme.palette.warning.main,
        color: theme.palette.warning.main,
    },
    btnOutlinedError: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
    },
    btnOutlinedSuccess: {
        borderColor: theme.palette.success.main,
        color: theme.palette.success.main,
    },
    btnContainedPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    btnContainedSecondary: {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
        }
    },
    btnContainedWarning: {
        backgroundColor: theme.palette.warning.main,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.warning.dark,
        }
    },
    btnContainedError: {
        backgroundColor: theme.palette.error.main,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
        }
    },
    btnContainedSuccess: {
        backgroundColor: theme.palette.success.main,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.success.dark,
        }
    }
}));