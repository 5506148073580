import React from 'react';
import { BREADCRUMBS, MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE, enMonthsLower } from '../../../utils/constants';

const Payments = React.lazy(() => import('../../pages/Payments'));

export default [
    {
        path: `/payments/:year`,
        exact: true,
        name: 'year_payments',
        component: Payments,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-payments',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true,
                params: ["year"],
            },
            show: true
        }
    },
    {
        path: `/payments/:year/all`,
        exact: true,
        name: 'payments',
        component: Payments,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-payments',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.url_params,
                display: true
            },
            show: true
        }
    },
    {
        path: `/payments/:year/:month(${enMonthsLower.join('|')})`,
        exact: true,
        name: 'mounth_payments',
        component: Payments,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-payments',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.url_params,
                params: ["month"],
                display: true
            },
            show: true
        }
    },
];