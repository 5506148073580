export const usersColumns = [
    {
        id: 0,
        name: 'firstname',
        apiName: 'firstname',
        width: 3,
        sort: false
    },
    {
        id: 1,
        name: 'lastname',
        apiName: 'lastname',
        width: 3,
        sort: false
    },
    {
        id: 2,
        name: 'email',
        apiName: 'email',
        width: 3,
        sort: false
    },
    {
        id: 3,
        name: 'role',
        apiName: 'role',
        width: 3,
        sort: false
    }
];