import { ReactENV } from '../utils/io';

export const KEYCLOAK_DISABLED = ReactENV.REACT_APP_KEYCLOAK_DISABLED;
export const KEYCLOAK_REALM = ReactENV.REACT_APP_KEYCLOAK_REALM;
export const KEYCLOAK_URL = ReactENV.REACT_APP_KEYCLOAK_URL;
export const KEYCLOAK_SSL_REQUIRED =
    ReactENV.REACT_APP_KEYCLOAK_SSL_REQUIRED;
export const KEYCLOAK_CLIENT_ID = ReactENV.REACT_APP_KEYCLOAK_CLIENT_ID;
export const KEYCLOAK_PUBLIC_CLIENT =
    ReactENV.REACT_APP_KEYCLOAK_PUBLIC_CLIENT;

export const API_KEYCLOAK_NAME = ReactENV.REACT_APP_API_KEYCLOAK_NAME;
