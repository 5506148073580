import React, { useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Drawer,
    Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import { useTranslation } from 'react-i18next';
import HistoryIcon from '@material-ui/icons/History';
import { getInvoiceAudit } from '../../redux/actions/invoicing';
import ChangeLogBlock from '../../../App/components/ChangeLogBlock';


function InvoiceAudit({ open, setOpen, invoice }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const invoiceAuditLog = useSelector(({ invoicing }) => invoicing.invoices.audit.changes.data);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(open && invoice && invoice.id) {
            setLoading(true);
            dispatch(getInvoiceAudit(invoice.id))
                .then(() => setLoading(false));
        } else {
            setOpen(false);
            setLoading(false);
        }
    }, [open, invoice]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
                className: classes.paper
            }}
        >
            <Box py={2} bgcolor="#fff" width="100%">
                <Typography variant="h6"><HistoryIcon fontSize="small" /> {t('invoice_audit_title')}</Typography>
            </Box>

            {
                loading &&
                <Box mb={2} display="flex" justifyContent="center">
                    <CircularProgress color="inherit" />
                </Box> 
            }

            <Box>
                {
                    invoiceAuditLog.map(item => {
                        return (
                            <ChangeLogBlock
                                key={item.id}
                                author={item.author}
                                date={item.createdAt}
                                changeLog={JSON.parse(item.changeLog)}
                            />
                        );
                    })
                }
            </Box>
        </Drawer>
    );
}

export default InvoiceAudit;