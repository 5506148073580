import { GRID_PAGINATION_CONFIG, LIST_PAGINATION_CONFIG, PAGINATION_MODE } from "./constants";

export default function getPaginationConfig(gridView) {
    return gridView ? GRID_PAGINATION_CONFIG : LIST_PAGINATION_CONFIG;
}

export const getInitialPagination = (gridView, type = PAGINATION_MODE.STANDARD) => {

    const getGridSize = () => {
        switch(type){
            case PAGINATION_MODE.STANDARD:
                return GRID_PAGINATION_CONFIG.rowsPerPage[0];
            case PAGINATION_MODE.MINIFIED:
                return GRID_PAGINATION_CONFIG.rowsPerPage[0];
            case PAGINATION_MODE.MINIFIED_GRID:
                return GRID_PAGINATION_CONFIG.rowsPerPage[0];
            default:
                return GRID_PAGINATION_CONFIG.rowsPerPage[0];
        }
    };

    const getListSize = () => {
        switch(type){
            case PAGINATION_MODE.STANDARD:
                return LIST_PAGINATION_CONFIG.rowsPerPage[2];
            case PAGINATION_MODE.MINIFIED:
                return LIST_PAGINATION_CONFIG.rowsPerPage[0];
            case PAGINATION_MODE.MINIFIED_LIST:
                return LIST_PAGINATION_CONFIG.rowsPerPage[0];
            default:
                return LIST_PAGINATION_CONFIG.rowsPerPage[2];
        }
    };

    return gridView
        ? {
            page: 0,
            size: getGridSize(),
            maxLength: 0,
        }
        : {
            page: 0,
            size: getListSize(),
            maxLength: 0,
        };
};
