import React, { useEffect, useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import "moment/locale/fr";
import {
    Chip,
    Grid,
    InputAdornment,
    Typography,
    Link,
    Box,
    Tooltip
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import useStyles from './style';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

moment.locale("fr");

function CustomToolbarComponent({ ...props }) {
    const classes = useStyles();

    return (
        props.views.includes("date") &&
        <Grid container className={classes.toolbar} justifyContent="space-between" alignItems="center">
            <Grid item>
                <Typography className={classes.toolbarDate} variant="body2">
                    {props.date.format("dddd DD MMMM")}
                </Typography>
            </Grid>
            <Grid item>
                <Chip
                    onClick={() => props.setOpenView("year")}
                    label={<Typography variant="subtitle2">
                        {props.date.format("yyyy")}
                        {props.openView === "year" ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                    </Typography>}
                    size="small"
                    className={classes.yearChip}
                />
            </Grid>
        </Grid>
    );
}

function CustomDatePicker({
    selectedDate,
    onChange,
    label,
    emptyLabel,
    inputVariant = "outlined",
    margin = "dense",
    minDate,
    maxDate,
    disabled = false,
    readOnly = false,
    required = false,
    className,
    InputClassName,
    yearOnly = false,
    yearMonthOnly = false,
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const adornmentRef = React.createRef();
    const [resetOffset, setResetOffset] = useState(0);
    const [datePrecision, setDatePrecision] = useState({});

    const getResetClassname = () => {
        return inputVariant === "standard"
            ? classes.resetStandard
            : (
                inputVariant === "outlined"
                    ? classes.resetOutlined
                    : classes.resetFilled
            );
    };

    useEffect(() => {
        if(yearOnly){
            setDatePrecision({
                format: "yyyy",
                views: ["year"],
            });
        }else if(yearMonthOnly){
            setDatePrecision({
                format: "MMMM yyyy",
                views: ["year", "month"],
            });
        }else{
            setDatePrecision({
                format: "DD/MM/yyyy",
                views: ["year", "month", "date"],
            });
        }
    }, [yearOnly, yearMonthOnly]);
    

    useEffect(() => {
        setResetOffset(adornmentRef?.current?.offsetTop ?? 0);
    }, [adornmentRef]);

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="fr">
            <Box style={{ position: "relative" }}>
                <DatePicker
                    className={className}
                    variant="inline"
                    inputVariant={inputVariant}
                    ToolbarComponent={CustomToolbarComponent}
                    format={datePrecision.format}
                    views={datePrecision.views}
                    margin={margin}
                    label={label}
                    emptyLabel={emptyLabel}
                    value={selectedDate}
                    onChange={onChange}
                    InputProps={{
                        endAdornment: (
                            <div ref={adornmentRef}>
                                <InputAdornment
                                    position="end"
                                    className={(!disabled && selectedDate) ? classes.adornment : null}
                                >
                                    <CalendarTodayIcon />
                                </InputAdornment>
                            </div>
                        ),
                        className: InputClassName
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    autoOk={true}
                    fullWidth
                    disabled={disabled}
                    error={false}
                    helperText={null}
                    readOnly={readOnly}
                    style={{margin: 0}}
                />
                {
                    (!disabled && !readOnly && !required) &&
                    selectedDate &&
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => onChange(null)}
                        className={clsx(classes.reset, getResetClassname())}
                        style={{ top: `${3 + resetOffset}px` }}
                    >
                        <Tooltip
                            title={t('clear')}
                            placement="top"
                        >
                            <CloseIcon />
                        </Tooltip>
                    </Link>
                }
            </Box>
        </MuiPickersUtilsProvider>
    );
}

export default CustomDatePicker;
