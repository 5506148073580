import {
    DELETE_RT_BANK_ACCOUNT, DOWNLOAD_BANK_RIB_ATTACHMENT,
    DOWNLOAD_MAIL_ATTACHMENT,
    GET_ALL_RT_BANK_ACCOUNT,
    POST_RT_BANK_ACCOUNT,
    SET_ALL_RT_BANK_ACCOUNT,
    UPDATE_BANK_ACCOUNT
} from '../../constants';
import { errorNotification, successNotification } from "../../../../utils/notification";
import {FINANCIAL_INVOICING_ENDPOINT, FINANCIAL_RT_ENDPOINT} from '../../constants/endpoint';
import { onSuccess, onError } from '../../../../utils/http';

export const getAllRtBankAccounts = () => {
    return {
        type: GET_ALL_RT_BANK_ACCOUNT,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/bankAccount`
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setAllRtBankAccounts(response.data));
                return response;
            })
        }
    };
};

export const setAllRtBankAccounts = (data) => {
    return {
        type: SET_ALL_RT_BANK_ACCOUNT,
        payload: data
    };
};

export const postRtBankAccount = (bankAccount) => {
    return {
        type: POST_RT_BANK_ACCOUNT,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/bankAccount`,
            method: 'post',
            data: bankAccount
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "create_success");
                store.dispatch(getAllRtBankAccounts());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    };
};

export const updateBankAccount = (bankAccountId, bankAccount) => {
    return {
        type: UPDATE_BANK_ACCOUNT,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/bankAccount/${bankAccountId}`,
            method: 'put',
            data: bankAccount
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "update_success");
                store.dispatch(getAllRtBankAccounts());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "update_error");
                throw error;
            })
        }
    };
};

export const downLoadRibBankAttachment = (bankAccountId) => () => {
    return {
        type: DOWNLOAD_BANK_RIB_ATTACHMENT,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/bankAccount/${bankAccountId}/download`,
            responseType: 'arraybuffer'
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                return response.data;
            }),
        }
    };
};

export const deleteRtBankAccount = (id) => {
    return {
        type: DELETE_RT_BANK_ACCOUNT,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/bankAccount/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "delete_success");
                store.dispatch(getAllRtBankAccounts());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', error.response.status === 409 ? "ressource_in_use_error" : "delete_error");
                throw error;
            })
        }
    };
};