import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    paper: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        padding: theme.spacing(1.5, 2),
        boxShadow: theme.shadows[25]
    },
    title: {
        fontSize: "1.15rem",
        fontWeight: "500",
        marginBottom: theme.spacing(1)
    },
    totalTurnover: {
        fontSize: "1.8rem",
        fontWeight: 500,
        color: "#665191"
    },
    trendUpChip: {
        backgroundColor: "#e5fbe5",
        color: "green",
        borderRadius: "4px",
        marginTop: "6px"
    },
    trendDownChip: {
        backgroundColor: "#ffcccb",
        color: "red",
        borderRadius: "4px",
        marginTop: "6px"
    },
    trendFlatChip: {
        borderRadius: "4px",
        marginTop: "6px"
    },
    noTrendChip: {
        borderRadius: "4px",
        marginTop: "6px",
        padding: "8px 20px"
    },
    pendingTotal: {
        fontSize: "1.1rem",
        fontWeight: 500,
        color: "#da1e28",
        marginRight: theme.spacing(1)
    },
    expectedTotal: {
        fontSize: "1.1rem",
        fontWeight: 500,
        color: "#daab1e",
        margin: theme.spacing(0, 1)
    },
    extraData: {
        marginTop: "1rem",
        paddingTop: "0.8rem",
        borderTop: "1px solid #0000002b",
        justifyContent: "space-between",
        alignITem: "center"
    },
    extrasContainer: {
        display: "flex",
        alignItems: "center"
    },
    extrasSkeleton: {
        marginRight: "1rem",
        width: "8rem",
        height: "2.2rem",
        display: "inline-block",
    }
}));