import { getFileTypeFromExtension } from "./files";

export const onRequest = (action) => {
    return (request, requestAction, store) => {
        if(action){
            return action({request, requestAction, store});
        }

        return request;
    };
};

export const onSuccess = (action) => {
    return (response, requestAction, store) => {
        if(action){
            return action({response, requestAction, store});
        }

        return response;
    };
};

export const onError = (action) => {
    return (error, requestAction, store) => {
        if(action) {
            return action({error, requestAction, store});
        }

        throw error;
    };
};

export const resolveHTTPAcceptHeader = (types) => {
    let acceptHeader = [];
    types.forEach(e => {
        acceptHeader.push(getFileTypeFromExtension(e));
    });

    return acceptHeader.join(",");
};