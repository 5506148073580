import {
    GET_ALL_RT_TAX_TYPE_RATES,
    SET_ALL_RT_TAX_TYPE_RATES,
    POST_RT_TAX_RATE,
    PUT_RT_TAX_RATE,
    DELETE_RT_TAX_RATE
} from '../../constants';
import { errorNotification, successNotification } from "../../../../utils/notification";
import { FINANCIAL_RT_ENDPOINT } from '../../constants/endpoint';
import { onError, onSuccess } from '../../../../utils/http';
import { getAllTaxType } from './invoiceTaxType';

export const getAllTaxTypeRates = (typeId) => {
    return {
        type: GET_ALL_RT_TAX_TYPE_RATES,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/taxRate/type/${typeId}`
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setAllTaxTypeRates(response.data));
                return response;
            })
        }
    };
};

export const setAllTaxTypeRates = (data) => {
    return {
        type: SET_ALL_RT_TAX_TYPE_RATES,
        payload: data
    };
};

export const postTaxRate = (taxRate) => {
    return {
        type: POST_RT_TAX_RATE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/taxRate`,
            method: 'post',
            data: taxRate
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "create_success");
                store.dispatch(getAllTaxType());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    };
};

export const putTaxRate = (id, taxRate) => {
    return {
        type: PUT_RT_TAX_RATE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/taxRate/${id}`,
            method: 'put',
            data: taxRate
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "update_success");
                store.dispatch(getAllTaxType());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "update_error");
                throw error;
            })
        }
    };
};

export const deleteTaxRate = (id) => {
    return {
        type: DELETE_RT_TAX_RATE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/taxRate/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "delete_success");
                store.dispatch(getAllTaxType());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', error.response.status === 409 ? "ressource_in_use_error" : "delete_error");
                throw error;
            })
        }
    };
};