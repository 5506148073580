import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    container: {
        
    },
    card: {
        '& .MuiCardHeader-root': {
            color: theme.palette.primary.main
        },
        padding: "10px"
    },
    noHeaderCard: {
        paddingTop: "26px"
    }
}));