import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    taxField: {
        "& .MuiSelect-root": {
            padding: "10px"
        },
        "& input": {
            padding: "10px"
        },
        "& label": {
            marginTop: "-8px"
        },
        "& label.MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, 3px) scale(0.75)"
        }
    },
    readOnlyTextField: {
        "& .MuiInputBase-root:hover, input:hover": {
            cursor: "default !important"
        }
    },
    endAdornment: {
        '& p': {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            fontSize: "16px",
            textAlign: "center",
            height: "37px",
            width: "30px",
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
            position: "relative",
            left: "14px",
            padding: "8px 0px"
        }
    }
}));