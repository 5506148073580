import React, { useEffect, useState } from 'react';
import CustomDialog from '../../../../App/components/CustomDialog';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import useStyles from './style'
import { useDispatch } from 'react-redux';
import { getAllUsers, postUser, putUSer } from '../../../redux/actions/users';

function UserDialog({open, setOpen, userDialogConfig, setUserDialogConfig}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [saving, setSaving] = useState(false);
    const dispatch = useDispatch();
    const [formData, setFromData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        role: ''
    });
    const [error, setError] = useState({
        firstName: false,
        lastName: false,
        email: false,
        role: false
    });


    useEffect(() => {
        (userDialogConfig && userDialogConfig.user)
            ?   setFromData({
                    firstName: userDialogConfig.user.firstName || '',
                    lastName: userDialogConfig.user.lastName || '',
                    email: userDialogConfig.user.email || '',
                    role: userDialogConfig.user.role || ''
                })
            :   setFromData({
                firstName: '',
                lastName: '',
                email: '',
                role: ''
            })
    }, [userDialogConfig])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setError(prevState => ({
            ...prevState,
            [name]: value === null || value === ''
        }));
        setFromData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = (action) => () => {
        switch(userDialogConfig.action){
            case 'edit':
                dispatch(putUSer(formData)).then(res => {
                    cleanUp();
                    setOpen(false);
                    dispatch(getAllUsers());
                })
                break;
            case 'add':
                setSaving(true);
                dispatch(postUser(formData)).then(res => {
                    cleanUp();
                    setOpen(false);
                    dispatch(getAllUsers());
                })
                break;
        }
    } 

    const renderUserFormActions = () => {
        return (
            <Box>
                <Button
                    disableElevation
                    onClick={handleSubmit()}
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.addButton}
                >
                    {t("submit")}
                </Button>
            </Box>
        )
    }
    
    const cleanUp = () => {
        setSaving(false);
        setUserDialogConfig(null);
    }

    const exit = () => {
        cleanUp();
        setOpen(false);
    }

    const roleChange = (roleId) => () => {
        const roleButton = document.getElementById(roleId);
        let active = '';
        switch (roleId) {
            case "Admin":
                roleButton.className = roleButton.className === classes.adminActive
                    ? classes.adminInactive
                    : classes.adminActive;
                active = classes.adminActive;
                break;
            case "Manager":
                roleButton.className = roleButton.className === classes.managerActive
                    ? classes.managerInactive
                    : classes.managerActive;
                active = classes.managerActive;
                break;
            case "Read Only":
                roleButton.className = roleButton.className === classes.readOnlyActive
                    ? classes.readOnlyInactive
                    : classes.readOnlyActive;
                active = classes.readOnlyActive;
                break;
        }
        if (roleButton.className === active) {
            setFromData({...formData, role: roleId});
            setError(prevState => ({
                ...prevState,
                role: false
            }));
        } else {
            setFromData({...formData, role: ''});
            setError(prevState => ({
                ...prevState,
                role: true
            }));
        }
        turnOffThOtherButtons(roleId);
    }

    const turnOffThOtherButtons = (roleId) => {
        rolesButtons.forEach(roleButton => {
            if (roleButton.id === roleId) {
                return;
            }
            document.getElementById(roleButton.id).className = roleButton.inactive;
        });
    }

    const rolesButtons = [
        {
            id: "Admin",
            className: classes.adminInactive,
            onClick: roleChange("Admin"),
            active: classes.adminActive,
            inactive: classes.adminInactive
        },
        {
            id: "Manager",
            className: classes.managerInactive,
            onClick: roleChange("Manager"),
            active: classes.managerActive,
            inactive: classes.managerInactive
        },
        {
            id: "Read Only",
            className: classes.readOnlyInactive,
            onClick: roleChange("Read Only"),
            active: classes.readOnlyActive,
            inactive: classes.readOnlyInactive
        }
    ];

    const renderUserFormContent = () => {
        return (
            <Grid container >
                <Grid item xs={6}>
                    <TextField
                        name="firstName"
                        label={t("first_name")}
                        variant="outlined"
                        fullWidth
                        value={formData.firstName}
                        onChange={handleChange}
                        className={classes.textField}
                        disabled={userDialogConfig?.user}
                        error={error.firstName}
                    />
                    <TextField
                        name="lastName"
                        label={t('last_name')}
                        variant="outlined"
                        fullWidth
                        value={formData.lastName}
                        onChange={handleChange}
                        className={classes.textField}
                        disabled={userDialogConfig?.user}
                        error={error.lastName}
                    />
                    <TextField
                        name="email"
                        label={t('email')}
                        variant="outlined"
                        fullWidth
                        value={formData.email}
                        onChange={handleChange}
                        className={classes.textField}
                        disabled={userDialogConfig?.user}
                        error={error.email}
                    />
                </Grid>
                <Grid item xs={6} className={classes.roleSelectContainer}>
                    {rolesButtons.map((role) => (
                        <Button
                            key={role.id}
                            {...role}
                        >{role.id}</Button>
                    ))}
                    {error.role && <Typography>The user's role is mandatory</Typography>}
                </Grid>
            </Grid>
        )
    }

    return (
        <CustomDialog
            open={open}
            onClose={exit}
            title={t("create_user_form")}
            renderContent={renderUserFormContent}
            renderActions={renderUserFormActions}
            saving={saving}
        />
    )
}

export default UserDialog;