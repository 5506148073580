import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    inputField: {
        "& label": {
            marginTop: "-8px"
        },
        "& label.MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, 3px) scale(0.75)"
        },
        "& .MuiInputBase-root": {
            padding: 0
        }
    },
    endAdornment: {
        '& p': {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            fontSize: "16px",
            textAlign: "center",
            height: "37px",
            width: "30px",
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
            position: "relative",
            padding: "8px 0px"
        }
    },
    dndIcon: {
        cursor: "pointer",
        margin: "10px"
    },
    deleteIcon: {
        cursor: "pointer",
        color: "red",
        margin: "10px"
    },
    quantityPaper: {
        boxShadow: theme.shadows[8],
        zIndex: theme.zIndex.drawer
    },
    quantitySymbol: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        fontSize: "16px",
        textAlign: "center",
        height: "37px",
        width: "30px",
        borderRadius: 0,
        padding: "8px 0px"
    },
    quantityIconButton: {
        borderRadius: 0,
        height: "37px",
        backgroundColor: "#eee"
    }
}));