import React, { useEffect, useState } from 'react';
import {
    IconButton,
    Paper,
    Typography,
    Grid,
    TextField,
    MenuItem
} from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import useStyles from './style';
import { getColorFromPercentage } from '../../../utils/converters';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const options = {
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                // eslint-disable-next-line no-unused-vars
                callback: function (label, index, labels) {
                    return label + ' j';
                }
            },
            gridLines: {
                color: "#eaeaea",
                zeroLineWidth: 0
            }
        }],
        xAxes: [{
            gridLines: {
                color: "#eaeaea",
                zeroLineWidth: 0
            }
        }]
    },
    legend: {
        display: false
    },
    tooltips: {
        backgroundColor: "#fff",
        titleFontColor: "#555",
        bodyFontColor: "#555",
        borderColor: "#777",
        borderWidth: 1,
        caretSize: 6,
        xPadding: 10,
        yPadding: 10,
        bodySpacing: 10,
        titleMarginBottom: 10,
        displayColors: false,
        callbacks: {
            // eslint-disable-next-line no-unused-vars
            label: function (tooltipItems, data) {
                return tooltipItems.yLabel + ' jours';
            }
        }
    }
};

function ClientsPaymentTime() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [chartData, setChartData] = useState([]);
    const [chartPageData, setChartPageData] = useState([]);
    const clientsPaymentTime = useSelector(({ dashboard }) => dashboard.clientsPaymentTime);

    const data = {
        labels: chartPageData.map(c => c.client.name),
        datasets: [
            {
                data: chartPageData.map(c => c.averagePaymentTime.toFixed(1)),
                backgroundColor: chartPageData.map(c =>
                    getColorFromPercentage(chartData.length > 0 ? c.averagePaymentTime / chartData[chartData.length - 1].averagePaymentTime : 0)
                ),
                maxBarThickness: 50
            },
        ],
    };

    useEffect(() => {
        setChartPageData(chartData.slice((size * page) - size, size * page));
    }, [chartData, page, size]);

    useEffect(() => {
        setChartData(clientsPaymentTime);
    }, [clientsPaymentTime]);

    const getMaxPage = () => {
        return Math.ceil(chartData.length / size);
    };

    return (
        <Paper className={classes.paper}>
            <Grid container justifyContent="space-between" alignItems="flex-start">
                <Grid item>
                    <Typography color="textSecondary" className={classes.title}>
                        {t('payment_time_averages')}
                    </Typography>
                </Grid>
                <Grid container item xs={5} justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <TextField
                            select
                            defaultValue={size}
                            onChange={e => setSize(e.target.value)}
                        >
                            {
                                [10, 20, 50].map(i => 
                                    <MenuItem key={i} value={i}>{i}</MenuItem>
                                )
                            }
                        </TextField>
                    </Grid>
                    <Grid item>
                        <IconButton
                            size="small"
                            title={t("first_page")}
                            className={classes.paginationButton}
                            onClick={() => setPage(1)}
                            disabled={page === 1}
                        >
                            <FirstPageIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            title={t("previous_page")}
                            className={classes.paginationButton}
                            onClick={() => page > 1 && setPage(page - 1)}
                            disabled={page === 1}
                        >
                            <NavigateBeforeIcon />
                        </IconButton>
                        <Typography display="inline" variant="body2" style={{ padding: "0 8px" }}>
                            {`${t('page')} ${page} ${t('pagination_of')} ${getMaxPage()}`}
                        </Typography>
                        <IconButton
                            size="small"
                            title={t("next_page")}
                            className={classes.paginationButton}
                            onClick={() => page < getMaxPage() && setPage(page + 1)}
                            disabled={page === getMaxPage()}
                        >
                            <NavigateNextIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            title={t("last_page")}
                            className={classes.paginationButton}
                            onClick={() => setPage(getMaxPage())}
                            disabled={page === getMaxPage()}
                        >
                            <LastPageIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ width: "100%", overflowY: "auto" }}>
                <div className={classes.chartContainer}>
                    <Bar
                        data={data}
                        responsive={true}
                        options={{ ...options, maintainAspectRatio: false }}
                    />
                </div>
            </div>
        </Paper>
    );

}

export default ClientsPaymentTime;