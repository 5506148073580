import { ReactENV } from "./io";

const ORIGIN = "http://" + window.location.origin.replace("http://", "").replace("https://", "").replace("/", "");

export const MA_INSTANCE = ORIGIN === ReactENV.REACT_APP_WEBAPP_MA_ORIGIN;
export const FR_INSTANCE = ORIGIN === ReactENV.REACT_APP_WEBAPP_FR_ORIGIN;

let MANAGE_INVOICING_ROLE_TMP = ReactENV.REACT_APP_SECURITY_ROLES_MANAGE;
    if (ORIGIN === ReactENV.REACT_APP_WEBAPP_MA_ORIGIN) {
        MANAGE_INVOICING_ROLE_TMP = ReactENV.REACT_APP_SECURITY_ROLES_MANAGE_MA;
    } else if (ORIGIN === ReactENV.REACT_APP_WEBAPP_CA_ORIGIN) {
        MANAGE_INVOICING_ROLE_TMP = ReactENV.REACT_APP_SECURITY_ROLES_MANAGE_CA;
    } else if (ORIGIN === ReactENV.REACT_APP_WEBAPP_US_ORIGIN) {
        MANAGE_INVOICING_ROLE_TMP = ReactENV.REACT_APP_SECURITY_ROLES_MANAGE_US;
    }

export const MANAGE_INVOICING_ROLE = MANAGE_INVOICING_ROLE_TMP;

let ADMIN_INVOICING_ROLE_TMP = ReactENV.REACT_APP_SECURITY_ROLES_ADMIN;
    if(ORIGIN === ReactENV.REACT_APP_WEBAPP_MA_ORIGIN) {
        ADMIN_INVOICING_ROLE_TMP = ReactENV.REACT_APP_SECURITY_ROLES_ADMIN_MA;
    } else if(ORIGIN === ReactENV.REACT_APP_WEBAPP_US_ORIGIN) {
        ADMIN_INVOICING_ROLE_TMP = ReactENV.REACT_APP_SECURITY_ROLES_ADMIN_US;
    } else if(ORIGIN === ReactENV.REACT_APP_WEBAPP_CA_ORIGIN) {
        ADMIN_INVOICING_ROLE_TMP = ReactENV.REACT_APP_SECURITY_ROLES_ADMIN_CA;
    }

export const ADMIN_INVOICING_ROLE = ADMIN_INVOICING_ROLE_TMP;

let READ_ONLY_INVOICING_ROLE_TMP = ReactENV.REACT_APP_SECURITY_ROLES_READ_ONLY;
    if(ORIGIN === ReactENV.REACT_APP_WEBAPP_MA_ORIGIN) {
        READ_ONLY_INVOICING_ROLE_TMP = ReactENV.REACT_APP_SECURITY_ROLES_READ_ONLY_MA;
    } else if(ORIGIN === ReactENV.REACT_APP_WEBAPP_US_ORIGIN) {
        READ_ONLY_INVOICING_ROLE_TMP = ReactENV.REACT_APP_SECURITY_ROLES_READ_ONLY_US;
    } else if(ORIGIN === ReactENV.REACT_APP_WEBAPP_CA_ORIGIN) {
        READ_ONLY_INVOICING_ROLE_TMP = ReactENV.REACT_APP_SECURITY_ROLES_READ_ONLY_CA;
    }

export const READ_ONLY_INVOICING_ROLE = READ_ONLY_INVOICING_ROLE_TMP;

export const LANGUAGES = [
    {
        flag: "fr",
        key: "fr",
        text: "Français",
        isSupported: true,
    },
    {
        flag: "us",
        key: "us",
        text: "English",
        isSupported: true,
    },
    {
        flag: "de",
        key: "de",
        text: "Deutsche",
        isSupported: true,
    },
];

export const MIN_INVOICING_YEAR = 2017;
export const MAX_INVOICING_YEAR = new Date().getFullYear() + 1;

export const BREADCRUMBS = {
    NAME: {
        url_params: "PARAMS",
        url_search_query: "QUERY",
        default: "DEFAULT",
    },
};

export const PAGINATION_MODE = {
    STANDARD: 'STANDARD',
    MINIFIED: 'MINIFIED',
    MINIFIED_LIST: 'MINIFIED_LIST',
    MINIFIED_GRID: 'MINIFIED_grid'
};

export const GRID_PAGINATION_CONFIG = {
    rowsPerPage: [12, 24],
};

export const LIST_PAGINATION_CONFIG = {
    rowsPerPage: [5, 15, 30, 50],
};

export const invoicingPerimeterCode = {
    A1_FACTURE_FOURNISSEUR: "A1_FACTURE_FOURNISSEUR",
    A2_FACTURE_FOURNISSEUR_DEJA_PAYEE: "A2_FACTURE_FOURNISSEUR_DEJA_PAYEE",
    A9_FACTURE_SOUSTRAITANT: "A9_FACTURE_SOUSTRAITANT",
    A12_FACTURE_COTRAITANT: "A12_FACTURE_COTRAITANT",
};

export const RECURRENT_CREATION_TYPE = {
    NONE: {
        name: "None",
        code: "NONE"
    },
    MONTHLY: {
        name: "Monthly",
        code: "MONTHLY"
    },
    ANNUAL: {
        name: "Annual",
        code: "ANNUAL"
    }
};

export const EMAIL_TYPE = {
    ISSUE: {
        name: "Issue",
        code: "ISSUE"  
    },
    REMINDER: {
        name: "Reminder",
        code: "REMINDER"  
    }
};

export const INVOICE_STATUS= {
    PREVISIONNELLE: {
        code: "PREVISIONNELLE",
        name: "Prévisionnelle",
        color: "rgba(168, 168, 168)",
        lightColor: "rgba(168, 168, 168, 0.35)"
    },
    A_VENIR: {
        code: "A_VENIR",
        name: "A venir",
        color: "rgba(241, 226, 0)",
        lightColor: "rgba(241, 226, 0, 0.35)"
    },
    EMISE: {
        code: "EMISE",
        name: "Emise",
        color: "rgba(0, 186, 219)",
        lightColor: "rgba(0, 186, 219, 0.35)"
    },
    PARTIELLEMENT_REGLEE: {
        code: "PARTIELLEMENT_REGLEE",
        name: "Partiellement réglée",
        color: "rgba(176, 40, 255)",
        lightColor: "rgba(176, 40, 255, 0.35)"
    },
    REGLEE: {
        code: "REGLEE",
        name: "Réglée",
        color: "rgba(75, 192, 107)",
        lightColor: "rgba(75, 192, 107, 0.35)"
    },
    AVOIREE: {
        code: "AVOIREE",
        name: "Avoirée",
        color: "rgba(241, 68, 0)",
        lightColor: "rgba(241, 68, 0, 0.35)"
    }
};

export const enMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const frMonths = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
];

export const enMonthsLower = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
];

export const frMonthsLower = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
];

export const frWeekDays = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche"
];
