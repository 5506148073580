import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    listHeader: {
        padding: theme.spacing(1, 2, 2, 3)
    },
    skeleton: {
        borderRadius: "4px"
    },
    addButton: {
        border: "2px solid",
        "&:hover": {
            border: "2px solid"
        },
        textTransform: "none",
        marginBottom: "-10px"
    },
    addButtonBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-start"
    },
}));