import React, { useState } from 'react';
import useStyles from './style';
import UploadFiles from '../../../App/components/UploadFiles';
import { Box, Grid } from '@material-ui/core';
import StaticPDFViewer from '../../../App/components/StaticPDFViewer';
import EmptyPDFViewer from '../../../App/components/EmptyPDFViewer';
import { useDispatch } from 'react-redux';
import { postVerifyDocumentSignature } from '../../redux/actions/invoicing';
import { useTranslation } from 'react-i18next';

function VerifyDocumentSignature() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [pdfBase64, setPdfBase64] = useState("");
    const { t } = useTranslation();

    const handleUpload = (data) => {
        return dispatch(postVerifyDocumentSignature({
            file: data
        }));
    };

    const handleResponse = (res, setMessage, setSeverity, setErrors) => {
        if(res.data.valid){
            setSeverity("success");
            setErrors([]);
            setMessage(t("document_signature_success"));
        }else{
            setSeverity("error");
            setErrors([]);
            setMessage(t("document_signature_error"));
        }
    };

    const handleError = (err, setMessage, setErrors) => {
        setErrors([]);
        setMessage(t("document_signature_failed"));
    };

    const handleLoad = (data) => {
        setPdfBase64(data);
    };

    return (
        <Grid container spacing={2} className={classes.container}>
            <Grid item xs={6}>
                <UploadFiles
                    types={["pdf"]}
                    buttonTitle="verify"
                    inline={false}
                    onSubmit={handleUpload}
                    onResponse={handleResponse}
                    onError={handleError}
                    onLoad={handleLoad}
                />
            </Grid>
            <Grid item xs={6} style={{minHeight: "750px"}}>
                {
                    pdfBase64 
                        ? (
                            <Box className={classes.pdfContainer}>
                                <StaticPDFViewer pdfBase64={pdfBase64} />
                            </Box>
                        )
                        : <EmptyPDFViewer />
                }
                
                
            </Grid>
        </Grid>
    );
}

export default VerifyDocumentSignature;