import { Box, Chip, Grid, Typography, Button, CircularProgress, Collapse } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import { isEmpty } from 'lodash-es';
import { resolveHTTPAcceptHeader } from '../../../utils/http';
import { fileToBase64 } from '../../../utils/io';
import clsx from 'clsx';

function UploadFiles({
    inline = true,
    onSubmit,
    validated = true,
    renderOptions,
    renderNotes,
    onResponse,
    onError,
    onLoad,
    types,
    buttonTitle = "import"
}) {
    const {
        acceptedFiles,
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop: (files) => onLoad && fileToBase64(files[0]).then(data => onLoad(data, files[0].name)),
        accept: resolveHTTPAcceptHeader(types),
        maxFiles: 1
    });
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [errors, setErrors] = useState({});
    const [errorsCollapse, setErrorsCollapse] = useState(false);

    const handleUpload = () => {
        return fileToBase64(acceptedFiles[0]).then(data => {
            setLoading(true);
            onSubmit(data)
                .then(res => {
                    onResponse && onResponse(res, setMessage, setSeverity, setErrors);
                    setLoading(false);
                })
                .catch(err => {
                    if(onError){
                        setSeverity("error");
                        onError(err, setMessage, setErrors);
                    }else{
                        setSeverity("error");
                        setMessage(t('unknown_error_occured'));
                    }
                    setLoading(false);
                });
        });
    };

    useEffect(() => {
        if (loading) {
            setErrors({});
            setMessage("");
        }
    }, [loading]);

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={inline ? 6 : 12}>
                    <div {...getRootProps({ className: classes.dropzone })}>
                        <input {...getInputProps()} />
                        <CloudUploadIcon fontSize="large" />
                        <Typography>
                            {t('drag_and_drop_file')} {t('or').toLowerCase()} <u>{t('browse').toLowerCase()}</u>
                        </Typography>
                    </div>
                </Grid>
                <Grid container item xs={inline ? 6 : 12} alignItems="flex-end" justifyContent="space-between" className={clsx(classes.uploadContainer, inline ? classes.inlineUploadContainer : "")}>
                    {
                        renderOptions &&
                        <Grid item container>
                            {renderOptions()}
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {
                            acceptedFiles[0]
                                ? <Box textAlign="center">
                                    <Typography display="inline"> {acceptedFiles[0].path} </Typography>
                                    <Chip
                                        label={<Typography variant="caption">{Number(acceptedFiles[0].size / 1024).toFixed(2)} {t('kilo_byte_abbr')}</Typography>}
                                        size="small"
                                        component="span"
                                        style={{ borderRadius: "4px" }}
                                    />
                                </Box>
                                : <Typography align="center"> {t('no_file_selected')} </Typography>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            disableElevation
                            fullWidth
                            onClick={acceptedFiles[0] && !loading ? handleUpload : () => { }}
                            disabled={!validated || !acceptedFiles[0]}
                        >
                            {
                                loading
                                    ? <> <CircularProgress disableShrink size="1.5rem" className={classes.circularProgress} />
                                        <Typography> {t('processing')} </Typography> </>
                                    : <Typography> {t(buttonTitle)} </Typography>
                            }
                        </Button>
                        {renderNotes && renderNotes()}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    {
                        message &&
                        <Box pb={2} pt={inline ? 0 : 2}>
                            <Alert
                                className={classes.alert}
                                severity={severity}
                                action={
                                    !isEmpty(errors) && 
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={() => setErrorsCollapse(!errorsCollapse)}
                                        style={{textTransform: "none"}}
                                    >
                                        {errorsCollapse ? t('hide_errors') : t('show_errors')}
                                    </Button>
                                }
                                style={{border: "1px solid #ccc"}}
                            >
                                <AlertTitle>{message}</AlertTitle>
                                {
                                    !isEmpty(errors) &&
                                    <Collapse in={errorsCollapse} timeout="auto">
                                        {
                                            Object.keys(errors).map((k, index) =>
                                                <Typography key={index} variant="body2">
                                                    {errors[k]}
                                                </Typography>
                                            )
                                        }
                                    </Collapse>
                                }
                            </Alert>
                        </Box>
                    }
                </Grid>
            </Grid>
        </>
    );
}

UploadFiles.propTypes = {
    type: PropTypes.array,
    inline: PropTypes.bool,
    buttonTitle: PropTypes.string,
    validated: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    renderOptions: PropTypes.func,
    renderNotes: PropTypes.func,
    onResponse: PropTypes.func,
    onError: PropTypes.func,
    onLoad: PropTypes.func
};

export default UploadFiles;
