import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ListColsHeader from '../../../App/components/ListColsHeader';
import { monthInvoicingColumns } from '../../pages/Invoicing/columns';
import { MonthInvoicingCard } from '../InvoicingCards';
import useStyles from './style';

function MonthInvoicingList({ loading, openCreateInvoice }) {
    const classes = useStyles();
    const filterYear = useSelector(({ invoicing }) => invoicing.filter.year);
    const monthInvoicingData = useSelector(({ invoicing }) => invoicing.monthInvoicing.list.data);

    const getTranformedMonthInvoicing = () => {
        const idx = [...monthInvoicingData].reverse().findIndex(m => m.count > 0);
        const end = 11 - (idx > -1 ? idx : 12);
        
        if(filterYear < new Date().getFullYear()){
            return monthInvoicingData;
        } else if(filterYear > new Date().getFullYear()){
            return monthInvoicingData.filter((_, idx) => idx <= end);
        } else {
            if(end > new Date().getMonth()){
                return monthInvoicingData.filter((_, idx) => idx <= end);
            }
        }
        
        return  monthInvoicingData.filter((_, idx) => idx <= new Date().getMonth());
    };

    return (
        <>
            <Grid container>
                <Grid item xs={11}>
                    <ListColsHeader
                        type="columns"
                        className={classes.listHeader}
                        columns={monthInvoicingColumns}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    {
                        loading
                            ? [...Array(12)].map((_, index) => (
                                <Box mb={2} key={index}>
                                    <Skeleton height={62.5} variant="rect" animation="wave" className={classes.skeleton} />
                                </Box>
                            ))
                            : getTranformedMonthInvoicing().map(m =>
                                <MonthInvoicingCard
                                    key={m.month}
                                    monthInvoicing={m}
                                    openCreateInvoice={openCreateInvoice}
                                />
                            )
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default MonthInvoicingList;
