import { SET_GLOBAL_FINANCIAL_INDICATORS } from "../../constants";


const initialState = {
    global: {
        CAVAMensuel: 0,
        MBthMensuel: 0,
        MBthCumule: 0
    }
};

export default (state = initialState, action) => {
    switch(action.type){
        case SET_GLOBAL_FINANCIAL_INDICATORS:
            return setGlobalIndicators(state, action.data);
        default:
            return state;
    }
};

const setGlobalIndicators = (state, data) => {
    return {
        ...state,
        global: data
    };
};