import React from 'react';
import { ADMIN_INVOICING_ROLE, BREADCRUMBS } from '../../../utils/constants';

const Analytics = React.lazy(() => import('../../pages/Analytics'));

export default [
    {
        path: '/analytics/aged-trial-balance',
        exact: true,
        name: 'aged_trial_balance',
        component: Analytics,
        permissions: [],
        private: true,
        className:'breadcrumb-settings',
        roles: [ADMIN_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    }
];