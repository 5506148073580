import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    container: {
        boxShadow: theme.shadows[25],
    },
    icon: {
        position: "relative",
        top: "8px",
        right: "8px",
        fontSize: "3rem",
        opacity: "0.3",
    },
    title: {
        fontWeight: "500",
        fontSize: "1.3rem"
    },
    content: {
        "&:last-child": {
            paddingBottom: "16px"
        },
        paddingTop: "0px"
    }
}));