import { Dialog, DialogActions, DialogContent, withStyles, IconButton, LinearProgress } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function CustomDialog({
    open, 
    onClose, 
    title, 
    renderTitle,
    renderContent, 
    renderActions, 
    renderErrors = null,
    className, 
    classes, 
    disableBackdropClick = false, 
    saving,
    maxWidth="lg",
    fullWidth = true,
    TransitionComponent
}) {

    return (
        <Dialog 
            open={open} 
            onClose={
                (event, reason) => reason === "backdropClick" 
                    ? (
                        disableBackdropClick 
                            ? null
                            : onClose(event, reason)
                    ) 
                    : onClose(event, reason)
            } 
            className={className}
            classes={classes}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            {...(TransitionComponent ? {TransitionComponent} : {})}
        >
            {
                renderTitle
                    ? renderTitle()
                    : (
                        <DialogTitle 
                            id="form-dialog-title"
                            onClose={onClose}
                        >
                            {title}
                        </DialogTitle>
                    )
            }
            {saving && <LinearProgress />}
            <DialogContent>
                {renderContent && renderContent()}
            </DialogContent>
            <DialogActions style={(renderErrors !== null) ? {display:"flex",justifyContent:"space-between"}:{}}>
                {renderErrors && renderErrors()}
                {renderActions && renderActions()}
            </DialogActions>
        </Dialog>
    );
}

export default CustomDialog;
