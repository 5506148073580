import { createStore, applyMiddleware, combineReducers } from 'redux';
import { handleRequests } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios';
import rootReducer from './view/redux/reducers/rootReducer';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import { init } from './axios';
import Keycloak from 'keycloak-js';
import { keycloakConfig } from './keycloak/config';
import { ReactENV } from './utils/io';

const persistConfig = {
    key: 'root',
    whitelist:['Auth', 'layout'],
    storage,
    timeout: 86400,
};

let kc = new Keycloak(keycloakConfig);
let axios = init(ReactENV.REACT_APP_SERVER_API_URL, kc);

export const configureStore = () => {
    const { requestsReducer, requestsMiddleware } = handleRequests({
        driver: createDriver(axios),
    });
    
    const reducers = combineReducers(
        {...rootReducer, requests: requestsReducer }
    );

    const persistedReducer = persistReducer(persistConfig, reducers);

    const store = createStore(
        persistedReducer,
        composeWithDevTools(applyMiddleware(...requestsMiddleware))
    );
    const persistor = persistStore(store);

    return {
        store,
        persistor,
        axios,
        kc
    };
};