import { INVOICE_STATUS } from "./constants";
import moment from "moment";

export const getInvoiceDate = (invoice) => {
    return invoice && invoice.issueDate
        ? invoice.issueDate
        : invoice.expectedDate;
};

export const isPREVISIONNELLE = (invoice) => invoice && invoice.status && invoice.status.code === INVOICE_STATUS.PREVISIONNELLE.code;
export const isA_VENIR = (invoice) => invoice && invoice.status && invoice.status.code === INVOICE_STATUS.A_VENIR.code;
export const isEMISE = (invoice) => invoice && invoice.status && invoice.status.code === INVOICE_STATUS.EMISE.code;
export const isREGLEE = (invoice) => invoice && invoice.status && invoice.status.code === INVOICE_STATUS.REGLEE.code;
export const isAVOIREE = (invoice) => invoice && invoice.status && invoice.status.code === INVOICE_STATUS.AVOIREE.code;
export const isPARTIELLEMENT_REGLEE = (invoice) => invoice && invoice.status && invoice.status.code === INVOICE_STATUS.PARTIELLEMENT_REGLEE.code;

export const getInvoiceStatusColor = (code) => {
    return INVOICE_STATUS[code];
};

export const getInvoiceFileName = (invoice) => {
    return `${invoice.number ? (invoice.number + " -") : ""} ${invoice.invoicingEntity ? invoice.invoicingEntity.name : invoice.client.name} - Facture Novelis - ${invoice.label} - ${invoice.issueDate ? moment(invoice.issueDate).format("MMMM yyyy") : ""} v1.0`;
};

export function calculatePayedAmount(payments){
    return Number(payments.map(p=>p.amount).reduce((t, p)=>(t+p),0).toFixed(2));
}

export function calculatePayedAmountInDefaultCurrency(payments){
    return Number(payments.map(p=>p.amount * p.exchangeRate).reduce((t, p)=>(t+p),0).toFixed(2));
}

export function calculateRemainingAmount(total, payments){
    return Number((total - payments.map(p=>p.amount).reduce((t, p)=>(t+p),0)).toFixed(2));
}

export function incrementMonth(date, monthsToAdd = 1) {
    const nextMonth = moment().add(monthsToAdd, "months");
    return nextMonth.set("date", Math.min(moment(date).date(), nextMonth.daysInMonth())).format("yyyy-MM-DD");
}