import {
  Box,
  Button,
  TextField,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import NoData from "../../../../../App/components/NoData";

function TaxTypeTable({
  classes,
  dispatch,
  loading,
  typeInstances,
  actionModal,
  setActionModal,
  displayModal,
  rTable,
  renderColHeaders,
}) {
  const [createType, setCreateType] = useState(false);
  const [editType, setEditType] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [typeInstance, setTypeInstance] = useState({});
  const { t } = useTranslation();

  const openEditType = useCallback((item) => {
    setEditType(true);
    setTypeInstance({
      id: item.id,
      label: item.label,
      isRequired: item.isRequired,
      taxRates: item.taxRates,
    });
    setDisabled(false);
  }, []);

  const openCreateTypeForm = () => {
    setCreateType(true);
    setTypeInstance({
      label: "",
      isRequired: false,
      taxRates: [],
    });
  };

  const saveType = () => {
    dispatch(rTable.post(typeInstance)).then(res=>{
      if(res.status === 200){
        setCreateType(false);
        setTypeInstance({});
      }
    });
  };

  const cancelCreateType = () => {
    setCreateType(false);
  };

  const saveEditType = () => {
    dispatch(rTable.put(typeInstance.id, typeInstance)).then(res=>{
      if(res.status === 200){
        setEditType(false);
        setTypeInstance({});
      }
    });
  };

  const cancelEditType = () => {
    setEditType(false);
    setTypeInstance({});
  };

  const handleDeleteType = (item) => {
    setActionModal({
      ...actionModal,
      open: true,
      type: "error",
      title: t("suppression"),
      body: (
        <span>
          {t("confirm_delete_type")} <b>{item.label}</b> ?
        </span>
      ),
      showCloseButton: true,
      onChange: displayModal,
      cancel: {
        title: t("cancel"),
        onClick: null,
      },
      confirm: {
        title: t("confirm"),
        onClick: () => {
          dispatch(rTable.delete(item.id));
          displayModal(false);
        },
      },
    });
  };

  useEffect(() => {
    if (createType || editType) {
      setDisabled(typeInstance.label.length > 0 ? false : true);
    }
  }, [disabled, createType, typeInstance]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Grid container alignItems="flex-start">
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography color="primary" variant="h6">
            {t("tax_types_list")}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            className={classes.addButton}
            startIcon={<AddIcon />}
            onClick={openCreateTypeForm}
            hidden={createType}
          >
            {t("add")}
          </Button>
        </Grid>
        <Grid item xs={10}>
          {renderColHeaders("taxType")}
        </Grid>
      </Grid>
      <NoData
        condition={typeInstances.length === 0 && !createType}
        loading={loading}
      />
      {createType && (
        <Card className={classes.root} elevation={1}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={10}>
              <CardContent
                className={classes.content}
                style={{ paddingBottom: "16px" }}
              >
                <Grid container>
                  <Grid
                    container
                    item
                    xs={5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Box mr={1} textAlign="center">
                        <TextField
                          type="text"
                          placeholder={t("label")}
                          defaultValue=""
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            setTypeInstance({
                              ...typeInstance,
                              label: e.target.value,
                            });
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Box mr={1} textAlign="center">
                        <Checkbox
                          defaultChecked={false}
                          color="primary"
                          onChange={(e) => {
                            setTypeInstance({
                              ...typeInstance,
                              isRequired: e.target.checked,
                            });
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
            <Grid item xs={2}>
              <CardActions>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={saveType}
                      disabled={disabled}
                    >
                      {t("save")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Tooltip title={t("cancel")} placement="top">
                      <IconButton onClick={cancelCreateType}>
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </CardActions>
            </Grid>
          </Grid>
        </Card>
      )}
      {loading
        ? [...Array(3)].map((_, index) => (
            <Box key={index}>
              <Skeleton
                height={62.5}
                variant="rect"
                animation="wave"
                className={classes.skeleton}
              />
            </Box>
          ))
        : typeInstances &&
          typeInstances.map((item, index) => (
            <Card key={index} className={classes.root} elevation={1}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={10}>
                  <CardContent
                    className={classes.content}
                    style={{ paddingBottom: "16px" }}
                  >
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={5}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Box textAlign="center">
                            {editType && typeInstance.id === item.id ? (
                              <Box mr={1}>
                                <TextField
                                  type="text"
                                  placeholder={t("label")}
                                  defaultValue={item.label}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  onChange={(e) => {
                                    setTypeInstance({
                                      ...typeInstance,
                                      label: e.target.value,
                                    });
                                  }}
                                />
                              </Box>
                            ) : (
                              <Typography>{item.label}</Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={5}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Box textAlign="center">
                            {editType && typeInstance.id === item.id ? (
                              <Box mr={1} textAlign="center">
                                <Checkbox
                                  defaultChecked={item.isRequired}
                                  color="primary"
                                  onChange={(e) => {
                                    setTypeInstance({
                                      ...typeInstance,
                                      isRequired: e.target.checked,
                                    });
                                  }}
                                />
                              </Box>
                            ) : (
                              <Typography>
                                {" "}
                                {item.isRequired ? t("yes") : t("no")}{" "}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
                <Grid item xs={2}>
                  <CardActions>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item>
                        <Tooltip
                          title={
                            editType && typeInstance.id === item.id
                              ? t("save_quick_edit")
                              : t("edit")
                          }
                          placement="top"
                        >
                          {editType && typeInstance.id === item.id ? (
                            <IconButton
                              color="primary"
                              onClick={saveEditType}
                              disabled={disabled}
                            >
                              <DoneIcon />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => openEditType(item)}>
                              <EditIcon />
                            </IconButton>
                          )}
                        </Tooltip>
                      </Grid>
                      {editType && typeInstance.id === item.id && (
                        <Grid item>
                          <Tooltip title={t("cancel")} placement="top">
                            <IconButton onClick={cancelEditType}>
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item>
                        <Tooltip title={t("delete")} placement="top">
                          <IconButton onClick={() => handleDeleteType(item)}>
                            <DeleteIcon className={classes.deleteIcon} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Grid>
              </Grid>
            </Card>
          ))}
    </Box>
  );
}

export default TaxTypeTable;
