import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import clsx from "clsx";
import useStyles from "./style.js";

function DashboardCard({ title, icon, className, contentClassName, children, ...rest }) {
    const classes = useStyles();

    return (
        <Card className={clsx("h-100", "w-100", classes.container, className)} {...rest}>
            {(title || icon) && (
                <CardHeader
                    classes={{title: classes.title}}
                    title={title}
                    titleTypographyProps={{
                        variant: "h6",
                    }}
                    action={
                        icon && (
                            <SvgIcon
                                component={icon}
                                color="primary"
                                fontSize="large"
                                className={classes.icon}
                            />
                        )
                    }
                />
            )}
            <CardContent className={clsx(classes.content, contentClassName)}>{children}</CardContent>
        </Card>
    );
}

export default DashboardCard;
