
// eslint-disable-next-line no-undef
export const ReactENV = process.env;

// eslint-disable-next-line no-undef
export const ReactBuffer = Buffer;

export const downloadByteResponsePDF = (response, title) => {
    const base64String = ReactBuffer.from(response, "binary").toString("base64"); //eslint-disable-line
    downloadBase64ResponsePDF(base64String, title);
};

export const downloadBase64ResponsePDF = (response, title) => {
    const link = document.createElement("a");
    link.download = `${title}.pdf`;
    link.target = "_blank";
    link.href = `data:application/pdf;base64,${response}`;
    link.click();
};

export const downloadResponseXLSX = (response, title) => {
    const base64String = ReactBuffer.from(response, "binary").toString("base64"); //eslint-disable-line
    const link = document.createElement("a");
    link.download = `${title}.xlsx`;
    link.target = "_blank";
    link.href = `data:attachement/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
    link.click();
};

export const downloadResponseGeneric = (response, fileName, fileType) => {
    const base64String = ReactBuffer.from(response, "binary").toString("base64"); //eslint-disable-line
    const link = document.createElement("a");
    link.download = fileName;
    link.target = "_blank";
    link.href = `data:${fileType};base64,${base64String}`;
    link.click();
};

export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target.result.split(',')[1]);
        };
        reader.onerror = (err) => {
            reject(err);
        };
        reader.readAsDataURL(file);
    });
};
