import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    icon: {
        width: "1rem",
        height: "1rem",
        marginLeft: "0.5rem",
        color: "#AAAAAA",
        cursor: "help",
        '&:hover': {
            color: theme.palette.primary.main,
            transform: "scale(1.2)"
        }
    }
}));