import {
    GET_ALL_RT_CURRENCY,
    GET_ALL_AVAILABLE_CURRENCIES,
    POST_RT_CURRENCY,
    UPDATE_CURRENCIES_DEFAULTS,
    DELETE_RT_CURRENCY,
    SET_ALL_RT_CURRENCIES
} from '../../constants';
import { errorNotification, successNotification } from "../../../../utils/notification";
import { FINANCIAL_RT_ENDPOINT } from '../../constants/endpoint';
import { onSuccess, onError } from '../../../../utils/http';

export const getAllRtCurrencies = () => {
    return {
        type: GET_ALL_RT_CURRENCY,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/currency`
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setAllRtCurrencies(response.data));
                return response;
            })
        }
    };
};

export const getAllAvailableCurrencies = () => {
    return {
        type: GET_ALL_AVAILABLE_CURRENCIES,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/currency/available`,
            method: 'get',
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                return response;
            })
        }
    };
};

export const setAllRtCurrencies = (data) => {
    return {
        type: SET_ALL_RT_CURRENCIES,
        payload: data
    };
};

export const postRtCurrency = (currency) => {
    return {
        type: POST_RT_CURRENCY,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/currency`,
            method: 'post',
            data: currency
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "create_success");
                store.dispatch(getAllRtCurrencies());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    };
};


export const updateCurrency = (currencyId, currency) => {
    return {
        type: UPDATE_CURRENCIES_DEFAULTS,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/currency/${currencyId}`,
            method: 'put',
            data: currency
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "update_success");
                store.dispatch(getAllRtCurrencies());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "update_error");
                throw error;
            })
        }
    };
};

export const deleteRtCurrency = (id) => {
    return {
        type: DELETE_RT_CURRENCY,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/currency/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "delete_success");
                store.dispatch(getAllRtCurrencies());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', error.response.status === 409 ? "ressource_in_use_error" : "delete_error");
                throw error;
            })
        }
    };
};