import React, { useState } from "react";
import { Box, Button, Chip, Grid, Typography } from "@material-ui/core";
import useStyles from "./style";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {Edit, Close } from "@material-ui/icons";

import ListColsHeader from "../../../../../App/components/ListColsHeader";
import { useEffect } from "react";
import TaxRateTable from "./TaxRateTable";
import TaxTypeTable from "./TaxTypeTable";
import {
  getAllTaxTypeRates,
} from "../../../../redux/actions/rt";

function RtTaxesPanel({
  rTable,
  displayModal,
  actionModal,
  setActionModal,
  instances,
  loading,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [typeInstances, setTypeInstances] = useState([]);
  const [displayTypes, setDisplayTypes] = useState(false);
  const [selectedTypeInstance, setSelectedTypeInstance] = useState({});
  const [rateInstances, setRateInstances] = useState([]);

  const renderColHeaders = (type) => {
    switch (type) {
      case "taxRate":
        return (
          <ListColsHeader
            type="columns"
            className={classes.tableHeader}
            columns={[
              {
                id: 0,
                name: "value",
                width: 5,
              },
              {
                id: 1,
                name: "default_value",
                width: 5,
              },
            ]}
            disableSort={true}
          />
        );

      case "taxType":
        return (
          <ListColsHeader
            type="columns"
            className={classes.tableHeader}
            columns={[
              {
                id: 0,
                name: "label",
                width: 5,
              },
              {
                id: 1,
                name: "required",
                width: 5,
              },
            ]}
            disableSort={true}
          />
        );
      default:
        return;
    }
  };

  const handleTypeSelected = (type) => {
    dispatch(getAllTaxTypeRates(type.id)).then((response) => {
      setSelectedTypeInstance(type);
      setRateInstances(response.data);
    });
  }

  useEffect(() => {
    setTypeInstances(
      instances.map((instance) => {
        return {
          id: instance.id,
          label: instance.label,
          taxRates: instance.taxRates,
          isRequired: instance.isRequired,
        };
      })
    );
    if(instances.length === 0){
      setDisplayTypes(true)
    }else{
      setDisplayTypes(false)
    }
    return ()=>{
      setTypeInstances(null)
    }
  }, [instances]);

  return (
    <Box role="tabpanel" mt={4}>
      <Grid
          style={{ marginBottom: "2rem" }}
          container
          item
          xs={12}
          justifyContent="flex-start"
        >
          <Box display="flex" justifyContent="flex-start">
            {" "}
            {typeInstances &&
              typeInstances.map((item) => (
                <Chip
                  className={!displayTypes ? ((selectedTypeInstance.id === item.id) 
                    ? classes.selectedChip
                    : classes.hoverableChip) : classes.chip
                  }
                  key={item.id}
                  variant="outlined"
                  component="span"
                  onClick={() => {
                    handleTypeSelected(item);
                  }}
                  label={
                    <Typography variant="caption">{item.label}</Typography>
                  }
                />
              ))}
          </Box>
          <Button
            style={{marginLeft: "1rem"}}
            variant="text"
            color="primary"
            onClick={() => setDisplayTypes(!displayTypes)}
            endIcon={displayTypes ? <Close />: <Edit />}
          >
            {!displayTypes ? t("edit"): t("close")}
          </Button>
        </Grid>
        {displayTypes && <TaxTypeTable
        {...{
          loading,
          dispatch,
          classes,
          typeInstances,
          actionModal,
          setActionModal,
          displayModal,
          rTable,
          renderColHeaders,
        }}
      />}
      {typeInstances.length > 0 && (
        <TaxRateTable
          {...{
            loading,
            dispatch,
            renderColHeaders,
            classes,
            typeInstances,
            setActionModal,
            displayModal,
            displayTypes,
            setDisplayTypes,
            rateInstances,
            setRateInstances,
            selectedTypeInstance,
            setSelectedTypeInstance
          }}
        />
      )}
    </Box>
  );
}

export default RtTaxesPanel;
