import { Grid, OutlinedInput, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import useStyles from "./style";
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, setSearchFilter } from "../../redux/actions/users";
import UsersList from "../../components/UsersList";
import { useTranslation } from "react-i18next";

export default function Users() {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch])

    const UserSearchField = () => {
      const [searchText, setSearchText] = useState("");
      const dispatch = useDispatch();

      let timesOut = 0;
      const handleSearchChange = (event) => {
        const newValue = event.target.value;
        setSearchText(newValue);
        if (timesOut) clearTimeout(timesOut);

        timesOut = setTimeout(() => {
          dispatch(setSearchFilter(searchText));
        }, 600);
      };

      return (<OutlinedInput
        margin="dense"
        placeholder="Recherche des utilisateurs"
        className={classes.searchInput}
        startAdornment={<SearchIcon color="primary" style={{ marginRight: "8px" }} />}
        value={searchText}
        onChange={handleSearchChange}
      />);
    }
    
    return(
        <>
        <Grid container className={classes.container} direction="column">
          <Grid
            className={classes.header}
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography className={classes.title}>{t("users")}</Typography>
            </Grid>
            <Grid item xs={6}>
              {<UserSearchField/>}
            </Grid>
          </Grid>
          <UsersList />
        </Grid>
        {/* {userDialogOpen && <UserDialog setOpen={setUserDialogOpen} />} */}
      </>
    );
}