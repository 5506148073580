import React from 'react';
import {Link, matchPath, Route, withRouter} from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import { Breadcrumbs as MaterialBreadcrumbs, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { BREADCRUMBS } from '../../../utils/constants';
import { capitalize } from '../../../utils/converters';

let routes= [];
const getPaths = (pathname) => {
    const paths = ['/'];

    if (pathname === '/') return paths;
    pathname.split('/').reduce((prev, curr) => {
        const currPath = `${prev}/${curr}`;
        paths.push(currPath);
        return currPath;
    });

    return paths;
};

const findRouteName = (url) => {
    const aroute = routes.find(route => matchPath(url, {path: route.path, exact: route.exact}));
    return (aroute && aroute.name) ? aroute.name : null;
};

const findRouteClassName = (url) => {
    const aroute = routes.find(route => matchPath(url, {path: route.path, exact: route.exact}));
    return (aroute && aroute.className) ? aroute.className : null;
};

const BreadcrumbsItem = ({match}) => {
    const { t } = useTranslation();
    const routeClassName = findRouteClassName(match.url);
    const currentRoute = routes.find(route => matchPath(match.path, {path: route.path, exact: route.exact}));
    const params = matchPath(match.path, currentRoute).params;
    const pageTitle = useSelector(({appState}) => appState.pageTitle);
    const routeName = findRouteName(match.url);

    if (routeName) {
        let title = "";

        switch(currentRoute.breadcrumbs.name.type){
            case BREADCRUMBS.NAME.url_params:
                title = currentRoute.breadcrumbs.name.params.map(param => capitalize(t(params[param]))).join(" ");
                break;
            case BREADCRUMBS.NAME.default:
                title = (pageTitle && match.isExact) ? pageTitle : t(routeName);
                break;
            default:
                title = (pageTitle && match.isExact) ? pageTitle : t(routeName);
                break;
        }

        return (
            match.isExact 
                ? <Typography color="textSecondary" variant="body2">{title}</Typography>
                : <Link className={routeClassName} to={match.url || ''}>
                    <Typography variant="body2" color="primary" >{title}</Typography>
                </Link>
        );
    }
    
    return null;
};

const Breadcrumbs = (args) => {
    const classes = useStyles();
    const paths = getPaths(args.location.pathname);
    const currentRoute = routes.find(route => matchPath(paths[paths.length-1], {path: route.path, exact: route.exact}));
    const items = paths.map((path, i) => <Route key={i.toString()} path={path} component={BreadcrumbsItem} {...args} />)
        .filter((item) => routes.some(route => {
            const path = matchPath(item.props.path, route);
            if(item.props.path === paths[paths.length-1]){
                return path ? path.isExact : false;
            }

            return (path && route.breadcrumbs.name.display) ? path.isExact : false;
        }));

    return (
        <>
            {
                currentRoute && currentRoute.breadcrumbs.show
                    ? <MaterialBreadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadcrumbsPath}> 
                        { items.length > 0 ? items : '' }
                    </MaterialBreadcrumbs>
                    : null
            }
        </>
    );
};

const PilotageBreadcrumbs = (props) => {
    const {className, tag: Tag, appRoutes, ...attributes} = props;

    routes = appRoutes;
    delete attributes.children;
    const parentClasses = clsx(className);

    return (
        <Tag className={clsx(parentClasses)}>
            <Route path="/:path" component={Breadcrumbs} {...attributes} {...props} />
        </Tag>
    );
};

PilotageBreadcrumbs.defaultProps = {
    tag: 'div',
    className: '',
    appRoutes: []
};

export default withRouter(PilotageBreadcrumbs);
