import { GET_ALL_RT_INVOICING_STATUS, SET_ALL_RT_INVOICING_STATUS } from "../../constants";
import { FINANCIAL_RT_ENDPOINT } from "../../constants/endpoint";


export const getAllStatus = () => {
    return {
        type: GET_ALL_RT_INVOICING_STATUS,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/invoiceStatus`
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllInvoicingStatus(response.data?.sort((a,b)=>a.order-b.order)));
                return response;
            }
        }
    };
};

export const setAllInvoicingStatus = (data) => {
    return {
        type: SET_ALL_RT_INVOICING_STATUS,
        payload: data
    };
};