import moment from "moment";
import { getInitialPagination } from "../../../../utils/common-state";
import { PAGINATION_MODE } from "../../../../utils/constants";
import {
    SET_TOP_INVOICING_CLIENTS,
    SET_INVOICING_CLIENTS_PAYMENT_TIME,
    SET_DASHBOARD_INVOICES,
    SET_DASHBOARD_INVOICES_SORT,
    CLEAR_DASHBOARD_INVOICES_PAGINATION,
    SET_DASHBOARD_INVOICES_PAGINATION,
    SET_DASHBOARD_INVOICES_FILTER,
    CLEAR_DASHBOARD_INVOICES_FILTER,
    SET_TURNOVER_DATA
} from "../../constants";


const initialState = {
    turnoverData: {
        total: 0,
        pendingTotal: 0,
        expectedTotal: 0,
        evolution: 0,
        prevTotal: 0,
        list: []
    },
    topClients: null,
    clientsPaymentTime: [],
    invoices: {
        config: {
            pagination: getInitialPagination(false, PAGINATION_MODE.MINIFIED)
        },
        filter: {
            year: null,
            status: null,
            beforeExpectedDate: null,
            beforeExpectedSettlementDate: moment().format("yyyy-MM-DD"),
            beforeIssueDate: moment().endOf("month").add(1, "days").format("yyyy-MM-DD"),
            beforeSettlementDate: null,
            afterExpectedDate: null,
            afterExpectedSettlementDate: null,
            afterIssueDate: moment().set({ month: 0 }).startOf("month").subtract(1, "days").format("yyyy-MM-DD"),
            afterSettlementDate: null,
        },
        list: {
            sort: {
                col: { id: 6 },
                type: "asc"
            },
            page: 0,
            size: getInitialPagination(false, PAGINATION_MODE.MINIFIED).size,
            data: null
        },
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_TURNOVER_DATA:
            return setTurnoverData(state, action.payload);
        case SET_TOP_INVOICING_CLIENTS:
            return setTopInvoicingClients(state, action.payload);
        case SET_INVOICING_CLIENTS_PAYMENT_TIME:
            return setInvoicingClientsPaymentTime(state, action.payload);
        case SET_DASHBOARD_INVOICES:
            return setInvoices(state, action.payload);
        case SET_DASHBOARD_INVOICES_SORT:
            return setInvoicesSort(state, action.payload);
        case CLEAR_DASHBOARD_INVOICES_PAGINATION:
            return clearInvoicesPagination(state);
        case SET_DASHBOARD_INVOICES_PAGINATION: 
            return setInvoicesPagination(state, action.payload);
        case SET_DASHBOARD_INVOICES_FILTER:
            return setFilter(state, action.payload);
        case CLEAR_DASHBOARD_INVOICES_FILTER:
            return clearFilter(state);
        default:
            return state;
    }
};

const setTurnoverData = (state, data) => {
    return {
        ...state,
        turnoverData: data
    };
};

const setTopInvoicingClients = (state, data) => {
    return {
        ...state,
        topClients: data
    };
};

const setInvoicingClientsPaymentTime = (state, data) => {
    return {
        ...state,
        clientsPaymentTime: data
    };
};

const setInvoices = (state, data) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            config: {
                ...state.invoices.config,
                pagination: {
                    ...state.invoices.config.pagination,
                    page: parseInt(data.page),
                    size: parseInt(data.size),
                    maxLength: parseInt(data.maxLength)
                }
            },
            list: {
                ...state.invoices.list,
                page: parseInt(data.page),
                size: parseInt(data.size),
                data: data.invoices
            }
        }
    };
};

const setInvoicesSort = (state, sort) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            list: {
                ...state.invoices.list,
                sort: sort
            }
        }
    };
};

const clearInvoicesPagination = (state) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            config: {
                ...state.invoices.config,
                pagination: getInitialPagination(false, PAGINATION_MODE.MINIFIED)
            }
        }
    };
};

const setInvoicesPagination = (state, pagination) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            config: {
                ...state.invoices.config,
                pagination: pagination
            }
        }
    };
};

const setFilter = (state, filter) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            filter: {
                ...state.invoices.filter,
                ...filter
            }
        }
    };
};

const clearFilter = (state) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            filter: initialState.invoices.filter
        }
    };
};