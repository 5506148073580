import {
    Box,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
import { setYearInvoicingType } from '../../../App/appSlice';

function YearInvoicingType({filter, type}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const yearInvoicingType = useSelector(({ appState }) => appState.yearInvoicingType);
    const history = useHistory();
    const yearInvoicesView = useMemo(
        () => matchPath(history.location.pathname, { path: `/${type}/:year/${type === "invoicing" ? "invoices" : "all"}`, exact: true })?.isExact || false
        , [history.location.pathname]); //eslint-disable-line react-hooks/exhaustive-deps

    const handleRadioChange = (e) => {
        if (e.target.value === "months") {
            history.push({ pathname: `/${type}/${filter.year}`, state: { position: window.pageYOffset } });
        } else if (e.target.value === "all") {
            history.push({ pathname: `/${type}/${filter.year}/${type === "invoicing" ? "invoices" : "all"}`, state: { position: window.pageYOffset } });
        }
    };

    useEffect(() => {
        if (yearInvoicesView) {
            dispatch(setYearInvoicingType("all"));
        } else {
            dispatch(setYearInvoicingType("months"));
        }
        history.location.state && window.scrollTo(0, history.location.state.position);
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box display="flex" justifyContent="left">
            <RadioGroup row value={filter.month ? "all" : yearInvoicingType} onChange={handleRadioChange}>
                {
                    !filter.month &&
                    <FormControlLabel
                        value="months"
                        control={<Radio />}
                        label={<Typography variant="subtitle2">{t("monthly_summary")}</Typography>}
                        style={{ margin: 0 }}
                    />
                }
                <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label={<Typography variant="subtitle2">{t(type === "invoicing" ? "invoices_list" : "payments_list")}</Typography>}
                    style={filter.month ? { margin: 0 } : { margin: "0 18px" }}
                />
            </RadioGroup>
        </Box>
    );
}

export default React.memo(YearInvoicingType);