import React from 'react';
import { 
    Checkbox, 
    FormControl, 
    InputLabel, 
    ListItemText, 
    MenuItem, 
    Select, 
    Typography
} from '@material-ui/core';
import useStyles from './style';
import { useTranslation } from 'react-i18next';

function MultiSelect({label, value, onChange, list, disabled = false, checkmark = false, noOptionsLabel, renderLabel, renderValue}) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple
                value={value}
                onChange={onChange}
                label={label}
                renderValue={(selected) => renderValue ? renderValue(selected) : selected.map(s => typeof(s) === 'object' ? s.label : s).join(' | ')}
                disabled={disabled}
            >
                {
                    list && list.length > 0
                        ? list.map((i) => (
                            <MenuItem key={i.id} value={i.label} disableGutters className={classes.menuItem}>
                                {
                                    checkmark &&
                                    <Checkbox className={classes.menuItemCheckbox} size="small" checked={value.includes(i.label)} />
                                }
                                <ListItemText primary={renderLabel ? renderLabel(i) : i.label} />
                            </MenuItem>
                        ))
                        : <Typography className={classes.noOptions}>{noOptionsLabel ?? t("no_items_found")}</Typography>
                }
            </Select>
        </FormControl>
    );
}

export default MultiSelect;