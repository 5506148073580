import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function StaticPDFViewer({ pdfBase64 }) {
    const { t } = useTranslation();

    return (
        <object data={"data:application/pdf;base64,"+pdfBase64} type="application/pdf" width="100%" height="100%">
            <Typography variant="h5" align="center">{t('pdf_not_available')}</Typography>
        </object>
    );
}

StaticPDFViewer.propTypes = {
    pdfBase64: PropTypes.string.isRequired
};

export default StaticPDFViewer;