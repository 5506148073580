import { Box, Button, Collapse, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from "react-i18next";
import useStyles from './style';


function ValidationAlert({message, errors, collapse, collapsed}, ref) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box ref={ref} style={{display: Array.isArray(errors) && errors.length !== 0 ? "block" : "none", width:"70%"}}>
            <Alert
                className={classes.alert}
                severity="error"
                action={
                    !isEmpty(errors) && 
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => collapse(!collapsed)}
                        style={{textTransform: "none"}}
                    >
                        {collapsed ? t('hide_errors') : t('show_errors')}
                    </Button>
                }
                style={{border: "1px solid #ccc"}}
            >
                <AlertTitle>{message ?? t("validation_errors")}</AlertTitle>
                {
                    !isEmpty(errors) &&
                    <Collapse in={collapsed} timeout="auto">
                        {
                            Object.keys(errors).map((k, index) =>
                                <Typography key={index} variant="body2">
                                    {t(errors[k])}
                                </Typography>
                            )
                        }
                    </Collapse>
                }
            </Alert>
        </Box>
    );
}

export default React.forwardRef(ValidationAlert);