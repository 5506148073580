import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

function InvoiceAttachmentViewer({downloadAttachment, attachment, base64File }) {
    const dispatch = useDispatch();
    const [data, setData] = useState(null);

    useEffect(() => {
        if (attachment) {
            if(base64File){
                setData(base64File);
            }else{
                dispatch(downloadAttachment(attachment.uuid)).then(res => {
                    if (!res.error) {
                        // eslint-disable-next-line no-undef
                        const base64String = Buffer.from(res.action.response, "binary").toString("base64");
                        setData(base64String);
                    }
                });
            }
        }
    }, [attachment]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {
                attachment && data &&
                <object data={`data:${attachment.fileType};base64,${data}`} type={attachment.fileType} width="100%" height="100%">
                    <Typography variant="h5" align="center">Aperçu non disponible</Typography>
                </object>
            }
        </>
    );

}

export default InvoiceAttachmentViewer;