import { ReactENV } from "../utils/io";

export const SERVER_API_URL = ReactENV.REACT_APP_SERVER_API_URL;
export const AUTHORITIES = {
    ADMIN: "ROLE_ADMIN",
    USER: "ROLE_USER",
    RH: "ROLE_RH",
};
export const AUTH_TOKEN_KEY = "jhi-authenticationToken";
export const PER_PAGE = 12;
export const DAYS_ALLOW_VALIDATION = 7;
