import React from "react";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { get } from "lodash-es";

import useStyles from "./style";
import CustomDatePicker from "../../../App/components/CustomDatePicker";
import moment from "moment";
import { useTranslation } from "react-i18next";

export function InvoiceCloningCard({editable=false, invoice, columns, cloningList, handleOnChange}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderColumn = (col) => {
    if (col.apiName) {
      const val = get(invoice, col.apiName);
      let displayVal = val;
      if (col.formatOrElse) {
        displayVal = col.formatOrElse(val, invoice);
      } else if (val) {
        displayVal = col.format ? col.type === "monetary" ? col.format(val, invoice.currency) : col.format(val) : val;
      }

      return (
        <Grid
          key={col.id}
          container
          item
          xs
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Typography
              className={col.apiName === "number" ? classes.invoiceNumber : ""}
            >
              {displayVal ?? "N/A"}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return <i>N/A</i>;
  };

  const renderExpectedDate = (col) => (
            <Grid
              key={col.id}
              container
              item
              xs
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                
                <CustomDatePicker
                  key={col.id}
                  label={t("expected_date")}
                  onChange={(date)=> handleOnChange(moment(date).format("yyyy-MM-DD"), get(invoice, 'id'))}
                  emptyLabel={t("expected_date")}
                  className={classes.datePicker}
                  selectedDate={cloningList[get(invoice, 'id')]}
                  required={true}
                />
              </Grid>
            </Grid>
          )
    ;

  return (
    <Card className={classes.root} elevation={1}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs>
          <CardContent
            className={classes.content}
            style={{ paddingBottom: "16px", cursor: "default"}}
          >
            <Grid container>
              {columns.map((col) => (editable && (col.apiName === "expectedDate") ) ? renderExpectedDate(col) : renderColumn(col))}
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}
