import { alpha, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    chartContainer: {
        position: 'relative',
        height: '300px',
        width: '100%',
        padding: theme.spacing(1),
        borderRadius: "4px",
        backgroundColor: "#fcfffc",
        border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`
    },
    paper: {
        boxShadow: theme.shadows[25],
        padding: theme.spacing(1.5, 2)
    },
    title: {
        fontSize: "1.15rem",
        fontWeight: "500",
        marginBottom: theme.spacing(2)
    },
    paginationButton: {
        borderRadius: "4px",
        border: "1px solid #ccc",
        margin: theme.spacing(0, 0.5),
        boxShadow: "rgba(17, 17, 26, 0.1) 0px 0.5px 0px"
    }
}));
