import React, { useState } from 'react';
import {
    Grid,
    Paper,
    Typography,
    Button,
    OutlinedInput
} from '@material-ui/core';
import { formatAmount } from '../../../utils/converters';
import useStyles from './style';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../../App/components/CustomDialog';
import { getTopInvoicingClients } from '../../redux/actions/dashboard';
import SearchIcon from '@material-ui/icons/Search';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ListIcon from '@material-ui/icons/List';
import { cloneDeep } from 'lodash-es';
import NoData from '../../../App/components/NoData';
import { Skeleton } from '@material-ui/lab';


function TopClients() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const topClients = useSelector(({ dashboard }) => dashboard.topClients);
    const defaultCurrency = useSelector(({ rt }) => rt.currencies).find(c => c.isDefault);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [inverse, setInverse] = useState(false);

    const fetchAll = () => {
        dispatch(getTopInvoicingClients());
    };

    const handleOpen = () => {
        setOpen(true);
        fetchAll();
    };

    const renderTitle = () => {
        return (
            <Grid container alignItems="flex-start" justifyContent="space-between" spacing={2}>
                <Grid item>
                    <Typography variant="h6" color="textSecondary">{t('clients_classified_by_turnover')}</Typography>
                </Grid>
                <Grid container item xs={12} alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item xs={10}>
                        <OutlinedInput
                            margin="dense"
                            fullWidth
                            placeholder={t("search")}
                            onChange={(e) => setSearch(e.target.value)}
                            startAdornment={<SearchIcon style={{ marginRight: "8px" }} />}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            disableElevation
                            onClick={() => setInverse(!inverse)}
                            startIcon={inverse ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                            style={{ width: "6rem" }}
                        >
                            {inverse ? "DESC" : "ASC"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const renderContent = () => {
        return (
            <Grid container direction="column" justifyContent="flex-start" spacing={1}>
                <Grid item container>
                    { topClients &&
                        cloneDeep(topClients).sort((a, b) => inverse ? a.turnover - b.turnover : b.turnover - a.turnover)
                            .map((tc, index) => {
                                if (search === "" || tc.client.name.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                                    return (
                                        <Grid
                                            item
                                            container
                                            key={index}
                                            alignItems="center"
                                            className={classes.container2}
                                            style={index === 0 ? { borderTop: "1px solid #ddd" } : {}}
                                        >
                                            <Grid item xs={1}>
                                                <Typography variant="body2" color="primary">{inverse ? topClients.length - index : index + 1}</Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography style={{ fontWeight: 500 }}>{tc.client.name}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography align="right">{formatAmount(tc.turnover, defaultCurrency)}</Typography>
                                            </Grid>
                                        </Grid>
                                    );
                                } return null;
                            }
                            )
                    }
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <Grid container component={Paper} className={classes.paper}>
                <Grid item container alignItems="flex-start" justifyContent="space-between">
                    <Grid item>
                        <Typography color="textSecondary" className={classes.title}>
                            {t('top_5_clients')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        { topClients &&
                            topClients.length >= 5 &&
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                style={{ textTransform: "none" }}
                                onClick={handleOpen}
                                startIcon={<ListIcon />}
                            >
                                <Typography>{t('show_all')}</Typography>
                            </Button>
                        }
                    </Grid>
                </Grid>
                <Grid item container direction="column" justifyContent="flex-start" style={{ height: "80%" }}>
                    {topClients ? 
                        topClients.slice(0, 5).map((tc, index) =>
                            <Grid
                                container
                                item
                                key={index}
                                alignItems="center"
                                className={classes.container1}
                                style={index === 0 ? { borderTop: "1px solid #ddd" } : {}}
                            >
                                <Grid item xs={1}>
                                    <Typography variant="caption" color="primary">{index + 1}</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant="subtitle2">{tc.client.name}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2" align="right">{formatAmount(tc.turnover, defaultCurrency)}</Typography>
                                </Grid>
                            </Grid>
                        ):
                        [...Array(3)].map((_, idx) => (<Skeleton key={idx} height="2.5rem" />))
                    }

                    {topClients && <NoData condition={topClients.length === 0} />}
                </Grid>
            </Grid>

            <CustomDialog
                open={open}
                onClose={() => setOpen(false)}
                title={renderTitle()}
                renderContent={renderContent}
                classes={{
                    paper: classes.dialogPaper
                }}
                disableBackdropClick={true}
            />
        </>
    );

}

export default TopClients;