import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    container: {
      paddingTop: theme.spacing(3)
    },
    title: {
      fontSize: "1.8rem",
      color: theme.palette.primary.main,
      fontWeight: "500",
      marginBottom: theme.spacing(2)
    },
    addButton: {
      marginLeft: theme.spacing(1),
      textTransform: "none"
    },    
    searchInput: {
      "& .MuiOutlinedInput-notchedOutline": {
          border: "none"
      },
      backgroundColor: theme.palette.background.default,
      boxShadow: "rgba(0, 0, 0, 0.15) 0.5px 1px 2px 0px inset",
      transition: theme.transitions.create('width'),
      width: '29ch',
      '&:hover': {
          width: '40ch',
      }
    }
  }));