import { Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import CustomDatePicker from '../CustomDatePicker';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import useStyles from './style';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function DateRangePicker({ start, end, onStartChange, onEndChange, minDate, maxDate, inputVariant = "outlined", required = false, yearMonthOnly = false }) {
    const classes= useStyles();
    const invoicesYears = useSelector(({ invoicing }) => invoicing.invoicesYears);
    const { t } = useTranslation();

    return (
        <Grid container direction="row">
            <Grid item xs={5}>
                <CustomDatePicker
                    selectedDate={start}
                    onChange={onStartChange}
                    label={t('start')}
                    required={required}
                    InputClassName={classes.dateInput}
                    yearMonthOnly={yearMonthOnly}
                    minDate={minDate ?? moment().set({ month: 0, year: Math.min(...invoicesYears) }).format("yyyy-MM-DD")}
                    maxDate={end ?? moment().set({ month: 11, "year": Math.max(...invoicesYears) }).format("yyyy-MM")}
                    inputVariant={inputVariant}
                />
            </Grid>
            <Grid container item xs={2} alignItems="center" justifyContent="center">
                <ArrowRightAltIcon />
            </Grid>
            <Grid item xs={5}>
                <CustomDatePicker
                    selectedDate={end}
                    onChange={onEndChange}
                    label={t('end')}
                    required={required}
                    InputClassName={classes.dateInput}
                    yearMonthOnly={yearMonthOnly}
                    minDate={start ?? moment().set({ month: 0, year: Math.min(...invoicesYears) }).format("yyyy-MM-DD")}
                    maxDate={maxDate ?? moment().set({ month: 11, "year": Math.max(...invoicesYears) }).format("yyyy-MM")}
                    inputVariant={inputVariant}
                />
            </Grid>
        </Grid>
    );
}

export default DateRangePicker;
