import { alpha, makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    dropzone: {
        textAlign: "center",
        padding: theme.spacing(4, 2),
        margin: theme.spacing(2, 0),
        border: `2px dashed ${theme.palette.primary.main}`,
        backgroundColor: alpha(theme.palette.primary.main, 0.06),
        borderRadius: "4px",
        boxShadow: theme.shadows[25],
        cursor: "pointer"
    },
    uploadContainer: {
        padding: theme.spacing(2, 0)
    },
    inlineUploadContainer: {
        margin: theme.spacing(2, 0)
    },
    alert: {
        "& .MuiAlert-action": {
            alignItems: "flex-start"
        }
    },
    circularProgress: {
        color: "#eee", 
        marginRight: "20px"
    }
}));