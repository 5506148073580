import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactBuffer } from '../../../utils/io';
import StaticPDFViewer from '../StaticPDFViewer';
import useStyles from "./style";
import { useTranslation } from 'react-i18next';

function PDFViewer({ getPdfAction, pdfId }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [pdfBase64, setPdfBase64] = useState(null);

    const loadPdfToBase64 = () => {
        return new Promise((resolve, reject) => {
            dispatch(getPdfAction(pdfId, "pdf"))
                .then(data => {
                    if (!data.error) {
                        const base64String = ReactBuffer.from(data.action.response, 'binary').toString('base64');
                        resolve(base64String);
                    }
                    reject(data.error);
                });
        });
    };

    useEffect(() => {
        loadPdfToBase64()
            .then((data) => {
                setPdfBase64(data);
            })
            .catch(() => {
                setPdfBase64("");
            });
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        pdfBase64 
            ? <StaticPDFViewer pdfBase64={pdfBase64} />
            : (
                <Box className={classes.loader}>
                    {t("loading")}
                    <span className={classes.loaderDot1}>.</span>
                    <span className={classes.loaderDot2}>.</span>
                    <span className={classes.loaderDot3}>.</span>
                </Box>
            )
    );
}

export default PDFViewer;