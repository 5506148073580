import {
    CLEAR_ALL_CLIENTS_WITH_INVOICES,
    SET_AGED_TRIAL_BALNCE_PAGINATION,
    SET_AGED_TRIAL_BALNCE_SORT, SET_AGED_TRIAL_BALNCE_STATS,
    SET_ALL_CLIENTS_WITH_INVOICES
} from "../../constants";
import {getInitialPagination} from "../../../../utils/common-state";
import {PAGINATION_MODE} from "../../../../utils/constants";

const initialState = {
    clients: {
        config: {
            pagination: JSON.parse(localStorage.getItem("agedTrialBalance"))?.pagination || getInitialPagination(false, PAGINATION_MODE.MINIFIED)
        },
        list: {
            sort: {
                col: { id: 1 },
                type: "desc"
            },
            page: 0,
            size: getInitialPagination(false, PAGINATION_MODE.MINIFIED).size,
            data: []
        }
    },
    statistics: {
        totalReceivables: 1,
        totalDueReceivables: {
            lessThan30Days: 1,
            lessThan45Days: 1,
            moreThan45Days: 1
        },
        totalReceivablesNotYetDue: {
            lessThan30Days: 1,
            lessThan45Days: 1,
            moreThan45Days: 1
        },
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_CLIENTS_WITH_INVOICES:
            return setAllCientsWithInvoices(state, action.payload);
        case SET_AGED_TRIAL_BALNCE_SORT:
            return setAgedTrialBalanceSort(state, action.payload);
        case SET_AGED_TRIAL_BALNCE_PAGINATION:
            return setAgedTrialBalancePagination(state, action.payload);
        case SET_AGED_TRIAL_BALNCE_STATS:
            return setAgedTrialBalanceStats(state, action.payload);
        default:
            return state;
    }
}

const setAllCientsWithInvoices = (state, data) => {
    return {
        ...state,
        clients: {
            ...state.clients,
            config: {
                ...state.clients.config,
                pagination: {
                    ...state.clients.config.pagination,
                    page: parseInt(data.page),
                    size: parseInt(data.size),
                    maxLength: parseInt(data.maxLength)
                }
            },
            list: {
                ...state.clients.list,
                page: parseInt(data.page),
                size: parseInt(data.size),
                data: data.clients
            }
        }
    }
}


const setAgedTrialBalanceSort = (state, sort) => {
    return {
        ...state,
        clients: {
            ...state.clients,
            list: {
                ...state.clients.list,
                sort: sort
            }
        }
    }
}

const setAgedTrialBalancePagination = (state, pagination) => {
    const localStorageData = JSON.parse(localStorage.getItem("agedTrialBalance")) || {};
    localStorage.setItem("agedTrialBalance", JSON.stringify({
        ...localStorageData,
        pagination
    }));
    return {
        ...state,
        clients: {
            ...state.clients,
            config: {
                ...state.clients.config,
                pagination: pagination
            }
        }
    }
}

const setAgedTrialBalanceStats = (state, statistics) => {
    return {
        ...state,
        statistics: {
            ...state.statistics,
            totalReceivables: statistics.totalReceivables,
            totalDueReceivables: {
                lessThan30Days: statistics.totalDueReceivablesLessThan30Days,
                lessThan45Days: statistics.totalDueReceivablesLessThan45Days,
                moreThan45Days: statistics.totalDueDueReceivablesMoreThan45Days
            },
            totalReceivablesNotYetDue : {
                lessThan30Days: statistics.totalReceivablesNotYetDueLessThan30Days,
                lessThan45Days: statistics.totalReceivablesNotYetDueLessThan45Days,
                moreThan45Days: statistics.totalReceivablesNotYetMoreThan45Days
            } ,
        }
    }
}