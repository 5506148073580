import { alpha, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    chartContainer: {
        position: 'relative',
        height: '318px',
        width: '100%',
        padding: theme.spacing(1),
        borderRadius: "4px",
        backgroundColor: "#fcfffc",
        border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`
    },
    paper: {
        boxShadow: theme.shadows[25],
        padding: theme.spacing(1.5, 2)
    },
    title: {
        fontSize: "1.15rem",
        fontWeight: "500",
        marginBottom: theme.spacing(1)
    },
    legendButton: {
        color: "#fff",
        marginLeft: "8px",
        padding: "4px 0 4px 10px",
        textTransform: "none",
        '&:first-child': {
            marginLeft: 0
        }
    },
    accumulatedButton: {
        backgroundColor: "green"
    },
    legendCheckbox: {
        padding: 0,
        margin: "0 8px"
    },
    accumulatedSwitch:{
        margin: "0.5rem 0",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    }
}));
