import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    statusIcon: {
        fontSize: "1rem",
        marginRight: theme.spacing(1)
    },
    dateFilters: {
        padding: theme.spacing(0),
        color: theme.palette.primary.main
    },
    filterStart: {
        paddingLeft: "0px !important", 
        paddingTop: "0px !important", 
        paddingBottom: "0px !important"
    },
    filterMiddle: {
        paddingTop: "0px !important", 
        paddingBottom: "0px !important"
    },
    filterEnd: {
        paddingRight: "0px !important", 
        paddingBottom: "0px !important",
        paddingTop: `${theme.spacing(1)} !important`,
        margin: theme.spacing(1, 0, 0, 0)
    },
    dateFilterPopover: {
        "& .MuiPopover-paper": {
            padding: theme.spacing(1)
        }
    },
    dateRangeLabel: {
        fontWeight: 500
    }
}));
