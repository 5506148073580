import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Card, CardActions, CardContent, Grid, IconButton, Radio, TextField, Tooltip, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import useStyles from "./style";
import ListColsHeader from "../../../../../App/components/ListColsHeader";
import { useTranslation } from "react-i18next";
import NoData from "../../../../../App/components/NoData";
import { getAllAvailableCurrencies, updateCurrency } from "../../../../redux/actions/rt/currency";
import { Autocomplete } from "@material-ui/lab";

function RtTaxesPanel({
  rTable,
  displayModal,
  actionModal,
  setActionModal,
  instances,
  loading,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [createCurrency, setcreateCurrency] = useState(false);
  const [editCurrency, setEditCurrency] = useState(false);
  const [disabled , setDisabled] = useState(true);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [currencyInstances, setCurrencyInstances] = useState([]);
  const [isDefaultExists, setIsDefaultExists] = useState(false);
  const { t } = useTranslation();
  
  const renderColHeaders = () => {
    return (
      <ListColsHeader
        type="columns"
        className={classes.tableHeader}
        columns={rTable.columns}
        disableSort={true}
      />
    );
  };

  const openCreateCurrencyForm = () => {
    setcreateCurrency(true);
    setSelectedCurrency({
      code: null,
      symbol: null,
      isDefault: false,
      exchangeRate: 1.000,
    })
  }

  const saveCurrency = () => {
    dispatch(rTable.post(selectedCurrency)).then(res=>{
      if(res.status === 200){
        setcreateCurrency(false);
        setSelectedCurrency({});
      }
    });
  };

  const cancelCreateCurrency = () => {
    setcreateCurrency(false);
    setSelectedCurrency({});
  };

  const handleCurrencyChange = (val) => {
    setSelectedCurrency({
      ...selectedCurrency,
      code: val ? val.code : null,
      symbol: val ? val.symbol : null
    })
  } 

  const handleDeleteCurrency = (item) => {
    setActionModal({
      ...actionModal,
      open: true,
      type: "error",
      title: t("suppression"),
      body: (
        <span>
          {t("confirm_delete_currency")} <b>{item.code}</b> ?
        </span>
      ),
      showCloseButton: true,
      onChange: displayModal,
      cancel: {
        title: t("cancel"),
        onClick: null,
      },
      confirm: {
        title: t("confirm"),
        onClick: () => {
          dispatch(rTable.delete(item.id));
          displayModal(false);
        },
      },
    });
  };


  const handleRadioChange = (e) => {
    setSelectedCurrency({
      ...selectedCurrency,
      isDefault: e.target.checked,
      exchangeRate: e.target.checked ? 1 : selectedCurrency.exchangeRate
    })
    if(e.target.checked){
      setCurrencyInstances([
        ...currencyInstances.map((currency) =>
          e.target.checked
            ? { ...currency, isDefault: false }
            : currency
        ),
      ]);
    }
  }

  const openEditCurrency = useCallback((item) =>{
    setEditCurrency(true);
    setSelectedCurrency({
      id: item.id,
      code: item.code,
      symbol: item.symbol,
      isDefault: item.isDefault,
      exchangeRate: item.exchangeRate
    });
  }, [])

  const saveEditCurrency = () =>{
    dispatch(updateCurrency(selectedCurrency.id, selectedCurrency)).then(res=>{
      if(res.status === 200){
        setEditCurrency(false);
        setSelectedCurrency({});
      }
    })
  }

  const cancelEditCurrency = ()=>{
    setEditCurrency(false);
    setSelectedCurrency({});
    setCurrencyInstances(instances)
  }

  useEffect(() => {
    dispatch(getAllAvailableCurrencies()).then(res=>{
      if(res.status === 200){
        setAvailableCurrencies(res.data);
      }
    })
  }, [])//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrencyInstances(instances)
    setIsDefaultExists(instances.filter(instance=>instance.isDefault).length === 0 ? false : true)
  }, [instances])//eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if(createCurrency){
      setDisabled(selectedCurrency.code !== null  ? false : true)
    }
    if(editCurrency){
      const matchingCurrency = instances.find(instance => instance.id === selectedCurrency.id)
      setDisabled((selectedCurrency.isDefault === matchingCurrency.isDefault) && (selectedCurrency.exchangeRate === matchingCurrency.exchangeRate))
    }
  }, [selectedCurrency])//eslint-disable-line react-hooks/exhaustive-deps
  

  return (
    <Box role="tabpanel" mt={4}>
      <Grid container alignItems="flex-start">
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="outlined"
            color="primary"
            className={classes.addButton}
            startIcon={<AddIcon />}
            onClick={openCreateCurrencyForm}
            hidden={createCurrency}
          >
            {t("add")}
          </Button>
        </Grid>
        <Grid item xs={10}>
          {renderColHeaders()}
        </Grid>
      </Grid>
      <NoData
        condition={instances.length === 0 && !createCurrency}
        loading={loading}
      />
      {createCurrency && (
        <Card className={classes.root} elevation={1}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={10}>
              <CardContent
                className={classes.content}
                style={{ paddingBottom: "16px" }}
              >
                <Grid container>
                  <Grid
                    container
                    item
                    xs={3}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Box mr={1} textAlign="center">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={availableCurrencies}
                          getOptionLabel={(option) => option.code}
                          noOptionsText={t("no_currency_found")}
                          getOptionDisabled={(option) => currencyInstances.map(curr => curr.code).includes(option.code)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="outlined"
                              label={t("currency")}
                              size="small"
                            />
                          )}
                          onChange={(e, val) => handleCurrencyChange(val)}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={3}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Box mr={1} textAlign="center">
                        <Typography>{selectedCurrency?.symbol}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={3}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Box mr={1} textAlign="center">
                        <TextField
                          className={classes.inputField}
                          label={t("exchangeRate")}
                          defaultValue={selectedCurrency.exchangeRate}
                          type="number"
                          variant="outlined"
                          onChange={(e) =>
                            setSelectedCurrency({
                              ...selectedCurrency,
                              exchangeRate: Number(e.target.value),
                            })
                          }
                          fullWidth
                          size="small"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={3}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Box mr={1} textAlign="center">
                        {!isDefaultExists && <Radio
                          checked={selectedCurrency.isDefault}
                          color="primary"
                          onChange={handleRadioChange}
                        />}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
            <Grid item xs={2}>
              <CardActions>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={saveCurrency}
                      disabled={disabled}
                    >
                      {t("save")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Tooltip title={t("cancel")} placement="top">
                      <IconButton onClick={cancelCreateCurrency}>
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </CardActions>
            </Grid>
          </Grid>
        </Card>
      )}
      {loading
        ? [...Array(3)].map((_, index) => (
            <Box key={index}>
              <Skeleton
                height={62.5}
                variant="rect"
                animation="wave"
                className={classes.skeleton}
              />
            </Box>
          ))
        : currencyInstances &&
          currencyInstances.map((item, index) => (
            <Card key={index} className={classes.root} elevation={1}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={10}>
                  <CardContent
                    className={classes.content}
                    style={{ paddingBottom: "16px" }}
                  >
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={3}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Box textAlign="center">
                            <Typography>{item.code}</Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        item
                        xs={3}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Box textAlign="center">
                            <Typography>{item.symbol}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={3}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Box textAlign="center">
                            {editCurrency && selectedCurrency.id === item.id ? (
                              <TextField
                                className={classes.inputField}
                                label={t("exchangeRate")}
                                defaultValue={selectedCurrency.exchangeRate}
                                type="number"
                                variant="outlined"
                                onChange={(e) =>
                                  setSelectedCurrency({
                                    ...selectedCurrency,
                                    exchangeRate: Number(e.target.value),
                                  })
                                }
                                fullWidth
                                size="small"
                              />
                            ) : (
                              <Typography>{item.exchangeRate}</Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={3}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Box textAlign="center">
                            {!isDefaultExists &&
                            editCurrency &&
                            selectedCurrency.id === item.id ? (
                              <Radio
                                checked={selectedCurrency.isDefault}
                                color="primary"
                                onChange={handleRadioChange}
                              />
                            ) : (
                              item.isDefault && (
                                <CheckCircleIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              )
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
                <Grid item xs={2}>
                  <CardActions>
                    <Grid container alignItems="center" justifyContent="center">
                      
                        <Grid item>
                          <Tooltip
                            title={
                              editCurrency && selectedCurrency.id === item.id
                                ? t("save_quick_edit")
                                : t("edit")
                            }
                            placement="top"
                          >
                            {editCurrency && selectedCurrency.id === item.id ? (
                              <span>
                                <IconButton
                                  color="primary"
                                  onClick={saveEditCurrency}
                                  disabled={disabled}
                                >
                                  <DoneIcon />
                                </IconButton>
                              </span>
                            ) : (
                              <IconButton
                                onClick={() => openEditCurrency(item)}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                          </Tooltip>
                        </Grid>
                      
                      {editCurrency && selectedCurrency.id === item.id && (
                        <Grid item>
                          <Tooltip title={t("cancel")} placement="top">
                            <IconButton onClick={cancelEditCurrency}>
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item>
                        {!item.isDefault && (
                          <Tooltip title={t("delete")} placement="top">
                            <IconButton
                              onClick={() => handleDeleteCurrency(item)}
                            >
                              <DeleteIcon className={classes.deleteIcon} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  </CardActions>
                </Grid>
              </Grid>
            </Card>
          ))}
    </Box>
  );
}

export default RtTaxesPanel;
