import { Paper, Grid, Typography, Chip } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { formatAmount } from '../../../utils/converters';
import useStyles from './style';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InfoTooltip from '../../../App/components/InfoTooltip';
import { Skeleton } from '@material-ui/lab';


function TurnoverBox() {
    const classes = useStyles();
    const { t } = useTranslation();
    const turnoverData = useSelector(({ dashboard }) => dashboard.turnoverData);
    const defaultCurrency = useSelector(({ rt }) => rt.currencies).find(c => c.isDefault);
    const range = useMemo(() => {
        return {
            startMonth: (turnoverData.list.length > 0) 
                ? moment(turnoverData.list[0].month).format("yyyy-MM") 
                : moment().format("yyyy-MM"),
            endMonth: (turnoverData.list.length > 0) 
                ? moment(turnoverData.list[turnoverData.list.length-1].month).format("yyyy-MM") 
                : moment().format("yyyy-MM")
        };
    }, [turnoverData]);

    const renderTurnoverEvolution = () => {
        let chip;
        const value = Number(turnoverData.evolution*100);
        if (value > 0) {
            chip = (
                <Chip
                    label={`+${value.toFixed(2)} %`}
                    className={classes.trendUpChip}
                    icon={<TrendingUpIcon style={{ color: "green" }} />}
                    size="small"
                ></Chip>
            );
        } else if (value < 0) {
            chip = (
                <Chip
                    label={`${value.toFixed(2)} %`}
                    className={classes.trendDownChip}
                    icon={<TrendingDownIcon style={{ color: "red" }} />}
                    size="small"
                ></Chip>
            );
        } else if (value === 0) { 
            chip = (
                <Chip
                    label={`${value.toFixed(2)} %`}
                    className={classes.trendFlatChip}
                    icon={<TrendingFlatIcon />}
                    size="small"
                ></Chip>
            );
        } else {
            chip = (
                <Chip
                    label="---"
                    className={classes.noTrendChip}
                    size="small"
                ></Chip>
            );
        }
        return (
            <>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {t('evolution')}
                    <InfoTooltip
                        text={t('turnover_evolution_info', {
                            totalTurnover: `${formatAmount(turnoverData.total, defaultCurrency)}`,
                            startMonth: moment(range.startMonth).format("MMMM yyyy"),
                            endMonth: moment(range.endMonth).format("MMMM yyyy"),
                            prevTotalTurnOver: `${formatAmount(turnoverData.prevTotal, defaultCurrency)}`,
                            prevStartMonth: moment(range.startMonth).subtract(1, "years").format("MMMM yyyy"),
                            prevEndMonth: moment(range.endMonth).subtract(1, "years").format("MMMM yyyy"),
                        })}
                    />
                </Typography>
                {chip}
            </>
        );
    };

    return (

        <Grid container component={Paper} className={classes.paper}>
            <Grid item>
                <Typography color="textSecondary" className={classes.title}>{t('net_turnover')}</Typography>
            </Grid>
            <Grid container item justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography className={classes.totalTurnover}>
                        {(turnoverData.total !== null) ? 
                            formatAmount(turnoverData.total, defaultCurrency)
                            :   
                            <Skeleton height="3rem" width="10rem" />
                        }
                    </Typography>
                </Grid>
                <Grid item>
                    {renderTurnoverEvolution()}
                </Grid>
            </Grid>
            <Grid container item className={classes.extraData}>
                <Grid>
                    <Typography color="textSecondary" className={classes.extrasContainer}>
                        {(turnoverData.pendingTotal !== null) ? <span className={classes.pendingTotal}>{formatAmount(turnoverData.pendingTotal, defaultCurrency)}</span> : <Skeleton className={classes.extrasSkeleton} />}  {" "} <span>{t('of_which_pending_payment')}</span>
                    </Typography>
                </Grid>
                <Grid>
                    <Typography color="textSecondary" className={classes.extrasContainer}>
                        {(turnoverData.expectedTotal !== null) ? <span className={classes.expectedTotal}>{formatAmount(turnoverData.expectedTotal, defaultCurrency)}</span> : <Skeleton className={classes.extrasSkeleton} />} {" "} <span>{t('total_expected_payment')}</span>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>

    );

}

export default TurnoverBox;