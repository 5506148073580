import { getInitialPagination } from "../../../../utils/common-state";
import { INVOICE_STATUS} from "../../../../utils/constants";
import {
    CLEAR_FINANCIAL_INVOICES_PAGINATION,
    CLEAR_FINANCIAL_SELECTED_INVOICE,
    CLEAR_FINANCIAL_INVOICING_FILTER,
    SET_FINANCIAL_INVOICES,
    SET_FINANCIAL_INVOICES_PAGINATION,
    SET_FINANCIAL_INVOICING_FILTER,
    SET_FINANCIAL_INVOICING_STATISTICS,
    SET_FINANCIAL_MONTH_INVOICING,
    SET_FINANCIAL_SELECTED_INVOICE,
    SET_SEARCH_FINANCIAL_INVOICES,
    SET_FINANCIAL_INVOICE_AUDIT,
    CLEAR_SEARCH_FINANCIAL_INVOICES,
    SET_FINANCIAL_INVOICES_SORT,
    CLEAR_FINANCIAL_INVOICES_SORT,
    SET_INVOICE_ATTACHMENTS,
    SET_FINANCIAL_ACTIONS_AUDIT,
    SET_FINANCIAL_ACTIONS_AUDIT_FILTER,
    SET_FINANCIAL_ACTIONS_AUDIT_PAGINATION,
    SET_FINANCIAL_INVOICES_YEARS
} from "../../constants";


const initialState = {
    statistics: {
        cahtInvoiced: 0,
        cahtToInvoice: 0,
        cahtAwaitingPayment: 0,
        statusCount: [
            {
                id: 1,
                name: 'Prévisionnelle',
                code: INVOICE_STATUS.PREVISIONNELLE.code,
                count: 1,
                order: 1
            },
            {
                id: 2,
                name: 'A venir',
                code: 'A_VENIR',
                count: 1,
                order: 2
            },
            {
                id: 3,
                name: 'Emise',
                code: 'EMISE',
                count: 1,
                order: 3
            },
            {
                id: 4,
                name: 'Partiellement réglée',
                code: 'PARTIELLEMENT_REGLEE',
                count: 1,
                order: 4
            },
            {
                id: 5,
                name: 'Réglée',
                code: 'REGLEE',
                count: 1,
                order: 5
            },
            {
                id: 6,
                name: 'Avoirée',
                code: 'AVOIREE',
                count: 1,
                order: 6
            },
        ]
    },
    filter: {
        responsables: [],
        clients: [],
        invoicingEntities: [],
        month: null,
        year: new Date().getFullYear(),
        projects: [],
        invoiceTypes: [],
        status: [],
        beforeExpectedDate: null,
        beforeExpectedSettlementDate: null,
        beforeIssueDate: null,
        beforeSettlementDate: null,
        afterExpectedDate: null,
        afterExpectedSettlementDate: null,
        afterIssueDate: null,
        afterSettlementDate: null,
        ...JSON.parse(localStorage.getItem("invoicing"))?.filter || {} 
    },
    invoices: {
        config: {
            pagination: JSON.parse(localStorage.getItem("invoicing"))?.pagination || getInitialPagination(false) 
        },
        list: {
            sort: JSON.parse(localStorage.getItem("invoicing"))?.sort || {
                col: { id: 0 },
                type: "desc"
            },
            page: 0,
            size: JSON.parse(localStorage.getItem("invoicing"))?.pagination?.size || getInitialPagination(false).size,
            data: []
        },
        selected: null,
        audit: {
            changes: {
                data: []
            },
            actions: {
                data: [],
                filter: {
                    resourceId: null,
                    author: null,
                    type: null,
                    afterDate: null,
                    beforeDate: null,
                    ...JSON.parse(localStorage.getItem("globalAudit"))?.filter || {} 
                },
                config: {
                    pagination: JSON.parse(localStorage.getItem("globalAudit"))?.pagination || getInitialPagination(false) 
                }
            }
        },
        attachments: [],
        searchResults: {
            list: {
                maxLength: 0,
                data: []
            }
        }
    },
    monthInvoicing: {
        list: {
            sort: {
                col: { id: 0 },
                type: "desc"
            },
            data: []
        },
        selected: null
    },
    invoicesYears: []
};

export default (state = initialState, action) => {
    switch(action.type){
        case SET_FINANCIAL_INVOICING_FILTER:
            return setInvoicingFilter(state, action.payload);
        case CLEAR_FINANCIAL_INVOICING_FILTER:
            return clearInvoicingFilter(state);
        case SET_FINANCIAL_INVOICING_STATISTICS:
            return setInvoicingStatistics(state, action.payload);
        case SET_FINANCIAL_INVOICES_YEARS:
            return setInvoicesYears(state, action.payload);
        case SET_FINANCIAL_MONTH_INVOICING:
            return setMonthInvoicing(state, action.payload);
        case SET_FINANCIAL_INVOICES_PAGINATION:
            return setInvoicesPagination(state, action.payload);
        case SET_FINANCIAL_INVOICES:
            return setInvoices(state, action.payload);
        case SET_SEARCH_FINANCIAL_INVOICES:
            return setSearchInvoices(state, action.payload);
        case SET_FINANCIAL_SELECTED_INVOICE:
            return setSelectedInvoice(state, action.payload);
        case SET_FINANCIAL_INVOICE_AUDIT:
            return setInvoiceAudit(state, action.payload);
        case SET_FINANCIAL_ACTIONS_AUDIT:
            return setActionsAudit(state, action.payload);
        case SET_FINANCIAL_ACTIONS_AUDIT_FILTER:
            return setActionsAuditFilter(state, action.payload);
        case SET_FINANCIAL_ACTIONS_AUDIT_PAGINATION:
            return setActionsAuditPagination(state, action.payload);
        case SET_INVOICE_ATTACHMENTS:
            return setInvoiceAttachments(state, action.payload);
        case CLEAR_FINANCIAL_INVOICES_PAGINATION:
            return clearInvoicesPagination(state);
        case CLEAR_SEARCH_FINANCIAL_INVOICES:
            return clearSearchInvoices(state);
        case CLEAR_FINANCIAL_SELECTED_INVOICE:
            return clearSelectedInvoice(state);
        case SET_FINANCIAL_INVOICES_SORT:
            return setInvoicingSort(state, action.payload);
        case CLEAR_FINANCIAL_INVOICES_SORT:
            return clearInvoicingSort(state);
        default:
            return state;
    }
};

const setInvoicingFilter = (state, filter) => {
    const localStorageData = JSON.parse(localStorage.getItem("invoicing")) || {};
    localStorage.setItem("invoicing", JSON.stringify({
        ...localStorageData,
        filter
    }));
    return {
        ...state,
        filter: {
            ...state.filter,
            ...filter
        }
    };
};

const clearInvoicingFilter = (state) => {
    const localStorageData = JSON.parse(localStorage.getItem("invoicing")) || {};
    localStorage.setItem("invoicing", JSON.stringify({
        ...localStorageData,
        filter: {
            ...initialState.invoices.list.filter,
        },
    }));
    return {
        ...state,
        filter: initialState.invoices.list.filter
    };
};

const setInvoicingStatistics = (state, data) => {
    return {
        ...state,
        statistics: data
    };
};

const setInvoicesYears = (state, data) => {
    return {
        ...state,
        invoicesYears: data.sort((a, b) => a - b)
    };
};

const setMonthInvoicing = (state, data) => {
    return {
        ...state,
        monthInvoicing: {
            ...state.monthInvoicing,
            list: {
                ...state.monthInvoicing.list,
                data: data
            }
        }
    };
};

const setInvoicesPagination = (state, pagination) => {
    const localStorageData = JSON.parse(localStorage.getItem("invoicing")) || {};
    localStorage.setItem("invoicing", JSON.stringify({
        ...localStorageData,
        pagination
    }));
    return {
        ...state,
        invoices: {
            ...state.invoices,
            config: {
                ...state.invoices.config,
                pagination: pagination
            }
        }
    };
};

const clearInvoicesPagination = (state) => {
    const localStorageData = JSON.parse(localStorage.getItem("invoicing")) || {};
    localStorage.setItem("invoicing", JSON.stringify({
        ...localStorageData,
        pagination: {
            ...getInitialPagination(false),
            size: localStorageData?.pagination?.size || getInitialPagination(false).size
        },
    }));
    return {
        ...state,
        invoices: {
            ...state.invoices,
            config: {
                ...state.invoices.config,
                pagination: JSON.parse(localStorage.getItem("invoicing"))?.pagination || getInitialPagination(false)
            }
        }
    };
};

const setInvoices = (state, data) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            config: {
                ...state.invoices.config,
                pagination: {
                    ...state.invoices.config.pagination,
                    page: parseInt(data.page),
                    size: parseInt(data.size),
                    maxLength: parseInt(data.maxLength)
                }
            },
            list: {
                ...state.invoices.list,
                page: parseInt(data.page),
                size: parseInt(data.size),
                data: data.invoices
            }
        }
    };
};

const setSearchInvoices = (state, data) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            searchResults: {
                list: {
                    maxLength: parseInt(data.maxLength),
                    data: data.invoices
                }
            }
        }
    };
};

const clearSearchInvoices = (state) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            searchResults: initialState.invoices.searchResults
        }
    };
};

const setSelectedInvoice = (state, data) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            selected: data
        }
    };
};

const setInvoiceAudit = (state, data) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            audit: {
                ...state.invoices.audit,
                changes: {
                    data: data
                },
            }
        }
    };
};

const setActionsAudit = (state, data) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            audit: {
                ...state.invoices.audit,
                actions: {
                    ...state.invoices.audit.actions,
                    data: data
                },
            }
        }
    };
};

const setActionsAuditFilter = (state, filter) => {
    const localStorageData = JSON.parse(localStorage.getItem("globalAudit")) || {};
    localStorage.setItem("globalAudit", JSON.stringify({
        ...localStorageData,
        filter
    }));
    return {
        ...state,
        invoices: {
            ...state.invoices,
            audit: {
                ...state.invoices.audit,
                actions: {
                    ...state.invoices.audit.actions,
                    filter: {
                        ...state.invoices.audit.actions.filter,
                        ...filter
                    }
                },
            }
        }
    };
};


const setActionsAuditPagination = (state, pagination) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            audit: {
                ...state.invoices.audit,
                actions: {
                    ...state.invoices.audit.actions,
                    config: {
                        ...state.invoices.audit.actions.config,
                        pagination: pagination,
                    }
                },
            }
        }
    };
};

const setInvoiceAttachments = (state, data) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            attachments: data
        }
    };
};

const clearSelectedInvoice = (state) => {
    return {
        ...state,
        invoices: {
            ...state.invoices,
            selected: null,
            audit: initialState.invoices.audit
        }
    };
};

const setInvoicingSort = (state, sort) => {
    const localStorageData = JSON.parse(localStorage.getItem("invoicing")) || {};
    localStorage.setItem("invoicing", JSON.stringify({
        ...localStorageData,
        sort
    }));
    return {
        ...state,
        invoices: {
            ...state.invoices,
            list: {
                ...state.invoices.list,
                sort: sort
            }
        }
    };
};

const clearInvoicingSort = (state) => {
    const localStorageData = JSON.parse(localStorage.getItem("invoicing")) || {};
    localStorage.setItem("invoicing", JSON.stringify({
        ...localStorageData,
        sort: {
            ...initialState.invoices.list.sort,
        },
    }));
    return {
        ...state,
        invoices: {
            ...state.invoices,
            list: {
                ...state.invoices.list,
                sort: initialState.invoices.list.sort
            }
        }
    };
};