import React, { useEffect, useState } from "react";
import {
    Grid,
    Paper,
    TextField,
    Button,
    Typography,
    InputAdornment,
    MenuItem,
    FormControl,
    Select
} from "@material-ui/core";
import useStyles from './style';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getInvoicingConfig, putInvoicingConfig } from "../../redux/actions/parameters";
import {
    TimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import ScheduleIcon from '@material-ui/icons/Schedule';
import { LANGUAGES } from "../../../utils/constants";
import { validateEmail } from "../../../utils/validation";

moment.locale("fr");

function InvoicingConfig() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const invoicingConfig = useSelector(({ parameters }) => parameters.invoicingConfig);
    const [data, setData] = useState({
        invoiceReminderTime: "00:00",
        daysBeforeIssueReminder: 0,
        daysBeforeSettlementReminder: 0,
        paymentDelay: 0,
        invoiceGenerationLanguage: "",
        amountSearchTolerance: 0,
        cciEmail: ""
    });

    useEffect(() => {
        dispatch(getInvoicingConfig());
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (invoicingConfig) {
            setData(invoicingConfig);
        }
    }, [invoicingConfig]);

    const save = () => {
        dispatch(putInvoicingConfig(data));
    };

    const disableSubmit = () => {
        return (invoicingConfig && data.invoiceReminderTime === invoicingConfig.invoiceReminderTime
            && data.daysBeforeIssueReminder === invoicingConfig.daysBeforeIssueReminder
            && data.daysBeforeSettlementReminder === invoicingConfig.daysBeforeSettlementReminder
            && data.paymentDelay === invoicingConfig.paymentDelay
            && data.invoiceGenerationLanguage === invoicingConfig.invoiceGenerationLanguage
            && ((data.cciEmail === invoicingConfig.cciEmail) || ((data.cciEmail?.length > 0) && !validateEmail(data.cciEmail)))
            && data.amountSearchTolerance === invoicingConfig.amountSearchTolerance);
    };

    return (

        <Paper className={classes.paper}>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <Typography variant="h6">{t('invoice_reminders')}</Typography>
                </Grid>
                <Grid container item alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography>{t('send_reminders_at')} </Typography>
                    </Grid>
                    <Grid item style={{width: "9rem"}}>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="fr">
                            <TimePicker
                                variant="inline"
                                openTo="hours"
                                forma="HH:mm"
                                value={moment(data.invoiceReminderTime, "HH:mm")}
                                onChange={val => setData({ ...data, invoiceReminderTime: moment(val).format("HH:mm") })}
                                autoOk
                                ampm={false}
                                disableToolbar
                                inputVariant="outlined"
                                InputProps={{
                                    style: { height: "36px" },
                                    endAdornment: <InputAdornment style={{margin: 0}} position="end">
                                        <ScheduleIcon />
                                    </InputAdornment>
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">({t('reminder_time_config_info')})</Typography>
                    </Grid>
                </Grid>
                <Grid container item alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography>{t('issue_reminder_config_text_1')} </Typography>
                    </Grid>
                    <Grid item style={{ width: "6rem" }}>
                        <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                            value={data.daysBeforeIssueReminder}
                            onChange={e => setData({ ...data, daysBeforeIssueReminder: Number(e.target.value) })}
                            inputProps={{
                                min: 0
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography>{t('issue_reminder_config_text_2')}.</Typography>
                    </Grid>
                </Grid>
                <Grid container item alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography>{t('settlement_reminder_config_text_1')} </Typography>
                    </Grid>
                    <Grid item style={{ width: "6rem" }}>
                        <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                            value={data.daysBeforeSettlementReminder}
                            onChange={e => setData({ ...data, daysBeforeSettlementReminder: Number(e.target.value) })}
                            inputProps={{
                                min: 0
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography>{t('settlement_reminder_config_text_2')}.</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="h6">{t('payments')}</Typography>
                </Grid>
                <Grid container item alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography>{t('payment_delay_config_text_1')} </Typography>
                    </Grid>
                    <Grid item style={{ width: "7rem" }}>
                        <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                            value={data.paymentDelay}
                            onChange={e => setData({ ...data, paymentDelay: Number(e.target.value) })}
                            inputProps={{
                                min: 0
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography>{t('payment_delay_config_text_2')}.</Typography>
                    </Grid>
                </Grid>
                <Grid container item alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography>{t('amount_tolerance_for_grouped_payment_search')} </Typography>
                    </Grid>
                    <Grid item style={{ width: "7rem" }}>
                        <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                            value={data.amountSearchTolerance}
                            onChange={e => setData({ ...data, amountSearchTolerance: Number(e.target.value) })}
                            inputProps={{
                                min: 0
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="h6">{t('languages')}</Typography>
                </Grid>
                <Grid container item alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography>{t('invoice_generation_language_text')} </Typography>
                    </Grid>
                    <Grid item style={{ width: "9rem" }}>
                        <FormControl
                            style={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                        >
                            <Select
                                value={data.invoiceGenerationLanguage}
                                onChange={(e)=> setData({...data, invoiceGenerationLanguage: e.target.value})}
                                style={{
                                    backgroundColor: "#fbfbfb",
                                    borderRadius: 4,
                                }}
                            >
                                {LANGUAGES.map(language => <MenuItem key={language.key} value={language.key}>{language.text}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="h6">{t('emails')}</Typography>
                </Grid>
                <Grid container item alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography>{t('cciEmail')}</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            type="text"
                            variant="outlined"
                            size="small"
                            placeholder="xxxxx@email.com"
                            value={data.cciEmail}
                            error={(data.cciEmail?.length > 0) && !validateEmail(data.cciEmail)}
                            onChange={e => setData({ ...data, cciEmail: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid container item justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={save}
                        disabled={disableSubmit()}
                    >{t('save')}</Button>
                </Grid>
            </Grid>
        </Paper>

    );

}

export default InvoicingConfig;