import { 
    GET_GLOBAL_FINANCIAL_INDICATORS, 
    SET_GLOBAL_FINANCIAL_INDICATORS
} from "../../constants";
import { GLOBAL_FINANCIAL_INDICATORS_ENDPOINT } from "../../constants/endpoint";


export const getGlobalIndicators = () => {
    return {
        type: GET_GLOBAL_FINANCIAL_INDICATORS,
        request: {
            url: GLOBAL_FINANCIAL_INDICATORS_ENDPOINT,
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setGlobalIndicators(response.data));
                return response;
            }
        }
    };
};

export const setGlobalIndicators = (data) => {
    return {
        type: SET_GLOBAL_FINANCIAL_INDICATORS,
        data: data
    };
};
