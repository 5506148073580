import React from 'react';
import moment from "moment";
import { formatAmount, formatDate, formatDateTime } from "../../../utils/converters";

export const invoiceColumns = [
    {
        id: 0,
        name: 'invoice_number',
        apiName: 'number',
        width: 1,
        sort: true,
        format: val => <span style={{fontWeight: "500"}}>{val}</span>
    },
    {
        id: 1,
        name: 'client',
        apiName: 'client.name',
        width: 2,
        sort: true
    },
    {
        id: 2,
        name: 'amount_excl_tax',
        apiName: 'amountExclTax',
        width: 2,
        sort: true,
        type: "monetary",
        format: (val, currency) => `${formatAmount(Number(val), currency)}`
    },
    {
        id: 3,
        name: 'issue_date',
        apiName: 'issueDate',
        width: 2,
        sort: true,
        format: formatDate
    },
    {
        id: 4,
        name: 'responsable',
        apiName: 'responsable',
        width: 2,
        sort: true,
        format: val => `${val.firstName} ${val.lastName}`
    },
    {
        id: 5,
        name: 'delay',
        apiName: 'expectedSettlementDate',
        width: 1,
        sort: true,
        inverseSort: true,
        format: val => <span style={{color: "red", fontWeight: "bold"}}>{moment().diff(moment(val), "days")}</span>
    },
    {
        id: 6,
        name: 'last_reminder',
        apiName: 'lastReminder',
        width: 2,
        sort: true,
        format: formatDateTime
    },
];