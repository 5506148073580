import { alpha, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        margin: theme.spacing(3, 0),
        padding: theme.spacing(2, 0),
        backgroundColor: "#fff",
        borderRadius: 4,
        boxShadow: theme.shadows[25],
    },
    statsBox: {
        borderRadius: 4,
        backgroundColor: "#eee",
        boxShadow: "none",
        height: "100%"
    },
    statsIcon: {
        color: alpha(theme.palette.primary.main, 0.3)
    },
    doughnutChartTotal: {
        position: 'absolute',
        top: '20%',
        left: '70%',
        transform: 'translate(-50%, -50%)',
        border: "1px solid gray",
        borderRadius: "5px",
        padding: "0.5rem",
        color: "gray",
        backgroundColor: "white",
        "&::after":{
            content: '""',
            position: "absolute",
            top: "100%",
            left: "50%",
            marginLeft: "-8px",
            borderWidth: "8px",
            borderStyle: "solid",
            borderColor: "gray transparent transparent transparent",
        }
    },
    insightCardAmountLine: {
        marginBottom: "0.5rem"
    },
    invoiceStatItem: {
        height: "100%",
        paddingTop: 16,
        paddingBottom: 16
    },
    statsCardContent: {
        paddingBottom: 16,
        height: "100%"
    },
    singleCurrencyStats: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }
}));
