import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    noOptions: {
        textAlign: "center",
        padding: theme.spacing(1)
    },
    menuItem: {
        padding: theme.spacing(0, 1)
    },
    menuItemCheckbox: {
        "&:hover": {
            background: "none"
        }
    }
}));