import React, {useEffect, useState} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/MenuOutlined";
import clsx from "clsx";
import { toogleMenu } from "../../../appSlice.js";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../style.js";
import i18n from "../../../../i18n/index.js";
import { useTranslation } from "react-i18next";
import { Avatar, CardActionArea, Grid, ListItemText, OutlinedInput } from "@material-ui/core";
import { logout } from "../../../../view/redux/actions/auth/index.js";
import { ArrowDropDown } from "@material-ui/icons";
import SearchIcon from '@material-ui/icons/Search';
import { infoNotification } from "../../../../utils/notification.js";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import TuneIcon from '@material-ui/icons/Tune';
import { ReactENV } from "../../../../utils/io.js";
import { LANGUAGES } from "../../../../utils/constants";
import { InvoiceSearch } from "../../../../view/components/index.js";
import { getAvatarColor } from "../../../../utils/avatar";
import {getUserAllInvoiceTenants} from "../../../../view/redux/actions/users";
import BusinessIcon from '@material-ui/icons/Business';
import OrganizationChooser from "../../../../view/components/OrganizationChooser";


const changeLanguage = (language, setAnchorLangEl) => {
    setAnchorLangEl(null);
    localStorage.setItem("language", language)
    i18n.changeLanguage(language);
};

function HeaderToolbar({ setOpenMySettings }) {
    const classes = useStyles();
    const menuExpanded = useSelector(({ appState }) => appState.menuExpanded);
    const keycloak = useSelector(({ keycloak }) => keycloak);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [anchorNotifEl, setAnchorNotifEl] = React.useState(null);
    const [anchorLangEl, setAnchorLangEl] = React.useState(null);
    const [anchorProfileEl, setAnchorProfileEl] = React.useState(null);
    const [openSearch, setOpenSearch] = React.useState(false);
    const headerExpanded = useSelector(
        ({ appState }) => appState.headerExpanded
    );
    const user = useSelector(({ Auth }) => Auth.user);
    const {firstName, lastName} = useSelector(({ Auth }) => Auth.user);
    const currentCollaborator = useSelector(({ collaborators }) => collaborators.currentCollaborator);
    const [openOrganizaitonChooser, setOpenOrganizationChooser] = useState(false);

    const profile = () => {
        return (
            <Grid item className="text-center">
                <IconButton
                    onClick={(e) => setAnchorProfileEl(e.currentTarget)}
                >
                    <Badge
                        overlap="circular"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        badgeContent={<ArrowDropDown color="primary" />}
                    >
                        <Avatar
                            style={{ backgroundColor: getAvatarColor(firstName + lastName) }}
                        >
                            {`${firstName.charAt(0)}${lastName.charAt(0)}`}
                        </Avatar>
                    </Badge>
                </IconButton>
            </Grid>
        );
    };

    useEffect(() => {
        dispatch(getUserAllInvoiceTenants(user.id));
        // console.log(user);
    }, []);

    return (
        <>
            <AppBar
                color="inherit"
                position={headerExpanded ? "relative" : "fixed"}
                className={clsx(
                    classes.appBar,
                    menuExpanded && classes.appBarShift,
                    classes.appBarFolded
                )}
                elevation={24}
            >
                <Toolbar className={classes.toolbar}>
                    <div className="align-items-center d-inline-flex">
                        <IconButton
                            edge="start"
                            color="primary"
                            aria-label="open Menu"
                            className={classes.menuButton}
                            onClick={() => dispatch(toogleMenu())}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                    <div className="align-items-center d-inline-flex">
                        <OutlinedInput
                            margin="dense"
                            placeholder="Recherche de factures"
                            className={classes.searchInput}
                            onClick={() => setOpenSearch(true)}
                            startAdornment={<SearchIcon color="primary" style={{ marginRight: "8px" }} />}
                            readOnly
                        />
                    </div>
                    <div className="align-items-center d-inline-flex">
                        <IconButton
                            onClick={(e) => setAnchorLangEl(e.currentTarget)}
                        >
                            <Typography
                                style={{width: "20px"}}
                                className={
                                    `flag-icon flag-icon-${LANGUAGES.find(({ key }) => key === i18n.language).flag} flag-icon-squared`
                                }
                            />
                        </IconButton>
                        {/*<IconButton
                            onClick={(e) => setAnchorNotifEl(e.currentTarget)}
                        >
                             //<Badge badgeContent={4} color="secondary">
                            //<NotificationsIcon />
                        //</Badge> 
                            <NotificationsIcon />
                        </IconButton>
                        */}
                        {!headerExpanded && profile()}
                    </div>
                </Toolbar>
                <Popover
                    id={Boolean(anchorNotifEl) ? "notifications-popover" : undefined}// eslint-disable-line no-extra-boolean-cast 
                    open={Boolean(anchorNotifEl)}
                    anchorEl={anchorNotifEl}
                    onClose={() => setAnchorNotifEl(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <Box p={2}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {t("no_notifications")}
                        </Typography>
                    </Box>
                </Popover>
                <Popover
                    id="langage-popover"
                    open={Boolean(anchorLangEl)}
                    anchorEl={anchorLangEl}
                    onClose={() => setAnchorLangEl(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {LANGUAGES.map(({ flag, key, text, isSupported }) => (
                        <CardActionArea
                            key={key}
                            onClick={() =>
                                isSupported
                                    ? changeLanguage(key, setAnchorLangEl)
                                    : infoNotification(
                                        "",
                                        t("language_not_yet_supported", { lang: text })
                                    )
                            }
                            style={{
                                backgroundColor:
                                    key === i18n.language
                                        ? "rgba(0,0,0,0.1)"
                                        : "rgba(0,0,0,0)",
                            }}
                        >
                            <ListItemText
                                primary={
                                    <span className="d-flex justify-content-between">
                                        <Typography
                                            className={`flag-icon flag-icon-${flag} flag-icon-squared`}
                                        />
                                        {text}
                                    </span>
                                }
                                className={classes.languageItemText}
                            />
                        </CardActionArea>
                    ))}
                </Popover>
                <Popover
                    id="profile-popover"
                    open={Boolean(anchorProfileEl)}
                    anchorEl={anchorProfileEl}
                    onClose={() => setAnchorProfileEl(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    PaperProps={{
                        style: {
                            padding: "6px 0",
                        },
                    }}
                >
                    {!currentCollaborator?.isReadOnly && 
                    <CardActionArea
                        onClick={() => {
                            setAnchorProfileEl(null);
                            window.open(`${ReactENV.REACT_APP_NOVY_WEBAPP_URL}/collaborators/user`, "_blank");
                        }}
                    >
                        <ListItemText
                            primary={
                                <Typography variant="body2">
                                    <PersonIcon fontSize="small" />{" "}
                                    {t("my_novy_account")}{" "}
                                </Typography>
                            }
                            className={classes.listItemText}
                        />
                    </CardActionArea>
                    }
                    {!currentCollaborator?.isReadOnly && 
                    <CardActionArea onClick={() => {
                        setOpenMySettings(true);
                        setAnchorProfileEl(null);
                    }}
                    >
                        <ListItemText
                            primary={
                                <Typography variant="body2">
                                    <TuneIcon fontSize="small" />{" "}
                                    {t("my_settings")}
                                </Typography>
                            }
                            className={classes.listItemText}
                        />
                    </CardActionArea>
                    }
                    <CardActionArea onClick={() => setOpenOrganizationChooser(true)}>
                        <ListItemText
                            primary={
                                <Typography variant="body2">
                                    <BusinessIcon fontSize="small" /> {t("organization")}
                                </Typography>
                            }
                            className={classes.listItemText}
                        />
                    </CardActionArea>
                    <CardActionArea onClick={() => dispatch(logout(keycloak))}>
                        <ListItemText
                            primary={
                                <Typography variant="body2">
                                    <ExitToAppIcon fontSize="small" /> {t("logout")}{" "}
                                </Typography>
                            }
                            className={classes.listItemText}
                        />
                    </CardActionArea>
                </Popover>
            </AppBar>

            <InvoiceSearch
                open={openSearch}
                setOpen={setOpenSearch}
            />

            <OrganizationChooser
                open={openOrganizaitonChooser}
                setOpen={setOpenOrganizationChooser}

            />
        </>
    );
}

export default HeaderToolbar;
