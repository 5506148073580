import React, { useState, useRef} from 'react';
import {
    Typography,
    Grid,
    Box,
    Card,
    CardContent
} from '@material-ui/core';
import { Doughnut, Chart } from 'react-chartjs-2';
import useStyles from './style';
import moment from 'moment';
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import { useSelector } from 'react-redux';
import { getInvoiceStatusColor } from '../../../utils/invoice-helper';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../../utils/converters';
import clsx from 'clsx';

moment.locale("fr");

var doughnutChart;

Chart.pluginService.register({
    beforeDraw: function (chart) {
        doughnutChart = chart;
        if (chart.config.options.elements.center) {
            var ctx = chart.chart.ctx;
            var centerConfig = chart.config.options.elements.center;
            var txt = centerConfig.text;
            var color = '#222';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
            ctx.font = "14px Roboto";
            ctx.fillStyle = color;
            ctx.fillText(txt, centerX, centerY);
        }
    }
});

function InvoicingStats() {
    const { t } = useTranslation();
    const classes = useStyles();
    const statistics = useSelector(({ invoicing }) => invoicing.statistics);
    const defaultCurrency = useSelector(({ rt }) => rt.currencies).find(c => c.isDefault);
    const doughnutRef = useRef(null);
    const [isDoughnutCenterHover, setIsDoughnutCenterHover] = useState(false);

    const renderDoughnut = () => {
        const data = statistics ? statistics.statusCount.sort((a,b)=> a.order-b.order).map(x => x.count) : [];
        const customLabels = statistics ? statistics.statusCount.map((status) => ({code: status.code,count: status.count})) : Array(6).fill("");
        const options = {
            maintainAspectRatio: false,
            legend: {
                position: "left",
                labels: {
                    padding: 10,
                    boxWidth: 20
                },
                onClick: () => { },
                onHover: (event, legendItem) => {
                    var chart = doughnutChart;
                    var index = legendItem.index;
                    var segment = chart.getDatasetMeta(0).data[index];
                    chart.tooltip._active = [segment];
                    chart.tooltip.update();
                    chart.draw();
                },
                onLeave: () => {
                    var chart = doughnutChart;
                    chart.tooltip._active = [];
                    chart.tooltip.update();
                    chart.draw();
                }
            },
            elements: {
                center: {
                    text: data.reduce((x, y) => x + y, 0)
                }
            },
            onHover: (event, elements) =>{
                const canvasPosition = doughnutRef.current.chartInstance.canvas.getBoundingClientRect();
                const centerX = canvasPosition.left + (canvasPosition.width / 1.5);
                const centerY = canvasPosition.top + (canvasPosition.height / 2);
                const mouseX = event.clientX;
                const mouseY = event.clientY;
                const deltaX = mouseX - centerX;
                const deltaY = mouseY - centerY;
                const radius = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                if(radius < 28){
                    setIsDoughnutCenterHover(true);
                }else if (radius > 28 && isDoughnutCenterHover){
                    setIsDoughnutCenterHover(false);
                }
            },
            tooltips: {
                backgroundColor: "#fff",
                titleFontColor: "#555",
                bodyFontColor: "#555",
                borderColor: "#777",
                borderWidth: 1,
                caretSize: 6,
                bodyFontSize: 12,
                xPadding: 10,
                yPadding: 10,
                bodySpacing: 10,
                titleMarginBottom: 10,
                callbacks: {
                    label: (tooltipItem) => `${t(customLabels[tooltipItem.index]?.code)}: ${customLabels[tooltipItem.index]?.count}`,
                    afterLabel: (tooltipItem) => {
                        const status = statistics.statusCount.find(e => e.code === customLabels[tooltipItem.index].code);
                        return (status && status.amount) ? `${formatAmount(status.amount, defaultCurrency)}` : "";
                    }
                }
            }
        };
        const chartData = {
            labels: customLabels.map(label=> `${t(label.code)}: ${label.count}`),
            datasets: [
                {
                    data: data,
                    backgroundColor: statistics ? statistics.statusCount.map(s => getInvoiceStatusColor(s.code).color) : Array(6).fill("#ddd"),
                    hoverBorderWidth: "1px",
                    hoverBorderColor: c => c.dataset.backgroundColor[c.dataIndex]
                },
            ],
        };

        return (
            <Doughnut
                ref={doughnutRef}
                data={chartData}
                options={options}
                height={145}
            />
        );
    };

    return (
        <Grid item xs={12} container className={classes.root}>
            <Grid item container xs={4} alignContent="center">
                <Grid item xs={12} style={{position: "relative"}}>
                    <Box>
                        {renderDoughnut()}
                        {statistics && isDoughnutCenterHover && (
                            <Box className={classes.doughnutChartTotal}>
                                {formatAmount(statistics.statusCount.map(s=>s.amount).reduce((x,y)=>x+y,0))}{defaultCurrency?.symbol}
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Grid item container xs={8} alignItems="center" spacing={2}>
                <Grid item xs={4} className={classes.invoiceStatItem}>
                    <Card className={classes.statsBox}>
                        <CardContent className={clsx(classes.statsCardContent, statistics?.currencyPartitionedStats?.length <= 1 && classes.singleCurrencyStats)}>
                            <Box height="35%">
                                <Typography variant="caption">{t("montant_ca_ht_facture")}</Typography>
                            </Box>
                            <Box>
                                {(statistics?.currencyPartitionedStats?.length > 1) && statistics?.currencyPartitionedStats?.map( data =>(
                                    <Grid key={data.currency.code} className={classes.insightCardAmountLine} container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography display="inline">{statistics && formatAmount(data?.cahtInvoiced, data?.currency)}</Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Grid className={classes.insightCardAmountLine} container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant='h6' display="inline">{statistics && formatAmount(statistics.cahtInvoiced, defaultCurrency)}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <DoneOutlineRoundedIcon fontSize="large" className={classes.statsIcon} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4} className={classes.invoiceStatItem}>
                    <Card className={classes.statsBox}>
                        <CardContent className={clsx(classes.statsCardContent, statistics?.currencyPartitionedStats?.length <= 1 && classes.singleCurrencyStats)}>
                            <Box height="35%">
                                <Typography variant="caption">{t("montant_ca_ht_a_facturer")}</Typography>
                            </Box>
                            <Box>
                                {(statistics?.currencyPartitionedStats?.length > 1) && statistics?.currencyPartitionedStats?.map( data =>(
                                    <Grid key={data.currency.code} className={classes.insightCardAmountLine} container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography display="inline">{statistics && formatAmount(data?.cahtToInvoice, data?.currency)}</Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Grid className={classes.insightCardAmountLine} container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="h6" display="inline">{statistics && formatAmount(statistics.cahtToInvoice, defaultCurrency)}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <PostAddRoundedIcon fontSize="large" className={classes.statsIcon} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4} className={classes.invoiceStatItem}>
                    <Card className={classes.statsBox}>
                        <CardContent className={clsx(classes.statsCardContent, statistics?.currencyPartitionedStats?.length <= 1 && classes.singleCurrencyStats)}>
                            <Box height="35%">
                                <Typography variant="caption">{t("montant_ca_ht_facture_en_attente")}</Typography>
                            </Box>
                            <Box>
                                {(statistics?.currencyPartitionedStats?.length > 1) && statistics?.currencyPartitionedStats?.map( data =>(
                                    <Grid key={data.currency.code} className={classes.insightCardAmountLine} container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography display="inline">{statistics && formatAmount(data?.cahtAwaitingPayment, data?.currency)}</Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="h6" display="inline">{statistics && formatAmount(statistics.cahtAwaitingPayment, defaultCurrency)}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <HourglassEmptyRoundedIcon fontSize="large" className={classes.statsIcon} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(InvoicingStats);