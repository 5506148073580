import ExcelIcon from "../App/assets/icons/excel.svg";
import PdfIcon from '../App/assets/icons/pdf.svg';
import WordIcon from '../App/assets/icons/word.svg';
import ImageIcon from '../App/assets/icons/image.svg';
import FileIcon from '../App/assets/icons/file.svg';

export const getFileExtensionFromFileName = (fileName) => {
    const arr = fileName.split(".");
    return arr[arr.length - 1];
};

export const getFileTypeFromExtension = (extension) => {
    switch (extension) {
        case "pdf":
            return "application/pdf";
        case "xlsx":
            return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        case "xls":
            return "application/vnd.ms-excel";
        case "docx":
            return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        case "doc":
            return "application/msword";
        case "jpg":
            return "image/jpg";
        case "png":
            return "image/png";
        case "jpeg":
            return "image/jpeg";
        default:
            return "application/octet-stream";
    }
};

export const getFileIconFromExtention = (extension) => {
    switch (extension) {
        case "pdf":
            return PdfIcon;
        case "xlsx":
        case "xls":
            return ExcelIcon;
        case "docx":
        case "doc":
            return WordIcon;
        case "png":
        case "jpg":
        case "jpeg":
            return ImageIcon;
        default:
            return FileIcon;
    }
};

export const getFileTypeNameFromExtenstion = (extension) => {
    switch (extension) {
        case "pdf":
            return "PDF";
        case "xlsx":
        case "xls":
            return "Excel";
        case "docx":
        case "doc":
            return "Word";
        case "jpg":
        case "png":
        case "jpeg":
            return "Image";
        default:
            return "Fichier";
    }
};