import { onError, onSuccess } from "../../../../utils/http"
import {
    DELETE_USER,
    GET_ALL_USERS, GET_CURRENT_USER_TENANTS,
    POST_USER,
    PUT_USER,
    SET_ALL_USERS,
    SET_CURRENT_USER_TENANTS,
    SET_SEARCH_FILTER,
    SWITCH_CURRENT_USER_TENANT
} from "../../constants";
import { ACCOUNT_ENDPOINT, FINANCIAL_INVOICING_ENDPOINT } from "../../constants/endpoint";
import { errorNotification, successNotification } from "../../../../utils/notification";
import {setCurrentUser} from "../auth";

export const getAllUsers = () => {
    return {
        type: GET_ALL_USERS,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/collaborators/responsables`,
            method: "get",
        },
        meta: {
            onSuccess: onSuccess(({response, store}) => {
                store.dispatch(setAllUsers(response.data));
                return response;
            })
        }
    }
}

export const deleteUser = (user) => {
    return {
        type: DELETE_USER,
        request: {
            url: `${ACCOUNT_ENDPOINT}/users/${user.id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', 'delete_success');
                return response;
            }),
            onError: onError(({error}) => {
                errorNotification('', "delete_error");
                throw error;
            })
        }
    }
}


export const setAllUsers = (data) => {
    return {
        type: SET_ALL_USERS,
        payload: data
    };
}

export const postUser = (user) => {
    switch (user.role) {
        case "Admin":
            user.isAdmin = true;
            break;
        case "Manager":
            user.isManager = true;
            break;
        case "Read Only":
            user.isReadOnly = true;
            break;
        default:
            break;
    }
    return {
        type: POST_USER,
        request: {
            url: `${ACCOUNT_ENDPOINT}/users`,
            method: 'post',
            data: user
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "create_success");
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    };
};

export const putUSer = (user) => {
    switch (user.role) {
        case "Admin":
            user.isAdmin = true;
            break;
        case "Manager":
            user.isManager = true;
            break;
        case "Read Only":
            user.isReadOnly = true;
            break;
        default:
            break;
    }
    return {
        type: PUT_USER,
        request: {
            url: `${ACCOUNT_ENDPOINT}/users/${user.id}`,
            method: 'put',
            data: user
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "create_success");
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    }
}

export const setSearchFilter = (filter) => {
    return {
        type: SET_SEARCH_FILTER,
        payload: filter
    }
}

export const setCurrentUserTenants = (tenants) => {
    return {
        type: SET_CURRENT_USER_TENANTS,
        payload: tenants
    }
}

export const getUserAllInvoiceTenants = (collaboratorId) => {
    return {
        type: GET_CURRENT_USER_TENANTS,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/collaborators/${collaboratorId}/companies`,
            method: "get",
        },
        meta: {
            onSuccess: onSuccess(({response, store}) => {
                store.dispatch(setCurrentUserTenants(response.data));
                return response;
            })
        }
    }
}

export const switchTenant = (collaboratorId, newOrganization) => {
    return {
        type: SWITCH_CURRENT_USER_TENANT,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/collaborators/${collaboratorId}/switch`,
            method: "put",
            data: newOrganization
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', 'delete_success');
                return response;
            }),
            onError: onError(({error}) => {
                errorNotification('', "delete_error");
                throw error;
            })
        }
    }
}