import {
    Divider,
    Grid,
    IconButton,
    TextField,
    MenuItem,
    Tooltip,
    withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from '../../../../../App/components/CustomDatePicker';
import OrderItem from '../OrderItem';
import useStyles from "./style";
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { getInvoiceStatusColor, isAVOIREE, isA_VENIR, isEMISE, isPARTIELLEMENT_REGLEE, isPREVISIONNELLE, isREGLEE } from '../../../../../utils/invoice-helper';
import { INVOICE_STATUS, MIN_INVOICING_YEAR} from '../../../../../utils/constants';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';
import moment from 'moment';
import clsx from 'clsx';
import { getLastEmittedInvoiceDate } from '../../../../redux/actions/invoicing';

var timeout = 0;
var ITEMS_COUNTER = 0;

const ReminderTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#eee",
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        boxShadow: theme.shadows[1],
        fontSize: "0.8rem"
    }
}))(Tooltip);

function InvoiceDetails({ invoice, formData, setFormData, isInputDisabled }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const invoiceStatusList = useSelector(({ rt }) => rt.invoiceStatus);
    const [issueDateDisabled, setIssueDateDisabled] = useState(false);
    const [settlementDateDisabled, setSettlementDateDisabled] = useState(false);
    const [lastEmittedInvoiceDate, setLastEmittedInvoiceDate] = useState(null);

    const delaySetFormData = (data) => {
        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(() => {
            setFormData(data);
        }, 250);
    };

    const setOrderItems = (items) => {
        const amountExclTax = Number(items.map(item => item.quantity * item.unitPrice).reduce((a, b) => a + b, 0));
        delaySetFormData({
            ...formData,
            invoiceOrder: {
                ...formData.invoiceOrder,
                orderItems: items
            },
            amountExclTax: amountExclTax,
        });
    };

    const addOrderItem = () => {
        if(!isInputDisabled("addOrderItem")){
            setOrderItems([
                ...formData.invoiceOrder.orderItems,
                {
                    displayIndex: ITEMS_COUNTER++,
                    reference: "",
                    description: "",
                    quantity: 0,
                    isPercent: true,
                    unitPrice: 0.00
                }
            ]);
        }
    };

    const updateOrderItem = (item) => {
        const items = formData.invoiceOrder.orderItems;
        const index = items.map(it => it.displayIndex).indexOf(item.displayIndex);
        items[index] = item;
        setOrderItems(items);
    };

    const deleteOrderItem = (displayIndex) => {
        const items = formData.invoiceOrder.orderItems.filter(item => item.displayIndex !== displayIndex);
        setOrderItems(items);
    };

    useEffect(() => {
        if (formData.status) {
            if (formData.status.code === INVOICE_STATUS.EMISE.code) {
                setIssueDateDisabled(false);
                setSettlementDateDisabled(true);
                setFormData({ ...formData, issueDate: moment().format("yyyy-MM-DD"), settlementDate: null });
            } else if (formData.status.code === INVOICE_STATUS.REGLEE.code || formData.status.code === INVOICE_STATUS.AVOIREE.code) {
                setSettlementDateDisabled(false);
                setIssueDateDisabled(false);
                setFormData(invoice
                    ? { ...formData, settlementDate: moment().format("yyyy-MM-DD")}
                    : { ...formData, issueDate: moment().format("yyyy-MM-DD"), settlementDate: moment().format("yyyy-MM-DD")}
                );
            } else {
                setIssueDateDisabled(true);
                setSettlementDateDisabled(true);
                setFormData({ ...formData, issueDate: null, settlementDate: null });
            }
        }
    }, [formData.status]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if((!issueDateDisabled || isAVOIREE(formData)) && (formData.issueDate || formData.avoirDate) && !lastEmittedInvoiceDate){
            dispatch(getLastEmittedInvoiceDate(+moment(formData.issueDate).year().toString())).then(res => {
                if(res.status === 200){
                    setLastEmittedInvoiceDate(res.data);
                }
            });
        }
    }, [issueDateDisabled, formData.issueDate, formData.status, formData.avoirDate]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        ITEMS_COUNTER = formData.invoiceOrder.orderItems.length;
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const isInvoiceStatusIncluded = (status) => {
        if (!invoice && (INVOICE_STATUS.PARTIELLEMENT_REGLEE.code === status.code)) return false;
        if (!invoice) return true;
        if (isPARTIELLEMENT_REGLEE(invoice)) return [INVOICE_STATUS.REGLEE.code, INVOICE_STATUS.AVOIREE.code, INVOICE_STATUS.PARTIELLEMENT_REGLEE.code].includes(status.code);
        return (isEMISE(invoice) || isREGLEE(invoice) || isAVOIREE(invoice))
            ? [INVOICE_STATUS.EMISE.code, INVOICE_STATUS.REGLEE.code, INVOICE_STATUS.AVOIREE.code].includes(status.code)
            : [INVOICE_STATUS.PREVISIONNELLE.code, INVOICE_STATUS.A_VENIR.code, INVOICE_STATUS.EMISE.code].includes(status.code);
    };

    const showIssueReminder = () => {
        return isPREVISIONNELLE(formData) || isA_VENIR(formData);
    };

    const showSettlementReminder = () => {
        return isPREVISIONNELLE(formData) || isA_VENIR(formData) || isEMISE(formData);
    };

    return (
        <Grid item container direction="column" spacing={3}>
            <Grid item container spacing={3} alignItems="center" justifyContent="center">
                <Grid item xs={4}>
                    <TextField
                        disabled={isInputDisabled("orderReference")}
                        defaultValue={formData.invoiceOrder.reference}
                        label={t("order_reference")}
                        variant="outlined"
                        onChange={(event) => delaySetFormData({ ...formData, invoiceOrder: { ...formData.invoiceOrder, reference: event.target.value.trim() || null } })}
                        fullWidth
                    />
                </Grid>
                <Grid container item xs={4} alignItems="center">
                    <Grid item xs={showIssueReminder() ? 10 : 12}>
                        <CustomDatePicker
                            label={t("expected_date")}
                            onChange={value => setFormData({ ...formData, expectedDate: value ? moment(value).format("YYYY-MM-DD") : null })}
                            emptyLabel={t('expected_date')}
                            className={clsx(classes.datePicker, showIssueReminder() ? classes.noRightBorderRadius : "")}
                            selectedDate={formData.expectedDate}
                            readOnly={isEMISE(invoice)}
                            disabled={isAVOIREE(formData) || isInputDisabled("expectedDate")}
                            required={true}
                        />
                    </Grid>
                    {
                        showIssueReminder() &&
                        <Grid container item xs={2} justifyContent="center" className={classes.reminderBox}>
                            <ReminderTooltip
                                title={formData.issueReminder ? t('disable_issue_reminders') : t('enable_issue_reminders')}
                                placement="top"
                                arrow
                                interactive
                            >
                                <IconButton
                                    disabled={isInputDisabled("issueReminders")}
                                    onClick={() => setFormData({ ...formData, issueReminder: !formData.issueReminder})}
                                    className={classes.reminderButton}
                                >
                                    {
                                        formData.issueReminder
                                            ? <NotificationsActiveOutlinedIcon color="primary" />
                                            : <NotificationsOffOutlinedIcon color="error" />
                                    }
                                </IconButton>
                            </ReminderTooltip>
                        </Grid>
                    }
                </Grid>
                <Grid container item xs={4} alignItems="center">
                    <Grid item xs={showSettlementReminder() ? 10 : 12}>
                        <CustomDatePicker
                            label={t("expected_settlement_date")}
                            onChange={value => setFormData({ ...formData, expectedSettlementDate: value ? moment(value).format("yyyy-MM-DD") : null })}
                            emptyLabel={t('expected_settlement_date')}
                            className={clsx(classes.datePicker, showSettlementReminder() ? classes.noRightBorderRadius : "")}
                            selectedDate={formData.expectedSettlementDate}
                            disabled={isAVOIREE(formData) || isInputDisabled("expectedDate")}
                            required={true}
                            readOnly={true}
                        />
                    </Grid>
                    {
                        showSettlementReminder() &&
                        <Grid container item xs={2} justifyContent="center" className={classes.reminderBox}>
                            <ReminderTooltip
                                title={formData.settlementReminder ? t('disable_settlement_reminders') : t('enable_settlement_reminders')}
                                placement="top"
                                arrow
                                interactive
                            >
                                <IconButton
                                    disabled={isInputDisabled("settlementReminders")}
                                    onClick={() => setFormData({ ...formData, settlementReminder: !formData.settlementReminder })}
                                    className={classes.reminderButton}
                                >
                                    {
                                        formData.settlementReminder
                                            ? <NotificationsActiveOutlinedIcon color="primary" />
                                            : <NotificationsOffOutlinedIcon color="error" />
                                    }
                                </IconButton>
                            </ReminderTooltip>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item container spacing={3} alignItems="center" justifyContent="center">
                <Grid item xs={4}>
                    <TextField
                        disabled={isInputDisabled("status")}
                        fullWidth
                        select
                        value={formData.status ? formData.status.code : ""}
                        label={t('status_col')}
                        variant="outlined"
                        onChange={(event) => setFormData({
                            ...formData, status: invoiceStatusList.find(s => s.code === event.target.value)
                        })}
                    >
                        {invoiceStatusList.filter(status => isInvoiceStatusIncluded(status)).map(s =>
                            <MenuItem value={s.code} key={s.id}>
                                <FiberManualRecordIcon
                                    style={{ color: getInvoiceStatusColor(s.code).color }}
                                    className={classes.statusIcon}
                                />
                                {t(s.code)}
                            </MenuItem>
                        )}
                    </TextField>
                </Grid>

                <Grid item xs={4}>
                    <CustomDatePicker
                        label={t("issue_date")}
                        onChange={value => setFormData({ ...formData, issueDate: value ? moment(value).format("yyyy-MM-DD") : null })}
                        emptyLabel={t('issue_date')}
                        className={classes.datePicker}
                        selectedDate={formData.issueDate}
                        disabled={isAVOIREE(formData) || issueDateDisabled || isInputDisabled("expectedDate")}
                        required={true}
                        minDate={lastEmittedInvoiceDate ? moment(lastEmittedInvoiceDate).format("yyyy-MM-DD") : moment().set({month: 0, year: MIN_INVOICING_YEAR}).format("yyyy-MM-DD")}
                        maxDate={moment().format("yyyy-MM-DD")}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CustomDatePicker
                        label={t("settlement_date")}
                        onChange={value => setFormData({ ...formData, settlementDate: value ? moment(value).format("yyyy-MM-DD") : null })}
                        emptyLabel={t('settlement_date')}
                        className={classes.datePicker}
                        selectedDate={formData.settlementDate}
                        disabled={isAVOIREE(formData) || settlementDateDisabled || isInputDisabled("expectedDate")}
                        required={true}
                        minDate={formData.issueDate}
                        maxDate={moment().add(7, "days").format("yyyy-MM-DD")}
                    />
                </Grid>
                {isAVOIREE(formData) && 
                    <Grid container item spacing={3} alignItems="center" justifyContent="flex-start">
                        <Grid item xs={4}>
                            <CustomDatePicker
                                label={t("avoir_date")}
                                onChange={value => setFormData({ ...formData, avoirDate: value ? moment(value).format("yyyy-MM-DD") : null })}
                                emptyLabel={t('avoir_date')}
                                className={classes.datePicker}
                                selectedDate={formData.avoirDate}
                                required={true}
                                minDate={lastEmittedInvoiceDate ? moment(lastEmittedInvoiceDate).format("yyyy-MM-DD") : moment().set({month: 0, year: MIN_INVOICING_YEAR}).format("yyyy-MM-DD")}
                                maxDate={moment().format("yyyy-MM-DD")}
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid item>
                <Divider style={{ marginBottom: "5px" }} />
            </Grid>
            <Grid item container spacing={3} alignItems="center" justifyContent="center" direction="column">
                {formData.invoiceOrder.orderItems.map(item => (
                    <Grid
                        key={item.displayIndex}
                        item container
                        spacing={3}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <OrderItem
                            isInputDisabled={isInputDisabled}
                            orderItem={item}
                            updateOrderItem={updateOrderItem}
                            deleteOrderItem={deleteOrderItem}
                            currency={formData.currency}
                        />
                    </Grid>
                ))}
                <Grid item container justifyContent="flex-end">
                    <Grid item onClick={addOrderItem}>
                        <label className={classes.addOrderItemLabel}>{t("add_order_item_label")}</label>
                        <IconButton
                            disabled={isInputDisabled("addOrderItem")}
                            color="primary"
                            className={classes.addOrderItem}
                        >
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const equalObjectId = (a, b) => ((a === b) || (!!a && !!b && (a.id === b.id)));

const areEqual = (prev, next) => {
    if (
        (prev.formData.invoiceOrder.ref === next.formData.invoiceOrder.ref)
        && (prev.formData.avoirDate === next.formData.avoirDate)
        && (prev.formData.expectedDate === next.formData.expectedDate)
        && (prev.formData.expectedSettlementDate === next.formData.expectedSettlementDate)
        && (prev.formData.issueReminder === next.formData.issueReminder)
        && (prev.formData.settlementReminder === next.formData.settlementReminder)
        && equalObjectId(prev.formData.status, next.formData.status)
        && (prev.formData.issueDate === next.formData.issueDate)
        && (prev.formData.settlementDate === next.formData.settlementDate)
        && (prev.formData.invoiceOrder === next.formData.invoiceOrder)
        && (prev.formData.amountExclTax === next.formData.amountExclTax)
        && (prev.formData.amountInclTax === next.formData.amountInclTax)
        && (prev.formData.currency?.code === next.formData.currency?.code)
    ) {
        return true;
    }

    return false;
};

export default React.memo(InvoiceDetails, areEqual);