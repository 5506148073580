import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fr from "./fr";
import us from "./en";
import de from "./de";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
    fr: {
        translation: fr,
    },
    us: {
        translation: us,
    },
    de: {
        translation: de
    } 
};

i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem("language") ?? "fr",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});

export const t = (key, options) => {
    return options ? i18n.t(key, options) : i18n.t(key);
};

export default i18n;
