import { ReactENV } from "../../../utils/io";

/************* ACCOUNT **************/
export const ACCOUNT_ENDPOINT = "api/account";

/************* RT **************/
export const FINANCIAL_RT_ENDPOINT = `/api/financial/rt`;

/************* INDICATORS **************/
export const GLOBAL_FINANCIAL_INDICATORS_ENDPOINT = `/api/financial/indicators/global`;

/************* INVOICES *************/
export const FINANCIAL_INVOICING_ENDPOINT = `/api/financial/invoicing`;