export const sameObjectId = (a, b) => {
    return (a === b) || (a && b && a.id === b.id);
};

export const validateEmail = (val) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;  
    return emailRegex.test(val);
};

export * from './invoicing';
