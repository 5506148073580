import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    dialogPaper: {
        width: "700px",
        minHeight: "600px",
    },
    attachmentViewerDialogPaper: {
        width: "75%",
        minHeight: "90%",
    },
    attachmentViewerDialog: {
        "& .MuiDialogContent-root": {
            overflow: "hidden",
            padding: 0,
            height: "600px"
        },
    },
    mailFieldContainer:{
        marginBottom: "1rem"
    },
    mailTextFieldLabel:{
        width: "15%"
    },
    mailTextField: {
        marginLeft: "1rem",
        width: "80%"
    },
    editorWrapper: {
        border: "1px solid #e5e5e5",
        borderRadius: "5px"
    },
    editorTextArea: {
        padding: theme.spacing(0, 1)
    },
    toolbar: {
        "& .rdw-dropdown-optionwrapper": {
            maxHeight: "135px"
        }
    },
    addAttachmentButton: {
        marginLeft: theme.spacing(1),
        border: "2px solid",
        "&:hover": {
            border: "2px solid",
        },
        textTransform: "none"
    },
    uploadContainer: {
        marginBottom: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        border: `1px solid ${theme.palette.grey[300]}`,
        boxShadow: "none"
    },
    uploadForm: {
        margin: theme.spacing(2, 3, 2, 0),
        padding: theme.spacing(2),
    },
    uploadZone: {
        padding: theme.spacing(0, 2)
    },
    messageBody: {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: "4px",
        padding: "1rem"
    },
    textField: {
        "& .MuiInputBase-input":{
            color: "black"
        }
    }
}));