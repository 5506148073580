import { GET_USER, LOGOUT_USER, SET_CURRENT_USER } from "../../constants";
import { ACCOUNT_ENDPOINT } from "../../constants/endpoint";

export const genericAction = (type, url, body = {}, params = {}) => {
    return {
        type: type,
        request: {
            url: url,
            method: "get",
            params: params,
            data: body,
        },
    };
};

export const getCurrentUser = () => {
    return {
        type: GET_USER,
        request: {
            url: ACCOUNT_ENDPOINT,
            method: "get",
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                store.dispatch(
                    setCurrentUser({
                        user: {},
                        loading: true,
                        isAuthenticated: false,
                        errorMessage: null,
                    })
                );
                return request;
            },
            onSuccess: (response, requestAction, store) => {
                store.dispatch(
                    setCurrentUser({
                        user: response.data,
                        loading: false,
                        isAuthenticated: true,
                        errorMessage: null,
                    })
                );
                return response;
            },
            onError: (error, requestAction, store) => {
                store.dispatch(
                    setCurrentUser({
                        user: {},
                        loading: false,
                        isAuthenticated: true,
                        errorMessage: error.message,
                    })
                );
                throw error;
            },
        },
    };
};

export const setCurrentUser = (response) => {
    return {
        type: SET_CURRENT_USER,
        response: response,
    };
};

export const logout = (keycloak) => {
    return {
        type: LOGOUT_USER,
        keycloak: keycloak,
    };
};
