import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import NotifModal from '../../../App/components/NotifModal';
import RtGroup from './RtGroup';
import { RtablesDef } from './rtablesDef';
import { uniq } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

const modal = {
    type: "primary",
    open: false,
    title: null,
    body: null,
    showCloseButton: true,
    onChange: null,
    ok: {
        title: null,
        onClick: null
    },
    confirm: {
        title: null,
        onClick: null
    },
    cancel: {
        title: null,
        onClick: null
    }
};

const groups = uniq(RtablesDef.map(rt => rt.group));

function Rtables() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [actionModal, setActionModal] = useState(modal);
    const [group, setGroup] = useState(null);
    
    const displayModal = (open) => {
        setActionModal({
            ...actionModal,
            open: open
        });
    };

    const onGroupSelectChange = (event) => {
        if(event.target.value === "all") setGroup(null);
        else setGroup(event.target.value);
    };

    return (

        <>
            <Grid container>
                <FormControl
                    variant="outlined"
                    size="small"
                    className={classes.groupsFormControl}
                >
                    <InputLabel>{t("groups")}</InputLabel>
                    <Select
                        value={group || "all"}
                        onChange={onGroupSelectChange}
                        label={t("groups")}
                    >
                        <MenuItem value="all">{t("all")}</MenuItem>
                        {groups.map((item, idx) => <MenuItem key={idx} value={item}>{t(item)}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <RtGroup 
                group={group}
                setGroup={setGroup}
                displayModal={displayModal}
                actionModal={actionModal}
                setActionModal={setActionModal}
            />
            <NotifModal {...actionModal} />
        </>
    );
}

export default Rtables;