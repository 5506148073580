import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import {
    TextField,
    Paper,
    Button,
    Typography,
    Box,
    Checkbox
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';


function InvoicingEntitySelect({
    value,
    handleValueChange,
    variant = "standard",
    disabled = false,
    size = "medium",
    inputStyle,
    inputLabel,
    showAddButton = false,
    multiselect = false,
    checkmark = false
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const entities = useSelector(({ rt }) => rt.invoicingEntities);

    const PaperComponent = (props) => {
        return <Paper {...props} className={classes.paper}></Paper>;
    };

    const PaperComponentWithAddButton = (props) => {
        return (
            <Paper className={classes.paper}>
                <div {...props} style={{ marginBottom: 0 }}>
                </div>
                <Button
                    fullWidth
                    size="small"
                    color="primary"
                    className={classes.addButton}
                    onMouseDown={() => history.push("/administration/reference-tables/entities")}
                >
                    <Typography variant="caption"><AddIcon fontSize="small" /> {t('add')} </Typography>
                </Button>
            </Paper>
        );
    };

    const uniSelect = () => (
        <Autocomplete
            disabled={disabled}
            value={value}
            options={entities && entities.sort((a, b) => a.name.localeCompare(b.name))}
            onChange={(e, val) => handleValueChange(val)}
            autoHighlight
            getOptionLabel={(entity) => `${entity.name}`}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(entity) => `${entity.name}`}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    variant={variant}
                    label={inputLabel || t('invoicing_entity')}
                    size={size}
                    style={inputStyle}
                />
            )}
            noOptionsText={t('no_entity_found')}
            clearText={t('clear')}
            openText={t('open')}
            closeText={t('close')}
            PaperComponent={showAddButton ? PaperComponentWithAddButton : PaperComponent}
        />
    );

    const multiSelect = () => (
        <Autocomplete
            multiple={true}
            disabled={disabled}
            value={value}
            disableCloseOnSelect
            options={entities && entities.sort((a, b) => a.name.localeCompare(b.name))}
            onChange={(e, val) => handleValueChange(val)}
            getOptionLabel={(entity) => `${entity.name}`}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(entity) => (
                <Box style={{flexShrink: 0}} >
                    {
                        checkmark &&
                        <Checkbox 
                            size="small" 
                            checked={value.map(v => v.id).includes(entity.id)} 
                        />
                    }
                    {entity.name}
                </Box>
            )}
            renderTags={(value) => (
                <Typography 
                    noWrap={true}
                >
                    {value.map(o => o.name).join(' | ')}
                </Typography>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    variant={variant}
                    label={inputLabel || t('entity_col')}
                    size={size}
                    style={inputStyle}
                />
            )}
            noOptionsText={t('no_invoicing_entity_found')}
            clearText={t('clear')}
            openText={t('open')}
            closeText={t('close')}
            classes={{ paper: classes.paper, option: classes.option }}
            className={classes.root}
        />
    )

    return multiselect ? multiSelect() : uniSelect();

}

export default InvoicingEntitySelect;