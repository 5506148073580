import { alpha, makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    dropzone: {
        textAlign: "center",
        padding: theme.spacing(4, 2),
        margin: theme.spacing(2, 0),
        border: `2px dashed ${theme.palette.primary.main}`,
        backgroundColor: alpha(theme.palette.primary.main, 0.06),
        borderRadius: "4px",
        boxShadow: theme.shadows[25],
        cursor: "pointer"
    },
    uploadContainer: {
        padding: theme.spacing(2, 0),
        margin: theme.spacing(2, 0)
    },
    tableContainer: {
        boxShadow: theme.shadows[25],
        borderRadius: "4px",
        margin: theme.spacing(2, 0)
    },
    configContainer: {
        padding: theme.spacing(1, 2),
        boxShadow: theme.shadows[25]
    },
    downloadLink: {
        color: "rgba(0, 0, 0, 0.75)",
        fontSize: "13px",
        textDecoration: "underline", 
        border: "none"
    },
    alert: {
        "& .MuiAlert-action": {
            alignItems: "flex-start"
        }
    },
    paper: {
        boxShadow: theme.shadows[8]
    },
    option: {
        padding: theme.spacing(0, 1)
    },
    root: {
        "& .MuiInputBase-root": {
            flexWrap: "nowrap",
        },
        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
            minWidth: "10px"
        }
    },
}));