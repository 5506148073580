import { Card, CardActions, CardHeader, CircularProgress, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getFileExtensionFromFileName, getFileIconFromExtention, getFileTypeNameFromExtenstion } from '../../../../utils/files';

import { truncateText } from '../../../../utils/converters';
import useStyles from './style';
import { useDispatch } from 'react-redux';
import { downloadMailAttachment } from '../../../redux/actions/invoicing';
import { downloadResponseGeneric } from '../../../../utils/io';
import CustomDialog from '../../../../App/components/CustomDialog';
import InvoiceAttachmentViewer from '../../InvoiceAttachments/InvoiceAttachmentViewer';


const MailAttachmentCard = ({attachment, extension, handleDeleteAttachment, isIssueMailSent, downLoadAttachment}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [downloading, setDownloading] = useState(false);
    const [openAttachmentViewer, setOpenAttachmentViewer] = useState(false);

    const onDownloadClick = (attachment) => {
        setDownloading(true);
        dispatch(downLoadAttachment()).then(data => {
            if (!data.error) {
                downloadResponseGeneric(data.action.response, attachment.fileName, attachment.fileType);
            }
            setDownloading(false);
        });
    };

    return (
        <>
            <Grid item className={classes.mailAttachmentCard}>
                <Card key={attachment.fileName}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={8}>
                            <CardHeader
                                avatar={
                                    <img src={getFileIconFromExtention(extension)} alt="File icon" width={30} />
                                }
                                title={<Typography>
                                    {truncateText(attachment.fileName, 15)}
                                </Typography>}
                                subheader={getFileTypeNameFromExtenstion(extension).toLowerCase()}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CardActions>
                                {["pdf", "png", "jpg", "jpeg"].includes(getFileExtensionFromFileName(attachment.fileName).toLowerCase()) &&
                                    <Tooltip title={t('view')} placement="top">
                                        <IconButton
                                            size="small"
                                            onClick={() => setOpenAttachmentViewer(true)}
                                        >
                                            <VisibilityIcon className={classes.controlsButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {isIssueMailSent &&
                                    (downloading ?
                                        <IconButton disabled size="small">
                                            <CircularProgress disableShrink size="1rem" />
                                        </IconButton>
                                        :
                                        <Tooltip title={t("download")} placement="top">
                                            <IconButton
                                                size="small"
                                                onClick={() => onDownloadClick(attachment)}
                                            >
                                                <CloudDownloadIcon color="primary" />
                                            </IconButton>
                                        </Tooltip>)
                                }
                                {!isIssueMailSent &&
                                    <Tooltip title={t("delete")} placement="top">
                                        <IconButton size="small" onClick={() => handleDeleteAttachment(attachment)}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </CardActions>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

            {openAttachmentViewer &&
                <CustomDialog
                    open={openAttachmentViewer}
                    onClose={() => setOpenAttachmentViewer(false)}
                    title={<Typography variant="h6">{t("attachment") + ": " + attachment.fileName}</Typography>}
                    renderContent={() => <InvoiceAttachmentViewer downloadAttachment={downloadMailAttachment} attachment={attachment} base64File={attachment.file} />}
                    renderActions={() => <></>}
                    className={classes.attachmentViewerDialog}
                    classes={{
                        paper: classes.attachmentViewerDialogPaper,
                    }}
                    saving={false}
                    disableBackdropClick={true}
                />

            }
        </>
    );
};

export default MailAttachmentCard;