import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import DashboardCard from '../../../App/components/DashboardCard';
import useStyles from './style';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    InvoiceDetails,
    InvoiceHeader,
    InvoiceFooter
} from './components';

function InvoiceForm({ 
    type,
    invoice,
    formData,
    setFormData,
    setPartFormData,
    isInputDisabled = () => false 
}) {
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {

        return () => {
            setFormData(null);
            setPartFormData(null);
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        formData &&  
        <Grid container className={classes.container} direction="column" spacing={2}>
            <Grid item container xs={12}>
                <DashboardCard
                    title={t("invoice_general_info")}
                    className={classes.card}
                    children={
                        <InvoiceHeader
                            type={type}
                            isInputDisabled={isInputDisabled}
                            formData={{
                                client: formData.client,
                                project: formData.project,
                                responsable: formData.responsable,
                                label: formData.label,
                                triggerAction: formData.triggerAction,
                                invoicingEntity: formData.invoicingEntity,
                                currency: formData.currency,
                                recurrentCreationType: formData.recurrentCreationType,
                                recurrentCreationLimit: formData.recurrentCreationLimit,
                                invoiceType: formData.invoiceType,
                                bankAccount: formData.bankAccount,
                            }}
                            setFormData={setPartFormData}
                        />
                    }
                />
            </Grid>
            <Grid item container xs={12}>
                <DashboardCard
                    title={t("invoice_details")}
                    className={classes.card}
                    children={
                        <InvoiceDetails
                            invoice={invoice}
                            isInputDisabled={isInputDisabled}
                            formData={{
                                expectedDate: formData.expectedDate,
                                expectedSettlementDate: formData.expectedSettlementDate,
                                issueReminder: formData.issueReminder,
                                settlementReminder: formData.settlementReminder,
                                status: formData.status,
                                issueDate: formData.issueDate,
                                settlementDate: formData.settlementDate,
                                invoiceOrder: formData.invoiceOrder,
                                amountExclTax: formData.amountExclTax,
                                amountInclTax: formData.amountInclTax,
                                currency: formData.currency,
                                avoirDate: formData.avoirDate
                            }}
                            setFormData={setPartFormData}
                        />
                    }
                />
            </Grid>
            <Grid item container xs={12}>
                <DashboardCard
                    className={clsx(classes.card, classes.noHeaderCard)}
                    children={
                        <InvoiceFooter
                            isInputDisabled={isInputDisabled}
                            formData={{
                                taxRates: formData.taxRates,
                                amountExclTax: formData.amountExclTax,
                                amountInclTax: formData.amountInclTax,
                                currency: formData.currency
                            }}
                            setFormData={setPartFormData}
                        />
                    }
                />
            </Grid>
        </Grid>
    );
}

export default InvoiceForm;