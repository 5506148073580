import {
    Grid,
    MenuItem,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from "./style";
import { useSelector } from 'react-redux';
import ProjectSelect from '../../../../../App/components/ProjectSelect';
import ResponsableSelect from '../../../../../App/components/ResponsableSelect';
import InvoicingClientSelect from '../../../InvoicingClientSelect';
import InvoicingEntitySelect from '../../../InvoicingEntitySelect';
import { Alert, Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import CustomDatePicker from '../../../../../App/components/CustomDatePicker';
import { RECURRENT_CREATION_TYPE } from '../../../../../utils/constants';

var timeout = 0;

function InvoiceHeader({ type, formData, setFormData, isInputDisabled }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const projectsList = useSelector(({ rt }) => rt.projects);
    const invoiceTypeList = useSelector(({ rt }) => rt.invoiceTypes);
    const [invoicingEntity, setInvoicingEntity] = useState(!!formData.invoicingEntity);
    const currentCollaborator = useSelector(({ collaborators }) => collaborators.currentCollaborator);
    const currencies = useSelector(({ rt }) => rt.currencies);
    const bankAccounts = useSelector(({ rt }) => rt.bankAccounts);
    const [createRecurrentInvoices, setCreateRecurrentInvoices] = useState(false);
    const delaySetFormData = (data) => {
        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(() => {
            setFormData(data);
        }, 250);
    };

    const handleInvoicingEntitySwitch = () => {
        setFormData({ ...formData, invoicingEntity: null });
        setInvoicingEntity(!invoicingEntity);
    };

    const handleRecurrentSwitch = () => {
        if(!createRecurrentInvoices){
            setFormData({...formData, recurrentCreationType: RECURRENT_CREATION_TYPE.NONE.code});
        }
        setCreateRecurrentInvoices(!createRecurrentInvoices);
        setFormData({...formData, recurrentCreationType: RECURRENT_CREATION_TYPE.ANNUAL.code, recurrentCreationLimit: moment().format("yyyy-MM-DD")});
    };

    const isCollabAllowed = (collab) => {
        if (collab.id === currentCollaborator.id && currentCollaborator.isAdmin) return 1;
        if ((formData.client && collab.clients.includes(formData.client.id))
            || (formData.project && (collab.projects.includes(formData.project.id)
                || collab.clients.includes(formData.project.client?.id)))) {
            return 1;
        } else return 0;
    };

    const handleCurrencyChange = (e)=>{
        const currency = currencies.find(c=>c.id === e.target.value);
        setFormData({
            ...formData,
            currency,
            bankAccount: bankAccounts.find(b => b.currency.id === currency.id) || bankAccounts[0], });
    };

    const handleBankAccountChange = (e) => {
        const bankAccount = bankAccounts.find(account => account.id === e.target.value);
        setFormData({
            ...formData,
            bankAccount});

    };

    useEffect(() => {
        if(formData.invoicingEntity?.currency){
            const currency = formData.invoicingEntity?.currency;
            setFormData({
                ...formData,
                currency,
                bankAccount: bankAccounts.find(b => b.currency.id === currency.id) || bankAccounts[0], 
            });
        }else if(formData?.client?.currency){
            const currency = formData.client?.currency;
            setFormData({
                ...formData,
                currency,
                bankAccount: bankAccounts.find(b => b.currency.id === currency.id) || bankAccounts[0], 
            });
        }else if(currencies.length > 0 && bankAccounts.length > 0){
            const currency = currencies.find(c => c.isDefault);
            setFormData({
                ...formData,
                currency,
                bankAccount: bankAccounts.find(b => b.currency.id === currency.id) || bankAccounts[0], 
            });
        }
    }, [formData?.client, formData?.invoicingEntity, currencies, bankAccounts]); //eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <Grid item container direction="column" spacing={3}>
            <Grid item container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={4}>
                    <InvoicingClientSelect
                        disabled={isInputDisabled("client")}
                        value={formData.client}
                        variant="outlined"
                        handleValueChange={(val) => setFormData({
                            ...formData,
                            client: val,
                            project: formData.project?.client?.id === val?.id ? formData.project : null,
                        })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ProjectSelect
                        disabled={isInputDisabled("project")}
                        projects={
                            formData.client
                                ? projectsList.filter(p =>
                                    (!!p.client
                                    && ((!!p.client.code && !!formData.client.code && p.client.code === formData.client.code)
                                        || (!!p.client.name && !!formData.client.name && p.client.name === formData.client.name))
                                    ))
                                : projectsList
                        }
                        value={formData.project ? formData.project : null}
                        variant="outlined"
                        handleValueChange={(val) => setFormData({ ...formData, project: val })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ResponsableSelect
                        disabled={isInputDisabled("responsable")}
                        type="edit"
                        value={formData.responsable ? formData.responsable : null}
                        handleValueChange={(val) => setFormData({ ...formData, responsable: val })}
                        variant="outlined"
                        selectedClient={formData.client}
                        selectedProject={formData.project}
                    />
                </Grid>
            </Grid>
            {
                (formData.client || formData.project) && formData.responsable && !isCollabAllowed(formData.responsable) &&
                <Grid item xs={12}>
                    <Alert severity="error">
                        {t('collaborator_not_allowed_to_manage_invoice', {
                            collaboratorName: `${formData.responsable.firstName} ${formData.responsable.lastName}`
                        })}.
                    </Alert>
                </Grid>
            }
            <Grid item container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={4}>
                    <TextField
                        disabled={isInputDisabled("trigger")}
                        defaultValue={formData.triggerAction}
                        label={t("invoice_trigger_action")}
                        variant="outlined"
                        onChange={(event) => delaySetFormData({ ...formData, triggerAction: event.target.value || null })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled={isInputDisabled("label")}
                        defaultValue={formData.label}
                        label={t("invoice_label")}
                        variant="outlined"
                        onChange={(event) => delaySetFormData({ ...formData, label: event.target.value || null })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disabled={isInputDisabled("invoiceType")}
                        value={formData.invoiceType}
                        options={invoiceTypeList}
                        onChange={(e, val) => setFormData({ ...formData, invoiceType: val})}
                        autoHighlight
                        getOptionLabel={(type) => type.name}
                        getOptionSelected={(option, value) => option.id === value?.id}
                        renderOption={(type) => type.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                label={t('invoice_type')}
                                size="medium"
                            />
                        )}
                        noOptionsText={t('no_type_found')}
                        clearText={t('clear')}
                        openText={t('open')}
                        closeText={t('close')}
                        classes={{ paper: classes.paper }}
                    />
                </Grid>
            </Grid>
            <Grid item container alignItems="center" justifyContent="space-between" spacing={3}>
                <Grid item xs={4}>
                    <Switch
                        disabled={isInputDisabled("invoicingEntity")}
                        checked={invoicingEntity}
                        onChange={handleInvoicingEntitySwitch}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        size="small"
                    /> {t("invoice_different_entity")}
                </Grid>
                <Grid container alignItems="center" justifyContent={((type === "create") || (type === "duplicate")) ? "space-between" : "flex-end"} item xs={8}>
                    <Grid item xs={4} className={classes.currencyContainer}>
                        <Typography style={{ marginRight: "1rem" }}>
                            {t("linked_account")}
                        </Typography>
                        <TextField
                            disabled={isInputDisabled("bankAccount")}
                            variant="outlined"
                            size="small"
                            select
                            label={t("account")}
                            value={formData?.bankAccount?.id || ""}
                            onChange={handleBankAccountChange}
                            classes={{ paper: classes.paper }}
                            className={classes.bankAccountSelect}
                        >
                            {bankAccounts.map((account) => (
                                <MenuItem selected={formData?.bankAccount?.id === account.id} value={account.id} key={account.id}>
                                    {account?.currency?.code} - {account.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={4} className={classes.currencyContainer}>
                        <Typography style={{ marginRight: "1rem" }}>
                            {t("invoice_currency")}
                        </Typography>
                        <TextField
                            disabled={isInputDisabled("currency")}
                            variant="outlined"
                            size="small"
                            select
                            label={t("currency")}
                            value={formData.currency?.id || ""}
                            onChange={handleCurrencyChange}
                            classes={{ paper: classes.paper }}
                        >
                            {currencies.map((currency) => (
                                <MenuItem selected={formData?.currency?.id === currency.id} value={currency.id} key={currency.id}>
                                    {currency.code}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {((type === "create") || (type === "duplicate")) &&
                    <>
                        <Grid style={{display: "flex", justifyContent: "flex-end"}} item xs={4}>
                            <Switch
                                checked={createRecurrentInvoices}
                                onChange={()=> handleRecurrentSwitch()}
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                size="small"
                            /> {t("create_recurrent_invoices")}
                        </Grid>
                    </>
                    }
                </Grid>
            </Grid>
            {
                formData.currency && formData.bankAccount && (formData.currency?.code !== formData.bankAccount?.currency?.code) &&
                <Grid item xs={12}>
                    <Alert severity="warning">
                        {t('chosen_currecy_is_different_than_the_bank_accounts_currency', {
                            currency: formData.currency?.code,
                            bankAccountCurrency: formData.bankAccount?.currency?.code,
                        })}.
                    </Alert>
                </Grid>
            }
            {
                (formData.invoicingEntity?.digital || formData?.client?.digital || invoicingEntity || formData.invoicingEntity || (createRecurrentInvoices && ((type === "create") || (type === "duplicate")))) &&
                <Grid item container alignItems="center" justifyContent="space-between" spacing={3}>
                    <Grid container item xs={4} spacing={1}>
                        {(invoicingEntity || formData.invoicingEntity) &&
                        <Grid item style={{ width: "100%" }}>
                            <InvoicingEntitySelect
                                value={formData.invoicingEntity}
                                variant="outlined"
                                handleValueChange={(val) => setFormData({ ...formData, invoicingEntity: val })}
                            />
                        </Grid>
                        }
                    </Grid>
                    
                    <Grid item container xs={4} justifyContent="space-between">
                        {createRecurrentInvoices && ((type === "create") || (type === "duplicate")) && 
                    <>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                size="medium"
                                select
                                label={t("reccurence_type")}
                                defaultValue={RECURRENT_CREATION_TYPE.ANNUAL.code}
                                onChange={(e) => setFormData({...formData, recurrentCreationType: e.target.value})}
                                style={{width: "100%"}}
                                classes={{ paper: classes.paper }}
                            >
                                <MenuItem value={RECURRENT_CREATION_TYPE.ANNUAL.code} key={RECURRENT_CREATION_TYPE.ANNUAL.code}>
                                    {t(RECURRENT_CREATION_TYPE.ANNUAL.name)}
                                </MenuItem>
                                <MenuItem value={RECURRENT_CREATION_TYPE.MONTHLY.code} key={RECURRENT_CREATION_TYPE.MONTHLY.code}>
                                    {t(RECURRENT_CREATION_TYPE.MONTHLY.name)}
                                </MenuItem>
                            </TextField>
                        </Grid>  
                        <Grid item>
                            <CustomDatePicker
                                className={classes.datePicker}
                                selectedDate={formData.recurrentCreationLimit}
                                onChange={(val)=> setFormData({...formData, recurrentCreationLimit: moment(val).format("YYYY-MM-DD")})}
                                label={t('recurrence_limit_date')}
                                required={true}
                                size="medium"
                                InputClassName={classes.dateInput}
                                yearMonthOnly={formData.recurrentCreationType === RECURRENT_CREATION_TYPE.MONTHLY.code ? true : false}
                                yearOnly={formData.recurrentCreationType === RECURRENT_CREATION_TYPE.ANNUAL.code ? true : false}
                            />
                        </Grid>
                    </>
                        }
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}

const equalObjectId = (a, b) => ((a === b) || (!!a && !!b && (a.id === b.id)));

const areEqual = (prev, next) => {
    if (
        equalObjectId(prev.formData.client, next.formData.client)
        && equalObjectId(prev.formData.project, next.formData.project)
        && equalObjectId(prev.formData.responsable, next.formData.responsable)
        && equalObjectId(prev.formData.invoicingEntity, next.formData.invoicingEntity)
        && prev.formData.label === next.formData.label
        && prev.formData.triggerAction === next.formData.triggerAction
        && equalObjectId(prev.formData.currency, next.formData.currency)
        && prev.formData.recurrentCreationType === next.formData.recurrentCreationType
        && prev.formData.recurrentCreationLimit === next.formData.recurrentCreationLimit
        && equalObjectId(prev.formData.invoiceType, next.formData.invoiceType)
        && equalObjectId(prev.formData.bankAccount, next.formData.bankAccount)
    ) {
        return true;
    }

    return false;
};

export default React.memo(InvoiceHeader, areEqual);