import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    mainDateLabel: {
        marginRight: "1rem"
    },
    listHeader: {
        padding: theme.spacing(0, 2, 2, 2)
    },
    cardAction: {
        minWidth: '125px',
        height: '45px',
        borderRadius: '2px',
        margin: theme.spacing(1, 2)
    },
    colName: {
        fontWeight: "bold",
        color: theme.palette.primary.dark
    },
    datePicker: {
        "& input": {
            height: "80%"
        }
    },
    countChip: {
        color: "white",
        backgroundColor: theme.palette.primary.dark
    }
}));