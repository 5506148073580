import Swal from "sweetalert2";
import { t } from "../i18n";

const infoOptions = {
    icon: "info",
    position: "top-right",
    toast: true,
    showConfirmButton: false,
    timer: 5000,
};

const successOptions = {
    icon: "success",
    position: "top-right",
    toast: true,
    showConfirmButton: false,
    timer: 5000,
};

const errorOptions = {
    icon: "error",
    position: "top-right",
    toast: true,
    showConfirmButton: false,
    timer: 5000,
};

export const infoNotification = (title, text, options = {}) => {
    Swal.fire({
        title: title,
        html: `<span style="padding-left: 20px">${t(text)}</span>`,
        ...infoOptions,
        ...options,
    });
};

export const successNotification = (title, text, options = {}) => {
    Swal.fire({
        title: title,
        html: `<span style="padding-left: 20px">${t(text)}</span>`,
        ...successOptions,
        ...options,
    });
};

export const errorNotification = (title, text, options = {}) => {
    Swal.fire({
        title: title,
        html: `<span style="padding-left: 20px">${t(text)}</span>`,
        ...errorOptions,
        ...options,
    });
};
