import React from "react";
import useStyles from "./style"
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { useTranslation } from "react-i18next";


function UserCard({ user, openEdit, handleDelete }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Card
                className={classes.root}
                elevation={1}
            >
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={10}>
                        <CardContent className={classes.content} style={{ paddingBottom: "16px" }}>
                            <Grid container>
                                <Grid container item xs={3} alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Typography className={classes.collabName}> {user.firstName}  </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={3} alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Typography className={classes.collabName}> {user.lastName}  </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={3} alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Typography className={classes.collabName}> {user.email}  </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={3} alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Typography className={user.isAdmin
                                            ? classes.adminRole
                                            : user.isReadOnly
                                            ? classes.readOnly
                                            : user.isManager
                                            ? classes.managerRole
                                            : ""
                                        }
                                        >
                                            {
                                                user.isAdmin
                                                ? "Admin"
                                                : user.isManager
                                                ? "Manager"
                                                : user.isReadOnly
                                                ? "Read Only"
                                                : "N/A"
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Grid>
                    <Grid item xs={2}>
                    <CardActions>
                        <Grid container alignItems="center" justifyContent="center">
                            <Grid item>
                                <Tooltip title={t('edit')} placement="top">
                                    <IconButton color="primary" onClick={() => openEdit(user)} >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={t('delete')} placement="top">
                                    <IconButton color="primary" onClick={() => {
                                            handleDelete(user)
                                        }} 
                                    >
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </CardActions>
                    </Grid>
                </Grid>
        </Card>
    )
}

export default UserCard