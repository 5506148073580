import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    addOrderItemLabel: {
        fontSize: "12px",
        padding: "0px 8px",
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    addOrderItem: {
        width: "15px",
        height: "15px",
        backgroundColor: theme.palette.primary.main,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.background.dark
        },
        marginRight: theme.spacing(1)
    },
    datePicker: {
        "& input": {
            height: "32px"
        }
    },
    statusIcon: {
        fontSize: "1rem",
        marginRight: theme.spacing(1)
    },
    reminderButton: {
        '&:hover': {
            backgroundColor: "#fff"
        }
    },
    reminderBox: {
        position: "relative",
        height: "53px",
        border: `1px solid ${theme.palette.grey[400]}`,
        borderLeft: "none",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px"
    },
    noRightBorderRadius: {
        '& .MuiOutlinedInput-root': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        }
    }
}));