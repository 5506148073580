import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    organizationItem: {
        fontSize: "1.3em",
        textAlign: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
        "&:hover": {
            cursor: "pointer",
        },
    },
    organizationName: {
        "&:hover": {
            backgroundColor: "#eee"
        },
        padding: "13px",
        transition: theme.transitions.create("background-color", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    }
}));