import Auth from "./auth";
import appSlice from "../../../App/appSlice";
import rtReducer from "./rt";
import indicatorsReducer from "./indicators";
import invoicingReducer from "./invoicing";
import dashboardReducer from "./dashboard";
import collaboratorsReducer from './collaborators';
import parametersReducer from './parameters';
import paymentsReducer from './payments';
import usersReducer from './users'
import agedTrialBalance from './agedTrialBalance'

export default {
    Auth: Auth,
    appState: appSlice,
    keycloak: (keycloak = {}) => keycloak,
    rt: rtReducer,
    indicators: indicatorsReducer,
    invoicing: invoicingReducer,
    dashboard: dashboardReducer,
    collaborators: collaboratorsReducer,
    parameters: parametersReducer,
    payments: paymentsReducer,
    users: usersReducer,
    agedTrialBalance: agedTrialBalance
};
