import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import InfoTooltip from '../../../../App/components/InfoTooltip';

function TextEditorAction({actionModal, setActionModal, displayModal, editorState, setEditorState, instance, action}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [confirmDisabled, setConfirmDisabled] = useState(true);

    const onEditorStateChange = (state) => {
        setEditorState(state);
    };

    const getInitialState = () => {
        const formatted = get(instance, action.apiName);
        let editorContent = formatted && JSON.parse(formatted).json;
        if(!editorContent && typeof action.defaultValue === 'function') {
            editorContent = action.defaultValue(instance);
            setConfirmDisabled(false);
        }

        if(!editorContent) return EditorState.createEmpty();
        return EditorState.createWithContent(convertFromRaw(editorContent));
    };

    const toJSON = (state) => {
        return convertToRaw(state.getCurrentContent());
    };

    const toHTML = (state) => {
        return draftToHtml(convertToRaw(state.getCurrentContent()))
            .replace(new RegExp("<br>", 'g'), "<br/>")
            .replace(new RegExp("\\n", 'g'), "");
    };

    useEffect(() => {
        setEditorState(getInitialState());
    }, [instance, action]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(editorState != null) {
            setActionModal({
                ...actionModal,
                open: true,
                type: "primary",
                title: (
                    <>
                        {t(action.name)}
                        <InfoTooltip text={<>{t('format_address_tooltip_1')}<br/>{t('format_address_tooltip_2')}</>} />
                    </>
                ),
                body: (
                    <Box>
                        <Editor
                            defaultEditorState={editorState}
                            wrapperClassName={classes.editorWrapper}
                            editorClassName={classes.editorTextArea}
                            toolbarClassName={classes.toolbar}
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                                options: ['inline', 'history'],
                                inline: { isDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'] }
                            }}
                        />
                    </Box>
                ),
                showCloseButton: true,
                onChange: displayModal,
                cancel: {
                    title: t('cancel'),
                    onClick: () => {
                        displayModal(false);
                    }
                },
                confirm: {
                    title: t('confirm'),
                    disabled: toHTML(editorState) === toHTML(getInitialState()) && confirmDisabled,
                    onClick: () => {
                        let formattedAddress = {
                            json: toJSON(editorState),
                            html: toHTML(editorState)
                        };

                        if(formattedAddress.html.trim().length === 0 || formattedAddress.html.match(/<p>(&nbsp;|<br\/>)*<\/p>/)) {
                            formattedAddress = null;
                        } else {
                            formattedAddress = JSON.stringify(formattedAddress);
                        }
                        
                        dispatch(action.confirm(instance.id, {...instance, formattedAddress: formattedAddress}));
                        displayModal(false);
                    }
                }
            });
        }
    }, [instance, editorState, action, confirmDisabled]); //eslint-disable-line react-hooks/exhaustive-deps

    return <></>;
}

export default TextEditorAction;