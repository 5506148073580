import { onError, onRequest, onSuccess } from '../../../../utils/http';
import { errorNotification, successNotification } from '../../../../utils/notification';
import {
    GET_ALL_PROJECTS,
    SET_ALL_PROJECTS,
    POST_PROJECT,
    PUT_PROJECT,
    DELETE_PROJECT
} from '../../constants';
import { FINANCIAL_RT_ENDPOINT } from '../../constants/endpoint';

export const getAllProjects = (filtered) => {
    return {
        type: GET_ALL_PROJECTS,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/project`
        },
        meta: {
            onRequest: onRequest(({ request, store }) => {
                if (filtered) {
                    const filter = store.getState().invoicing.filter;
                    if (filter.month) {
                        request.params = {
                            year: filter.year,
                            month: filter.month
                        };
                    } else {
                        request.params = {
                            year: filter.year
                        };
                    }
                }
                return request;
            }),
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllProjects(response.data));
                return response;
            }
        }
    };
};

export const setAllProjects = (data) => {
    return {
        type: SET_ALL_PROJECTS,
        payload: data
    };
};

export const postProject = (project) => {
    return {
        type: POST_PROJECT,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/project`,
            method: 'post',
            data: project
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "create_success");
                store.dispatch(getAllProjects());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    };
};

export const putProject = (id, project) => {
    return {
        type: PUT_PROJECT,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/project/${id}`,
            method: 'put',
            data: project
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "update_success");
                store.dispatch(getAllProjects());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "update_error");
                throw error;
            })
        }
    };
};

export const deleteProject = (id) => {
    return {
        type: DELETE_PROJECT,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/project/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "delete_success");
                store.dispatch(getAllProjects());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', error.response.status === 409 ? "ressource_in_use_error" : "delete_error");
                throw error;
            })
        }
    };
};