import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: "appState",
    initialState: {
        screenMedia: "lg",
        menuExpanded: true,
        headerExpanded: false,
        pageTitle: null,
        activeSection: 1,
        themeColor: {
            hex: "#266A74",
            rgb: { r: 38, g: 106, b: 116, a: 1 }
        },
        yearInvoicingType: "months",
    },
    reducers: {
        setScreenMedia: (state, action) => ({
            ...state,
            screenMedia: action.payload,
        }),
        toogleMenu: (state) => ({
            ...state,
            menuExpanded: !state.menuExpanded,
        }),
        setHeaderExpanded: (state, action) => ({
            ...state,
            headerExpanded: action.payload,
        }),
        setYearInvoicingType: (state, action) => ({
            ...state,
            yearInvoicingType: action.payload,
        }),
        clearPageTitle: (state) => ({ ...state, pageTitle: null }),
        setPageTitle: (state, action) => ({
            ...state,
            pageTitle: action.payload,
        }),
        setThemeColor: (state, action) => ({
            ...state,
            themeColor: action.payload,
        }),
        setActiveSection: (state, action) => ({
            ...state,
            activeSection: action.payload,
        }),
        clearActiveSection: (state) => ({ ...state, activeSection: 1 }),
        clearSnapshotID: (state) => ({ ...state, snapshotID: null }),
    },
});

export const {
    setScreenMedia,
    toogleMenu,
    setHeaderExpanded,
    clearPageTitle,
    setPageTitle,
    setThemeColor,
    setActiveSection,
    clearActiveSection,
    clearSnapshotID,
    setYearInvoicingType,
} = appSlice.actions;

export default appSlice.reducer;
