import { makeStyles, darken } from "@material-ui/core/styles";

export default (menuExpanded, headerExpanded) => makeStyles((theme) => ({
    drawer: {
        width: menuExpanded ? theme.custom.variables.menuLeftWidth : theme.custom.variables.closedMenuLeftWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    menuLeft: {
        position: "fixed",
        whiteSpace: "nowrap",
        width: theme.custom.variables.menuLeftWidth,
        background: "transparent",
        color: theme.palette.common.white,
        border: "none",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    container: {
        height: '100%',
        backgroundImage: `linear-gradient(to top, ${theme.palette.background.main} ${headerExpanded ? "90%" : "100%"}, ${theme.palette.background.mainTransparent} 92%, transparent 95%)`
    },
    menuLeftExpanded:{
        top: theme.custom.variables.headerHeight
    },
    logo: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: theme.spacing(1),
        "& > img": {
            width: "60%",
            maxWidth: "95px"
        },
        ...theme.mixins.toolbar,
    },
    icon: {
        color: theme.palette.common.white,
        minWidth: theme.custom.variables.menuLeftIconWidth
    },
    menuListItem: {
        paddingLeft: theme.custom.variables.menuLeftIconPadding,
        paddingRight: theme.custom.variables.menuLeftIconPadding,
        '&:hover': {
            color: "#FFFFFF",
        }
    },
    menuLeftClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.custom.variables.closedMenuLeftWidth
    },
    tab: {
        minWidth: 0,
        textTransform: "inherit",
        padding: theme.spacing(2),
        lineHeight: "1.3rem",
    },
    indicator: {
        backgroundColor: theme.palette.common.white,
        left: "0",
    },
    nested: {
        backgroundColor: darken(theme.palette.primary.main, 0.1)
    },
    selected: {
        borderLeft: `5px solid ${theme.palette.common.white}`,
        paddingLeft: `calc(${theme.custom.variables.menuLeftIconPadding}px - 5px)`
    },
    menuItemText: {
        fontSize: "1rem"
    },
    svg: {
        fontSize: theme.custom.variables.menuLeftIconSize
    }
}))();
