/************* AUTH **************/
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_USER = "GET_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

/************* RT **************/
export const GET_RT_PROJECT_TYPE = "GET_RT_PROJECT_TYPE";
export const GET_ALL_RT_PROJECT_TYPE = "GET_ALL_RT_PROJECT_TYPE";
export const SET_ALL_RT_PROJECT_TYPE = "SET_ALL_RT_PROJECT_TYPE";
export const POST_RT_PROJECT_TYPE = "POST_RT_PROJECT_TYPE";
export const PUT_RT_PROJECT_TYPE = "PUT_RT_PROJECT_TYPE";
export const DELETE_RT_PROJECT_TYPE = "DELETE_RT_PROJECT_TYPE";

export const GET_RT_PROJECT_STATUS = "GET_RT_PROJECT_STATUS";
export const GET_ALL_RT_PROJECT_STATUS = "GET_ALL_RT_PROJECT_STATUS";
export const SET_ALL_RT_PROJECT_STATUS = "SET_ALL_RT_PROJECT_STATUS";
export const POST_RT_PROJECT_STATUS = "POST_RT_PROJECT_STATUS";
export const PUT_RT_PROJECT_STATUS = "PUT_RT_PROJECT_STATUS";
export const DELETE_RT_PROJECT_STATUS = "DELETE_RT_PROJECT_STATUS";

export const GET_RT_PROJECT_CLIENT = "GET_RT_PROJECT_CLIENT";
export const GET_ALL_RT_PROJECT_CLIENT = "GET_ALL_RT_PROJECT_CLIENT";
export const SET_ALL_RT_PROJECT_CLIENT = "SET_ALL_RT_PROJECT_CLIENT";
export const POST_RT_PROJECT_CLIENT = "POST_RT_PROJECT_CLIENT";
export const PUT_RT_PROJECT_CLIENT = "PUT_RT_PROJECT_CLIENT";
export const DELETE_RT_PROJECT_CLIENT = "DELETE_RT_PROJECT_CLIENT";

export const GET_RT_PROJECT_RISK = "GET_RT_PROJECT_RISK";
export const GET_ALL_RT_PROJECT_RISK = "GET_ALL_RT_PROJECT_RISK";
export const POST_RT_PROJECT_RISK = "POST_RT_PROJECT_RISK";
export const PUT_RT_PROJECT_RISK = "PUT_RT_PROJECT_RISK";
export const DELETE_RT_PROJECT_RISK = "DELETE_RT_PROJECT_RISK";
export const SET_ALL_RT_PROJECT_RISK = "SET_ALL_RT_PROJECT_RISK";

export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const SET_ALL_PROJECTS = "SET_ALL_PROJECTS";
export const POST_PROJECT = "POST_PROJECT";
export const PUT_PROJECT = "PUT_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";

export const GET_ALL_RT_TVA_RATE = 'GET_ALL_RT_TVA_RATE';
export const POST_RT_TVA_RATE = 'POST_RT_TVA_RATE';
export const PUT_RT_TVA_RATE = 'PUT_RT_TVA_RATE';
export const DELETE_RT_TVA_RATE = 'DELETE_RT_TVA_RATE';
export const SET_ALL_RT_TVA_RATE = 'SET_ALL_RT_TVA_RATE';

export const GET_ALL_RT_TAX_TYPE = 'GET_ALL_RT_TAX_TYPE';
export const POST_RT_TAX_TYPE = 'POST_RT_TAX_TYPE';
export const PUT_RT_TAX_TYPE = 'PUT_RT_TAX_TYPE';
export const DELETE_RT_TAX_TYPE = 'DELETE_RT_TAX_TYPE';
export const SET_ALL_RT_TAX_TYPE = 'SET_ALL_RT_TAX_TYPE';

export const GET_ALL_RT_TAX_TYPE_RATES = 'GET_ALL_RT_TAX_RATES';
export const POST_RT_TAX_RATE = 'POST_RT_TAX_RATE';
export const PUT_RT_TAX_RATE = 'PUT_RT_TAX_RATE';
export const DELETE_RT_TAX_RATE = 'DELETE_RT_TAX_RATE';
export const SET_ALL_RT_TAX_TYPE_RATES = 'SET_ALL_RT_TAX_TYPE_RATES';

export const GET_ALL_RT_INVOICING_ENTITY = 'GET_ALL_RT_INVOICING_ENTITY';
export const POST_RT_INVOICING_ENTITY = 'POST_RT_INVOICING_ENTITY';
export const PUT_RT_INVOICING_ENTITY = 'PUT_RT_INVOICING_ENTITY';
export const DELETE_RT_INVOICING_ENTITY = 'DELETE_RT_INVOICING_ENTITY';
export const SET_ALL_RT_INVOICING_ENTITY = 'SET_ALL_RT_INVOICING_ENTITY';

export const GET_ALL_RT_INVOICING_CLIENT = 'GET_ALL_RT_INVOICING_CLIENT';
export const POST_RT_INVOICING_CLIENT = 'POST_RT_INVOICING_CLIENT';
export const PUT_RT_INVOICING_CLIENT = 'PUT_RT_INVOICING_CLIENT';
export const DELETE_RT_INVOICING_CLIENT = 'DELETE_RT_INVOICING_CLIENT';
export const SET_ALL_RT_INVOICING_CLIENT = 'SET_ALL_RT_INVOICING_CLIENT';

export const GET_ALL_RT_INVOICING_STATUS = 'GET_ALL_RT_INVOICING_STATUS';
export const SET_ALL_RT_INVOICING_STATUS = 'SET_ALL_RT_INVOICING_STATUS';

export const GET_ALL_RT_INVOICE_ATTACHMENT_TYPE = 'GET_ALL_RT_INVOICE_ATTACHMENT_TYPE';
export const POST_RT_INVOICE_ATTACHMENT_TYPE = 'POST_RT_INVOICE_ATTACHMENT_TYPE';
export const PUT_RT_INVOICE_ATTACHMENT_TYPE = 'PUT_RT_INVOICE_ATTACHMENT_TYPE';
export const DELETE_RT_INVOICE_ATTACHMENT_TYPE = 'DELETE_RT_INVOICE_ATTACHMENT_TYPE';
export const SET_ALL_RT_INVOICE_ATTACHMENT_TYPE = 'SET_ALL_RT_INVOICE_ATTACHMENT_TYPE';

export const GET_ALL_RT_CURRENCY = 'GET_ALL_RT_CURRENCY';
export const GET_ALL_AVAILABLE_CURRENCIES = 'GET_ALL_AVAILABLE_CURRENCIES';
export const POST_RT_CURRENCY = 'POST_RT_CURRENCY';
export const UPDATE_CURRENCIES_DEFAULTS = 'UPDATE_CURRENCIES_DEFAULTS';
export const DELETE_RT_CURRENCY = 'DELETE_RT_CURRENCY';
export const SET_ALL_RT_CURRENCIES = 'SET_ALL_RT_CURRENCIES';

export const GET_ALL_RT_BANK_ACCOUNT = 'GET_ALL_RT_BANK_ACCOUNT';
export const POST_RT_BANK_ACCOUNT = 'POST_RT_BANK_ACCOUNT';
export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';
export const DELETE_RT_BANK_ACCOUNT = 'DELETE_RT_BANK_ACCOUNT';
export const SET_ALL_RT_BANK_ACCOUNT = 'SET_ALL_RT_BANK_ACCOUNT';
export const DOWNLOAD_BANK_RIB_ATTACHMENT = 'DOWNLOAD_BANK_RIB_ATTACHMENT';

export const GET_RT_INVOICE_TYPE = "GET_RT_INVOICE_TYPE";
export const GET_ALL_RT_INVOICE_TYPE = "GET_ALL_RT_INVOICE_TYPE";
export const SET_ALL_RT_INVOICE_TYPE = "SET_ALL_RT_INVOICE_TYPE";
export const POST_RT_INVOICE_TYPE = "POST_RT_INVOICE_TYPE";
export const PUT_RT_INVOICE_TYPE = "PUT_RT_INVOICE_TYPE";
export const DELETE_RT_INVOICE_TYPE = "DELETE_RT_INVOICE_TYPE";

/************* FINANCIAL INDICATORS **************/
export const GET_GLOBAL_FINANCIAL_INDICATORS = 'GET_GLOBAL_FINANCIAL_INDICATORS';
export const SET_GLOBAL_FINANCIAL_INDICATORS = 'SET_GLOBAL_FINANCIAL_INDICATORS';

/************* INVOICING **************/
export const GET_FINANCIAL_INVOICING_STATISTICS = 'GET_FINANCIAL_INVOICING_STATISTICS';
export const GET_FINANCIAL_INVOICING = 'GET_FINANCIAL_INVOICING';
export const GET_FINANCIAL_INVOICES_YEARS = "GET_FINANCIAL_INVOICES_YEARS";
export const GET_SEARCH_FINANCIAL_INVOICES = 'GET_SEARCH_FINANCIAL_INVOICES';
export const GET_SEARCH_FINANCIAL_INVOICES_TO_SETTLE = 'GET_SEARCH_FINANCIAL_INVOICES_TO_SETTLE';
export const GET_SEARCH_FINANCIAL_GROUPED_INVOICES_TO_SETTLE = 'GET_SEARCH_FINANCIAL_GROUPED_INVOICES_TO_SETTLE';
export const GET_FINANCIAL_SELECTED_INVOICE = 'GET_FINANCIAL_SELECTED_INVOICE';
export const GET_FINANCIAL_INVOICE_AUDIT = 'GET_FINANCIAL_INVOICE_AUDIT';
export const GET_FINANCIAL_ACTIONS_AUDIT = 'GET_FINANCIAL_ACTIONS_AUDIT';
export const GET_FINANCIAL_ACTION_CHANGE_LOG = 'GET_FINANCIAL_ACTION_CHANGE_LOG';
export const GET_FINANCIAL_PAYMENTS = 'GET_FINANCIAL_PAYMENTS';
export const GET_INVOICE_ISSSUE_MAIL = 'GET_INVOICE_ISSSUE_MAIL';
export const GET_LAST_EMITTED_INVOICE_DATE = 'GET_LAST_EMITTED_INVOICE_DATE';
export const GET_AVOIR_INVOICE = 'GET_AVOIR_INVOICE';

export const POST_FINANCIAL_INVOICE = 'POST_FINANCIAL_INVOICE';
export const PUT_FINANCIAL_INVOICE = 'PUT_FINANCIAL_INVOICE';
export const DELETE_FINANCIAL_INVOICE = 'DELETE_FINANCIAL_INVOICE';
export const DELETE_USER = "DELETE_USER";
export const SETTLE_FINANCIAL_INVOICES = 'SETTLE_FINANCIAL_INVOICES';
export const ADD_INVOICE_PAYMENT = 'ADD_INVOICE_PAYMENT';
export const DELETE_MULTIPLE_FINANCIAL_INVOICES = 'DELETE_MULTIPLE_FINANCIAL_INVOICES';
export const DUPLICATE_MULTIPLE_FINANCIAL_INVOICES = 'DUPLICATE_MULTIPLE_FINANCIAL_INVOICES';
export const EXPORT_FINANCIAL_PAYMENTS = 'EXPORT_FINANCIAL_PAYMENTS';
export const EXPORT_FINANCIAL_INVOICING = 'EXPORT_FINANCIAL_INVOICING';
export const EXPORT_FINANCIAL_INVOICE = 'EXPORT_FINANCIAL_INVOICE';
export const IMPORT_FINANCIAL_INVOICES = 'UPLOAD_FINANCIAL_INVOICES';
export const GET_INVOICE_ATTACHMENTS = 'GET_INVOICE_ATTACHMENTS';
export const UPLOAD_INVOICE_ATTACHMENT = 'UPLOAD_INVOICE_ATTACHMENT';
export const DOWNLOAD_INVOICE_ATTACHMENT = 'DOWNLOAD_INVOICE_ATTACHMENT';
export const DOWNLOAD_MAIL_ATTACHMENT = 'DOWNLOAD_MAIL_ATTACHMENT';
export const PUT_INVOICE_ATTACHMENT = 'PUT_INVOICE_ATTACHMENT';
export const DELETE_INVOICE_ATTACHMENT = 'DELETE_INVOICE_ATTACHMENT';
export const SEND_INVOICE_TO_CLIENT = 'SEND_INVOICE_TO_CLIENT';
export const ISSUE_ELECTRONIC_INVOICE = 'ISSUE_ELECTRONIC_INVOICE';

export const SET_FINANCIAL_PAYMENTS = 'SET_FINANCIAL_PAYMENTS';
export const SET_FINANCIAL_PAYMENTS_FILTER = 'SET_FINANCIAL_PAYMENTS_FILTER';
export const SET_FINANCIAL_INVOICING_FILTER = 'SET_FINANCIAL_INVOICING_FILTER';
export const SET_FINANCIAL_INVOICING_STATISTICS = 'SET_FINANCIAL_INVOICING_STATISTICS';
export const SET_FINANCIAL_INVOICES_YEARS = 'SET_FINANCIAL_INVOICES_YEARS';
export const SET_FINANCIAL_MONTH_INVOICING = 'SET_FINANCIAL_MONTH_INVOICING';
export const SET_FINANCIAL_MONTH_PAYMENTS = 'SET_FINANCIAL_MONTH_PAYMENTS';
export const SET_FINANCIAL_INVOICES = 'SET_FINANCIAL_INVOICES';
export const SET_SEARCH_FINANCIAL_INVOICES = 'SET_SEARCH_FINANCIAL_INVOICES';
export const SET_FINANCIAL_SELECTED_INVOICE = 'SET_FINANCIAL_SELECTED_INVOICE';
export const SET_FINANCIAL_INVOICE_AUDIT = 'SET_FINANCIAL_INVOICE_AUDIT';
export const SET_FINANCIAL_ACTIONS_AUDIT = 'SET_FINANCIAL_ACTIONS_AUDIT';
export const SET_FINANCIAL_ACTIONS_AUDIT_FILTER = 'SET_FINANCIAL_ACTIONS_AUDIT_FILTER';
export const SET_FINANCIAL_ACTIONS_AUDIT_PAGINATION = 'SET_FINANCIAL_ACTIONS_AUDIT_PAGINATION';
export const SET_FINANCIAL_INVOICES_PAGINATION = 'SET_FINANCIAL_INVOICES_PAGINATION';
export const SET_FINANCIAL_PAYMENTS_PAGINATION = 'SET_FINANCIAL_PAYMENTS_PAGINATION';
export const SET_FINANCIAL_INVOICES_SORT = 'SET_FINANCIAL_INVOICES_SORT';
export const SET_FINANCIAL_PAYMENTS_SORT = 'SET_FINANCIAL_PAYMENTS_SORT';
export const SET_INVOICE_ATTACHMENTS = 'SET_INVOICE_ATTACHMENTS';

export const CLEAR_FINANCIAL_INVOICES_PAGINATION = 'CLEAR_FINANCIAL_INVOICES_PAGINATION';
export const CLEAR_FINANCIAL_PAYMENTS_PAGINATION = 'CLEAR_FINANCIAL_PAYMENTS_PAGINATION';
export const CLEAR_FINANCIAL_SELECTED_INVOICE = 'CLEAR_FINANCIAL_SELECTED_INVOICE';
export const CLEAR_SEARCH_FINANCIAL_INVOICES = 'CLEAR_SEARCH_FINANCIAL_INVOICES';
export const CLEAR_FINANCIAL_INVOICING_FILTER = 'CLEAR_FINANCIAL_INVOICING_FILTER';
export const CLEAR_FINANCIAL_PAYMENTS_FILTER = 'CLEAR_FINANCIAL_PAYMENTS_FILTER';
export const CLEAR_FINANCIAL_INVOICES_SORT = 'CLEAR_FINANCIAL_INVOICES_SORT';
export const CLEAR_FINANCIAL_PAYMENTS_SORT = 'CLEAR_FINANCIAL_PAYMENTS_SORT';

/************* E-SIGNATURE **************/
export const POST_VERIFY_DOCUMENT_SIGNATURE = 'POST_VERIFY_DOCUMENT_SIGNATURE';
export const POST_SIGN_DOCUMENT = 'POST_SIGN_DOCUMENT';

/************* Dashboard **************/
export const GET_TOP_INVOICING_CLIENTS = 'GET_TOP_INVOICING_CLIENTS';
export const SET_TOP_INVOICING_CLIENTS = 'SET_TOP_INVOICING_CLIENTS';
export const GET_INVOICING_CLIENTS_PAYMENT_TIME = 'GET_INVOICING_CLIENTS_PAYMENT_TIME';
export const SET_INVOICING_CLIENTS_PAYMENT_TIME = 'SET_INVOICING_CLIENTS_PAYMENT_TIME';
export const GET_TURNOVER_DATA = 'GET_TURNOVER_DATA';
export const SET_TURNOVER_DATA = 'SET_TURNOVER_DATA';

export const SET_DASHBOARD_INVOICES = 'SET_DASHBOARD_INVOICES';
export const SET_DASHBOARD_INVOICES_SORT = 'SET_DASHBOARD_INVOICES_SORT';
export const CLEAR_DASHBOARD_INVOICES_PAGINATION = 'CLEAR_DASHBOARD_INVOICES_PAGINATION';
export const SET_DASHBOARD_INVOICES_PAGINATION = 'SET_DASHBOARD_INVOICES_PAGINATION';
export const SET_DASHBOARD_INVOICES_FILTER = 'SET_DASHBOARD_INVOICES_FILTER';
export const CLEAR_DASHBOARD_INVOICES_FILTER = 'CLEAR_DASHBOARD_INVOICES_FILTER';

/************* Parameters **************/
export const GET_FINANCIAL_INVOICE_COMPANY = 'GET_FINANCIAL_INVOICE_COMPANY';
export const PUT_FINANCIAL_INVOICE_COMPANY = 'PUT_FINANCIAL_INVOICE_COMPANY';
export const SET_FINANCIAL_INVOICE_COMPANY = 'SET_FINANCIAL_INVOICE_COMPANY';

export const GET_INVOICING_CONFIG = 'GET_INVOICING_CONFIG';
export const PUT_INVOICING_CONFIG = 'PUT_INVOICING_CONFIG';
export const SET_INVOICING_CONFIG = 'SET_INVOICING_CONFIG';

/************* Collaborators *************/
export const GET_ALL_RESPONSABLES = "GET_ALL_RESPONSABLES";
export const SET_ALL_RESPONSABLES = "SET_ALL_RESPONSABLES";
export const GET_CURRENT_COLLABORATOR = "GET_CURRENT_COLLABORATOR";
export const SET_CURRENT_COLLABORATOR = "SET_CURRENT_COLLABORATOR";
export const GET_COLLABORATOR = "GET_COLLABORATOR";
export const PUT_COLLABORATOR = "PUT_COLLABORATOR";
export const PUT_COLLABORATOR_ACCESS_CONTROL = "PUT_COLLABORATOR_ACCESS_CONTROL";

/************** Users *****************/
export const GET_ALL_USERS = "GET_ALL_USERS";
export const POST_USER = "POST_USER";
export const PUT_USER = "PUT_USER";
export const SET_SEARCH_FILTER = "SET_SEARCH_FILTER";
export const SET_ALL_USERS = "SET_ALL_USERS";
export const SET_CURRENT_USER_TENANTS = "SET_CURRENT_USER_COMPANIES";
export const GET_CURRENT_USER_TENANTS = "GET_CURRENT_USER_TENANTS";
export const SWITCH_CURRENT_USER_TENANT = "SWITCH_CURRENT_USER_TENANT";

/************** Analytics *************/
export const GET_ALL_AVOIREE_INVOICES = 'GET_ALL_AVOIREE_INVOICES';
export const GET_ALL_CLIENTS_WITH_INVOICES = 'GET_ALL_CLIENTS_WITH_INVOICES';
export const GET_AGED_TRIAL_BALNCE_STATS = 'GET_AGED_TRIAL_BALNCE_STATS';

export const EXPORT_AGED_TRIAL_BALANCE = 'EXPORT_AGED_TRIAL_BALANCE';
export const CLEAR_ALL_CLIENTS_WITH_INVOICES = 'CLEAR_ALL_CLIENTS_WITH_INVOICES';

export const SET_ALL_AVOIREE_INVOICES = 'SET_ALL_AVOIREE_INVOICES'
export const SET_ALL_CLIENTS_WITH_INVOICES = 'SET_ALL_CLIENTS_WITH_INVOICES';
export const SET_AGED_TRIAL_BALNCE_PAGINATION = 'SET_AGED_TRIAL_BALNCE_PAGINATION';
export const SET_AGED_TRIAL_BALNCE_STATS = 'SET_AGED_TRIAL_BALNCE_STATS';
export const SET_AGED_TRIAL_BALNCE_SORT = 'SET_AGED_TRIAL_BALNCE_SORT';
