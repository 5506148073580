import React from 'react';
import { ADMIN_INVOICING_ROLE, BREADCRUMBS } from '../../../utils/constants';

const Administration = React.lazy(() => import('../../pages/Administration'));

export default [
    { 
        path: '/administration/reference-tables',
        exact: true, 
        name: 'rtables', 
        component: Administration,
        permissions: [],
        private: true, 
        className:'breadcrumb-settings',
        roles: [ADMIN_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    },
    { 
        path: '/administration/reference-tables/taxes',
        exact: true, 
        name: 'rtables', 
        component: Administration,
        permissions: [],
        private: true, 
        className:'breadcrumb-settings',
        roles: [ADMIN_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: false
            },
            show: false
        }
    },
    { 
        path: '/administration/reference-tables/invoice-clients',
        exact: true, 
        name: 'rtables', 
        component: Administration,
        permissions: [],
        private: true, 
        className:'breadcrumb-settings',
        roles: [ADMIN_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: false
            },
            show: false
        }
    },
    { 
        path: '/administration/reference-tables/invoice-entities',
        exact: true, 
        name: 'rtables', 
        component: Administration,
        permissions: [],
        private: true, 
        className:'breadcrumb-settings',
        roles: [ADMIN_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: false
            },
            show: false
        }
    },
    { 
        path: '/administration/reference-tables/projects',
        exact: true, 
        name: 'rtables', 
        component: Administration,
        permissions: [],
        private: true, 
        className:'breadcrumb-settings',
        roles: [ADMIN_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: false
            },
            show: false
        }
    },
    { 
        path: '/administration/reference-tables/project-status',
        exact: true, 
        name: 'rtables', 
        component: Administration,
        permissions: [],
        private: true, 
        className:'breadcrumb-settings',
        roles: [ADMIN_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: false
            },
            show: false
        }
    },
    { 
        path: '/administration/reference-tables/project-types',
        exact: true, 
        name: 'rtables', 
        component: Administration,
        permissions: [],
        private: true, 
        className:'breadcrumb-settings',
        roles: [ADMIN_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: false
            },
            show: false
        }
    },
    { 
        path: '/administration/reference-tables/project-clients',
        exact: true, 
        name: 'rtables', 
        component: Administration,
        permissions: [],
        private: true, 
        className:'breadcrumb-settings',
        roles: [ADMIN_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: false
            },
            show: false
        }
    },
    { 
        path: '/administration/company-information',
        exact: true, 
        name: 'company_information', 
        component: Administration,
        permissions: [],
        private: true, 
        className:'breadcrumb-settings',
        roles: [ADMIN_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    },
    {
        path: '/administration/invoicing-config',
        exact: true,
        name: 'invoicing_config',
        component: Administration,
        permissions: [],
        private: true,
        className: 'breadcrumb-settings',
        roles: [ADMIN_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    },
    {
        path: '/administration/access-control',
        exact: true,
        name: 'access_control',
        component: Administration,
        permissions: [],
        private: true,
        className: 'breadcrumb-settings',
        roles: [ADMIN_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    },
    {
        path: '/administration/global-audit',
        exact: true,
        name: 'global_audit',
        component: Administration,
        permissions: [],
        private: true,
        className: 'breadcrumb-settings',
        roles: [ADMIN_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    }
];