import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    root: {
        
    },
    paginationAction: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    seeMoreBtn: {
        textTransform: "capitalize"
    }
}));