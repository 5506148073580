import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import {
  deleteTaxRate,
  postTaxRate,
  putTaxRate,
} from "../../../../redux/actions/rt";
import { useState } from "react";
import NoData from "../../../../../App/components/NoData";


function TaxRateTable({
  loading,
  renderColHeaders,
  dispatch,
  classes,
  typeInstances,
  setActionModal,
  actionModal,
  displayModal,
  displayTypes,
  rateInstances,
  setRateInstances,
  selectedTypeInstance,
  setSelectedTypeInstance
}) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [createRate, setCreateRate] = useState(false);
  const [editRate, setEditRate] = useState(false);
  const [selectedRate, setSelectedRate] = useState({});

  const transform = (val, reverse) => {
    if (reverse) {
      return Number((val / 100).toFixed(10));
    }
    return Number((val * 100).toFixed(10));
  };

  const saveEditRate = () => {
    dispatch(putTaxRate(selectedRate.id, selectedRate)).then(res=>{
      if(res.status === 200){
        setEditRate(false);
        setSelectedRate({});
      }
    })
  };

  const openCreateRateForm = () => {
    setCreateRate(true);
    setSelectedRate({
      value: null,
      isDefaultValue: false,
      taxType: selectedTypeInstance,
    });
  };

  const saveRate = () => {
    dispatch(postTaxRate(selectedRate)).then(res=>{
      if(res.status === 200){
        setCreateRate(false);
        setSelectedRate({});
      }
    });
  };

  const cancelCreateRate = () => {
    setCreateRate(false);
    setRateInstances([...selectedTypeInstance.taxRates]);
  };

  const cancelEditRate = () => {
    setEditRate(false);
    setSelectedRate({});
    setRateInstances([...selectedTypeInstance.taxRates]);
  };

  const handleDeleteRate = (item) => {
    setActionModal({
      ...actionModal,
      open: true,
      type: "error",
      title: t("suppression"),
      body: (
        <span>
          {t("confirm_delete_rate")} <b>{transform(item.value, false)}%</b> ?
        </span>
      ),
      showCloseButton: true,
      onChange: displayModal,
      cancel: {
        title: t("cancel"),
        onClick: null,
      },
      confirm: {
        title: t("confirm"),
        onClick: () => {
          dispatch(deleteTaxRate(item.id));
          displayModal(false);
        },
      },
    });
  };

  const openEditRate = useCallback((item) => {
    setEditRate(true);
    setSelectedRate({
      id: item.id,
      value: item.value,
      isDefaultValue: item.isDefaultValue,
      taxType: item.taxType,
    });
    setDisabled(false);
  }, []);

  

  const handleRadioChange = (e) => {
    setSelectedRate({
      ...selectedRate,
      isDefaultValue: e.target.checked,
    });
    if (e.target.checked) {
      setRateInstances([
        ...rateInstances.map((rate) =>
          e.target.checked
            ? { ...rate, isDefaultValue: false }
            : rate
        ),
      ]);
    }
  }

  useEffect(() => {
    let updatedSelectedType = null;
    if(Object.keys(selectedTypeInstance).length > 0 ){
      updatedSelectedType = typeInstances.filter(type => type.id === selectedTypeInstance.id)[0];

    }
    setSelectedTypeInstance(updatedSelectedType ? updatedSelectedType : typeInstances[0])
    setRateInstances(updatedSelectedType?.taxRates ? updatedSelectedType.taxRates : typeInstances[0].taxRates);
  }, [typeInstances]);//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createRate || editRate) {
      setDisabled(Number(selectedRate.value) !== 0 ? false : true);
    }
  }, [selectedRate]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Grid container alignItems="flex-start">
        {!displayTypes && (
          <>
            <Grid container item xs={12} justifyContent="space-between">
              <Typography color="primary" variant="h6">
                {t("tax_rates_list_for",{typeLabel: selectedTypeInstance.label})}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={openCreateRateForm}
                hidden={createRate}
              >
                {t("add")}
              </Button>
            </Grid>
            <Grid item xs={10}>
              {renderColHeaders("taxRate")}
            </Grid>
          </>
        )}
      </Grid>
      {!displayTypes && rateInstances && (
        <NoData
          condition={rateInstances.length === 0 && !createRate}
          loading={loading}
        />
      )}
      {!displayTypes && (
        <>
          {createRate && (
            <Card className={classes.root} elevation={1}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={10}>
                  <CardContent
                    className={classes.content}
                    style={{ paddingBottom: "16px" }}
                  >
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={5}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Box mr={1} textAlign="center">
                            <TextField
                              type="number"
                              placeholder={t("value")}
                              defaultValue={transform(
                                selectedRate.value,
                                false
                              )}
                              variant="outlined"
                              size="small"
                              fullWidth
                              onChange={(e) => {
                                setSelectedRate({
                                  ...selectedRate,
                                  value: transform(e.target.value, true),
                                });
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    classes={{ root: classes.endAdornment }}
                                    position="end"
                                  >
                                    %
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={5}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Box mr={1} textAlign="center">
                            <Radio
                              checked={selectedRate.isDefaultValue}
                              color="primary"
                              onChange={(e) => {
                                handleRadioChange(e);
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
                <Grid item xs={2}>
                  <CardActions>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={saveRate}
                          disabled={disabled}
                        >
                          {t("save")}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Tooltip title={t("cancel")} placement="top">
                          <IconButton onClick={cancelCreateRate}>
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Grid>
              </Grid>
            </Card>
          )}
          {loading
            ? [...Array(3)].map((_, index) => (
                <Box key={index}>
                  <Skeleton
                    height={62.5}
                    variant="rect"
                    animation="wave"
                    className={classes.skeleton}
                  />
                </Box>
              ))
            : rateInstances &&
              rateInstances.map((item, index) => (
                <Card key={index} className={classes.root} elevation={1}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={10}>
                      <CardContent
                        className={classes.content}
                        style={{ paddingBottom: "16px" }}
                      >
                        <Grid container>
                          <Grid
                            container
                            item
                            xs={5}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Grid item>
                              <Box textAlign="center">
                                {editRate && selectedRate.id === item.id ? (
                                  <Box mr={1}>
                                    <TextField
                                      type="number"
                                      defaultValue={transform(
                                        item.value,
                                        false
                                      )}
                                      variant="outlined"
                                      size="small"
                                      placeholder={t("value")}
                                      fullWidth
                                      onChange={(e) => {
                                        setSelectedRate({
                                          ...selectedRate,
                                          value: transform(
                                            e.target.value,
                                            true
                                          ),
                                        });
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            classes={{
                                              root: classes.endAdornment,
                                            }}
                                            position="end"
                                          >
                                            %
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <Typography>
                                    {transform(item.value, false)}%
                                  </Typography>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={5}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Grid item>
                              <Box textAlign="center">
                                {editRate && selectedRate.id === item.id ? (
                                  <Box mr={1} textAlign="center">
                                    <Radio
                                      checked={selectedRate.isDefaultValue}
                                      color="primary"
                                      onChange={(e) => handleRadioChange(e)}
                                    />
                                  </Box>
                                ) : (
                                  item.isDefaultValue && (
                                    <CheckCircleIcon
                                      color="primary"
                                      fontSize="small"
                                    />
                                  )
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Grid>
                    <Grid item xs={2}>
                      <CardActions>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid item>
                            <Tooltip
                              title={
                                editRate && selectedRate.id === item.id
                                  ? t("save_quick_edit")
                                  : t("edit")
                              }
                              placement="top"
                            >
                              {editRate && selectedRate.id === item.id ? (
                                <IconButton
                                  color="primary"
                                  onClick={saveEditRate}
                                  disabled={disabled}
                                >
                                  <DoneIcon />
                                </IconButton>
                              ) : (
                                <IconButton onClick={() => openEditRate(item)}>
                                  <EditIcon />
                                </IconButton>
                              )}
                            </Tooltip>
                          </Grid>
                          {editRate && selectedRate.id === item.id && (
                            <Grid item>
                              <Tooltip title={t("cancel")} placement="top">
                                <IconButton onClick={cancelEditRate}>
                                  <ClearIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                          <Grid item>
                            <Tooltip title={t("delete")} placement="top">
                              <IconButton
                                onClick={() => handleDeleteRate(item)}
                              >
                                <DeleteIcon className={classes.deleteIcon} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Grid>
                  </Grid>
                </Card>
              ))}
        </>
      )}
    </Box>
  );
}

export default TaxRateTable;
