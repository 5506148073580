import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    paper: {
        height: "100%",
        padding: theme.spacing(1.5, 2),
        boxShadow: theme.shadows[25]
    },
    title: {
        fontSize: "1.15rem",
        fontWeight: "500",
        marginBottom: theme.spacing(2)
    },
    container1: {
        height: "2.5rem",
        borderBottom: "1px solid #ddd",
        padding: theme.spacing(0, 2),
    },
    container2: {
        height: "3rem",
        borderBottom: "1px solid #ddd",
        padding: theme.spacing(0, 2),
    },
    dialogPaper: {
        width: "40%"
    }
}));