import React from "react";
import { useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import HeaderToolbar from "./HeaderToolbar";
import useStyles from "./style";
import MySettings from "../MySettings";

export default function () {
    const classes = useStyles();
    //const { t } = useTranslation();
    //const dispatch = useDispatch();
    //const keycloak = useSelector(({ keycloak }) => keycloak);
    const headerExpanded = useSelector(
        ({ appState }) => appState.headerExpanded
    );
    // const globalIndicators = useSelector(
    //     ({ indicators }) => indicators.global
    // );
    // const { firstName, lastName } = useSelector(({ Auth }) => Auth.user);
    // const [anchorProfileEl, setAnchorProfileEl] = React.useState(null);
    const [openMySettings, setOpenMySettings] = React.useState(false);

    // const cards = () => {
    //     return (
    //         <Grid container item direction="row">
    //             <CardHeader
    //                 title={`${globalIndicators.CAVAMensuel}`}
    //                 content={t("cava_mensuel")}
    //             />
    //             <CardHeader
    //                 title={`${globalIndicators.MBthMensuel}%`}
    //                 content={t("mbth_mensuel")}
    //             />
    //             <CardHeader
    //                 title={`${globalIndicators.MBthCumule}%`}
    //                 content={t("mbth_cumule")}
    //             />
    //         </Grid>
    //     );
    // };

    // const profile = () => {
    //     return (
    //         <Grid item className="text-center p-2 pb-3">
    //             <IconButton
    //                 onClick={(e) => setAnchorProfileEl(e.currentTarget)}
    //             >
    //                 <Badge
    //                     overlap="circular"
    //                     anchorOrigin={{
    //                         vertical: "bottom",
    //                         horizontal: "right",
    //                     }}
    //                     badgeContent={<ArrowDropDown color="primary" />}
    //                 >
    //                     <Avatar
    //                         style={{ backgroundColor: getAvatarColor(firstName + lastName) }}
    //                     >{`${firstName.charAt(0)}${lastName.charAt(0)}`}</Avatar>
    //                 </Badge>
    //             </IconButton>
    //             <Typography
    //                 variant="body2"
    //                 align="center"
    //                 className={classes.title}
    //             >
    //                 {`${firstName} ${lastName}`}
    //             </Typography>
    //         </Grid>
    //     );
    // };

    // useEffect(() => {
    //     const onScroll = () => {
    //         let st = window.pageYOffset || document.documentElement.scrollTop;
    //         if (headerExpanded && st > 120) {
    //             dispatch(setHeaderExpanded(false));
    //         } else if (!headerExpanded && st <= 120) {
    //             dispatch(setHeaderExpanded(true));
    //         }
    //     };

    //     window.addEventListener("scroll", onScroll);

    //     return () => {
    //         window.removeEventListener("scroll", onScroll);
    //     };
    // }, [headerExpanded]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <AppBar
                position="relative"
                color="transparent"
                className={clsx(
                    classes.root,
                    !headerExpanded && classes.rootSticky
                )}
                elevation={24}
            >
                {/* <Grid
                    container
                    className={classes.gridContainer}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item>
                        <img src={logo} alt="Logo" className={classes.logo} />
                    </Grid>
                    <Grid item>
                        {cards()}
                    </Grid>
                    {profile()}
                    <Popover
                        id="profile-popover"
                        open={Boolean(anchorProfileEl)}
                        anchorEl={anchorProfileEl}
                        onClose={() => setAnchorProfileEl(null)}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        PaperProps={{
                            style: {
                                padding: "6px 0",
                            },
                        }}
                    >
                        <CardActionArea
                            onClick={() => {
                                setAnchorProfileEl(null);
                                window.open(`${ReactENV.REACT_APP_NOVY_WEBAPP_URL}/collaborators/user`, "_blank");
                            }}
                        >
                            <ListItemText
                                primary={
                                    <Typography variant="body2">
                                        <PersonIcon fontSize="small" />{" "}
                                        {t("my_novy_account")}
                                    </Typography>
                                }
                                className={classes.listItemText}
                            />
                        </CardActionArea>
                        <CardActionArea onClick={() => {
                            setOpenMySettings(true);
                            setAnchorProfileEl(null);
                        }}
                        >
                            <ListItemText
                                primary={
                                    <Typography variant="body2">
                                        <TuneIcon fontSize="small" />{" "}
                                        {t("my_settings")}
                                    </Typography>
                                }
                                className={classes.listItemText}
                            />
                        </CardActionArea>
                        <CardActionArea onClick={() => dispatch(logout(keycloak))}>
                            <ListItemText
                                primary={
                                    <Typography variant="body2">
                                        <ExitToAppIcon fontSize="small" />{" "}
                                        {t("logout")}
                                    </Typography>
                                }
                                className={classes.listItemText}
                            />
                        </CardActionArea>
                    </Popover>
                </Grid> */}
                <HeaderToolbar
                    setOpenMySettings={setOpenMySettings}
                />
            </AppBar>

            <MySettings
                open={openMySettings}
                setOpen={setOpenMySettings}
            />
        </>
    );
}
