import { makeStyles } from "@material-ui/core";


export default makeStyles(() => ({
    datePicker: {
        "& input": {
            height: "33px"
        }
    },
    currencyContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    bankAccountSelect: {
        "& .MuiSelect-root": {
            minWidth: "40px"
        }
    }
}));