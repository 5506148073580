import React, { useEffect, useState } from "react";
import {
    Grid,
    Paper,
    TextField,
    Button,
    Typography, Collapse
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import useStyles from './style';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getInvoiceCompany, putInvoiceCompany } from "../../redux/actions/parameters";
import BackupIcon from '@material-ui/icons/Backup';
import { fileToBase64 } from "../../../utils/io";
import UploadFiles from "../../../App/components/UploadFiles";
import {getFileExtensionFromFileName, getFileTypeFromExtension} from "../../../utils/files";
import AttachmentCard from "../RTables/AttachmentCard";

const textFields = [
    { name: "name", required: true },
    { name: "address", required: true },
    { name: "postalCode", required: true },
    { name: "city", required: true },
    { name: "country", required: true },
    { name: "siren", required: false },
    { name: "email", required: true },
    { name: "tvaNumber", required: false }
];

function CompanyInformation() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const invoiceCompany = useSelector(({ parameters }) => parameters.invoiceCompany);
    const { register, handleSubmit, formState, reset, setValue } = useForm();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedAttachment, setSelectedAttachment] = useState({});
    const [isUploadFormVisible, setIsUploadFormVisible] = useState(false);

    const onSubmit = (data) => {
        dispatch(putInvoiceCompany(data));
    };


    // const handleFileUpload = async () => {
    //     if (!selectedFile) {
    //         setFileUploadMessage('Please select a file first');
    //         return;
    //     }

        
    //     try {
    //         const response = await axios.post('http://localhost:8080/upload', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });
    //         setFileUploadMessage(response.data);
    //     } catch (error) {
    //         setFileUploadMessage('Error uploading file');
    //     }
    // }

    const onSubmitAttachment = (data) => {
        setSelectedAttachment({
            ...selectedAttachment,
            file: data,
        })
        setValue(
            "invoiceCompanyLogo",
            {...selectedAttachment, file: data},
            { shouldDirty: true }
        );
        setIsUploadFormVisible(false);
        return new Promise((resolve) => {
            resolve();
        });
    }

    const loadAttachment = (data, fileName) => {
        const extension = getFileExtensionFromFileName(fileName).toLowerCase();
        setSelectedAttachment({
            file: data,
            fileName,
            fileType: getFileTypeFromExtension(extension)
        });
    }

    useEffect(() => {
        dispatch(getInvoiceCompany()).then((response) => {
            if (response.data.invoiceCompanyLogo !== null) {
                setSelectedAttachment(response.data.invoiceCompanyLogo);
            }
        });
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        reset(invoiceCompany, { keepDirty: false });
    }, [invoiceCompany]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Paper className={classes.paper}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t('invoice_company')}</Typography>
                    </Grid>
                    {
                        textFields.map((field, idx) =>
                            <Grid item xs={(((textFields.length % 2) !== 0) && (textFields.length - 1 === idx)) ?  12 : 6} key={idx}>
                                <TextField
                                    label={t(field.name)}
                                    defaultValue=" "
                                    variant="outlined"
                                    fullWidth
                                    {...register(field.name, { required: field.required })}
                                    error={formState.errors[field.name]}
                                />
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <TextField
                            label={t('bankingInfoBloc')}
                            defaultValue=" "
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            {...register("bankingInfoBloc", { required: false })}
                            error={formState.errors["bankingInfoBloc"]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t('footerMessage')}
                            defaultValue=" "
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            {...register("footerMessage", { required: false })}
                            error={formState.errors["footerMessage"]}
                        />
                    </Grid>
                    <Grid item container xs={2}>
                        <Grid item container className={classes.mailFieldContainer} justifyContent="space-between">
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        className={classes.addAttachmentButton}
                                        onClick={() => {
                                            setIsUploadFormVisible(!isUploadFormVisible)
                                        }}
                                    >
                                        {isUploadFormVisible
                                            ? t("close")
                                            : (
                                                selectedAttachment.file
                                                    ? t("modify_company_logo")
                                                    : t("add_company_logo")
                                            )
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        <Collapse in={isUploadFormVisible}>
                                <Grid container className={classes.uploadContainer} component={Paper}>
                                    <Grid item xs={12} className={classes.uploadZone}>
                                        {isUploadFormVisible && <UploadFiles
                                            inline={false}
                                            types={["jpg", "png", "jpeg"]}
                                            onSubmit={onSubmitAttachment}
                                            onLoad={loadAttachment}
                                            buttonTitle={t("add")}
                                            validated={selectedAttachment.name !== null && selectedAttachment.name !== "" && selectedAttachment.type !== null}
                                        />}
                                    </Grid>
                                </Grid>
                            </Collapse>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={3} >
                            {(selectedAttachment.file) &&
                                <AttachmentCard
                                    attachment={selectedAttachment}
                                    extension={getFileExtensionFromFileName(selectedAttachment.fileName).toLowerCase()}
                                    handleDeleteAttachment={() => {}}
                                    isIssueMailSent={true}
                                    downLoadAttachment={() => {}}
                                />
                            }
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disableElevation
                            disabled={!formState.errors || !formState.isDirty}
                        >{t('save')}</Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>

    );

}

export default CompanyInformation;