import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Chip,
    Tooltip,
    CardActions,
    IconButton
} from '@material-ui/core';
import useStyles from './style';
import { formatAmount } from '../../../utils/converters';
import { getInvoiceStatusColor } from '../../../utils/invoice-helper';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import { enMonthsLower } from '../../../utils/constants';
import { useHistory } from 'react-router-dom';
import { capitalize } from 'lodash';

export function MonthInvoicingCard({ monthInvoicing, openCreateInvoice }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const filterYear = useSelector(({ invoicing }) => invoicing.filter.year);
    const defaultCurrency = useSelector(({ rt }) => rt.currencies).find(c => c.isDefault);
    const history = useHistory();
    const currentCollaborator = useSelector(({ collaborators }) => collaborators.currentCollaborator);

    const handleClick = () => {
        history.push(`/invoicing/${filterYear}/${enMonthsLower[monthInvoicing.month - 1]}`);
    };

    return (

        <Card
            className={classes.root}
            elevation={1}
        >
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={11}>
                    <CardContent className={classes.content} style={{ paddingBottom: "16px" }} onClick={handleClick}>
                        <Grid container>
                            <Grid container item xs={3} alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Typography className={classes.month}> {capitalize(t(enMonthsLower[monthInvoicing.month - 1]))} {filterYear} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={2} alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Typography> {formatAmount(monthInvoicing.cahtInvoiced, defaultCurrency)} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={2} alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Typography> {formatAmount(monthInvoicing.cahtToInvoice, defaultCurrency)} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={2} alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Typography> {formatAmount(monthInvoicing.cahtAwaitingPayment, defaultCurrency)} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={3} alignItems="center" justifyContent="center">
                                <Grid item>
                                    {
                                        monthInvoicing.count > 0
                                            ? monthInvoicing.statusCount.map((s, idx) => {
                                                const palette = getInvoiceStatusColor(s.code);
                                                return <Tooltip key={idx} title={<Typography variant="body2">{t(s.code)}<br></br>{formatAmount(s.amount, defaultCurrency)}</Typography>} placement="top" arrow>
                                                    <Chip
                                                        label={<Typography variant="body2" color="textSecondary"> {s.count} </Typography>}
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ borderColor: palette.color, backgroundColor: palette.lightColor }}
                                                        className={s.count !== 0 ? classes.statusChip : classes.hiddenStatusChip}
                                                    />
                                                </Tooltip>;
                                            })
                                            : <Typography variant="body2" color="textSecondary">{t('no_invoice')}</Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
                <Grid item xs={1}>
                    <CardActions>
                        <Grid container alignItems="center" justifyContent="center">
                            {!currentCollaborator?.isReadOnly && 
                            <Grid item>
                                <Tooltip title={t('add_invoice')} placement="top">
                                    <IconButton color="primary" onClick={() => openCreateInvoice(monthInvoicing.month)} >
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            }
                        </Grid>
                    </CardActions>
                </Grid>
            </Grid>
        </Card>

    );
}