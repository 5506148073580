import React from "react";
import RtTaxesPanel from "../RtCustomTabPanels/RtTaxesPanel/index";
import RtCurrenciesPanel from "../RtCustomTabPanels/RtCurrenciesPanel/index";

function CustomTabPanel({
  displayModal,
  actionModal,
  setActionModal,
  rTable,
  instances,
  loading,
  openCreateForm,
}) {
  switch (rTable.name) {
    case "taxes": 
      return  <RtTaxesPanel
                rTable={rTable}
                displayModal={displayModal}
                actionModal={actionModal}
                setActionModal={setActionModal}
                loading={loading}
                openCreateForm={openCreateForm}
                instances={instances}
              />;
    case "currencies": 
      return  <RtCurrenciesPanel
                rTable={rTable}
                displayModal={displayModal}
                actionModal={actionModal}
                setActionModal={setActionModal}
                loading={loading}
                openCreateForm={openCreateForm}
                instances={instances}
              />;
    default: 
      return <></>;
  }
}

export default CustomTabPanel;
