import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        display: "flex",
        marginBottom: "1%",
        width: "100%",
        boxShadow: theme.shadows[25],
    },
    content: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    chip: {
        borderRadius: "4px",
        marginRight: theme.spacing(0.5)
    },
    adminRole: {
        fontWeight: 600,
        color: "white",
        background: "#ef1d1d",
        padding: "5px 10px",
        opacity: 0.7,
        border: "2px solid red",
        borderRadius: "8px",
    },
    readOnly: {
        color: "white",
        border: "2px solid #0b73e3",
        opacity: "0.7",
        padding: "5px 10px",
        background: "#2d57ce",
        fontWeight: "600",
        borderRadius: "8px",
    },
    managerRole: {
        backgroundColor: "#57ca71",
        padding: "8px 21px",
        color: "white",
        borderRadius: "10px",
        border: "1px solid green",
        fontWeight: "600",
    }
}));