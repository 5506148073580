import * as keycloakParams from "./constants";

export const keycloakConfig = {
    realm: keycloakParams.KEYCLOAK_REALM,
    url: keycloakParams.KEYCLOAK_URL,
    "ssl-required": keycloakParams.KEYCLOAK_SSL_REQUIRED,
    clientId: keycloakParams.KEYCLOAK_CLIENT_ID,
    "public-client": keycloakParams.KEYCLOAK_PUBLIC_CLIENT,
};

export const initOptions = {
    onLoad: "login-required",
    promiseType: "native",
    checkLoginIframe: false,
};
