import { onError, onSuccess } from "../../../../utils/http";
import { errorNotification, successNotification } from "../../../../utils/notification";
import {
    DELETE_RT_INVOICE_TYPE,
    GET_ALL_RT_INVOICE_TYPE,
    POST_RT_INVOICE_TYPE,
    PUT_RT_INVOICE_TYPE,
    SET_ALL_RT_INVOICE_TYPE,
} from "../../constants";

import { FINANCIAL_RT_ENDPOINT } from "../../constants/endpoint";

export const getAllInvoiceType = () => {
    return {
        type: GET_ALL_RT_INVOICE_TYPE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/invoiceType`,
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setAllInvoiceType(response.data));
                return response;
            },
        },
    };
};

export const setAllInvoiceType = (data) => {
    return {
        type: SET_ALL_RT_INVOICE_TYPE,
        payload: data,
    };
};

export const postInvoiceType = (invoiceType) => {
    return {
        type: POST_RT_INVOICE_TYPE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/invoiceType`,
            method: 'post',
            data: invoiceType
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "create_success");
                store.dispatch(getAllInvoiceType());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    };
};

export const putInvoiceType = (id, invoiceType) => {
    return {
        type: PUT_RT_INVOICE_TYPE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/invoiceType/${id}`,
            method: 'put',
            data: invoiceType
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "update_success");
                store.dispatch(getAllInvoiceType());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "update_error");
                throw error;
            })
        }
    };
};

export const deleteinvoiceType = (id) => {
    return {
        type: DELETE_RT_INVOICE_TYPE,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/invoiceType/${id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "delete_success");
                store.dispatch(getAllInvoiceType());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', error.response.status === 409 ? "ressource_in_use_error" : "delete_error");
                throw error;
            })
        }
    };
};
