import React from 'react';
import { formatAmount, formatDate } from '../../../utils/converters';
import { get } from 'lodash';

export const monthInvoicingColumns = [
    {
        id: 0,
        name: 'month',
        apiName: 'month',
        width: 3,
        sort: false
    },
    {
        id: 1,
        name: 'caht_billed',
        apiName: 'cahtBilled',
        width: 2,
        sort: false
    },
    {
        id: 2,
        name: 'ca_provisional',
        apiName: 'caProvisional',
        width: 2,
        sort: false
    },
    {
        id: 3,
        name: 'ca_awaiting_payment',
        apiName: 'caAwaitingPayment',
        width: 2,
        sort: false
    },
    {
        id: 4,
        name: 'invoices',
        apiName: '',
        width: 3,
        sort: false
    },
];

export const invoiceColumns = [
    {
        id: 0,
        name: 'invoice_number',
        apiName: 'number',
        width: 1,
        sort: true,
        format: val => <span style={{fontWeight: "500"}}>{val}</span>
    },
    {
        id: 1,
        name: 'invoicing_entity',
        apiName: 'invoicingEntity.name',
        width: 1,
        sort: false,
        formatOrElse: (val, data) => val ?? get(data, "client.name", null)
    },
    {
        id: 2,
        name: 'client',
        apiName: 'client.name',
        width: 1,
        sort: true
    },
    {
        id: 3,
        name: 'amount_excl_tax',
        apiName: 'amountExclTax',
        width: 2,
        sort: true,
        format: (val, currency) => `${formatAmount(Number(val), currency)}`,
        type: "monetary"
    },
    {
        id: 4,
        name: 'amount_incl_tax',
        apiName: 'amountInclTax',
        width: 2,
        sort: true,
        format: (val, currency) => `${formatAmount(Number(val), currency)}`,
        type: "monetary"
    },
    {
        id: 5,
        name: 'issue_date',
        apiName: 'issueDate',
        width: 2,
        sort: true,
        format: formatDate
    },
    {
        id: 6,
        name: 'settlement_date',
        apiName: 'settlementDate',
        width: 2,
        sort: true,
        format: formatDate
    },
    {
        id: 7,
        name: 'status',
        apiName: 'status.name',
        width: 1,
        sort: false
    },
];

export const invoiceCloningColumns = [
    {
        id: 0,
        name: 'invoice_number',
        apiName: 'number',
        width: 1,
        sort: true,
        format: val => <span>{val}</span>
    },
    {
        id: 1,
        name: 'invoicing_entity',
        apiName: 'invoicingEntity.name',
        width: 2,
        sort: false,
        formatOrElse: (val, data) => val ?? get(data, "client.name", null)
    },
    {
        id: 2,
        name: 'client',
        apiName: 'client.name',
        width: 2,
        sort: true
    },
    {
        id: 3,
        name: 'amount_excl_tax',
        apiName: 'amountExclTax',
        width: 1,
        sort: true,
        format: (val, currency) => `${formatAmount(Number(val), currency)}`,
        type: "monetary"
    },
    {
        id: 4,
        name: 'amount_incl_tax',
        apiName: 'amountInclTax',
        width: 1,
        sort: true,
        format: (val, currency) => `${formatAmount(Number(val), currency)}`,
        type: "monetary"
    },
    {
        id: 5,
        name: 'status',
        apiName: 'status.name',
        width: 1,
        sort: false
    },
    {
        id: 6,
        name: 'expected_date',
        apiName: 'expectedDate',
        width: 2,
        editable: true,
        type: "date",
        sort: true,
        format: formatDate
    },
];