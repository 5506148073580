import React, { useState } from "react";
import { Typography, Button, Grid, Chip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ArrowDropUp } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import moment from "moment";

import { invoiceCloningColumns } from "../../../view/pages/Invoicing/columns";
import useStyles from "./style";
import { InvoiceCloningCard } from "../../../view/components/InvoicingCards/InvoiceCloningCard";
import CustomDialog from "../CustomDialog";
import { duplicateMultipleInvoices } from "../../../view/redux/actions/invoicing";
import CustomDatePicker from "../CustomDatePicker";
import { incrementMonth } from "../../../utils/invoice-helper";

export default function DuplicateModal({
    invoices = [],
    open = false,
    onChange = null,
    setSelected,
    recurringInvoices = [],
}) {
    const dispatch = useDispatch();
    const [duplicatedInvoices, setDuplicatedInvoices] = useState(recurringInvoices);
    const [expectedDate, setExpectedDate] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [saving, setSaving] = useState(false);
    const [cloningList, setCloningList] = useState(invoices.reduce((acc, invoice) => {
        acc[invoice.id] = incrementMonth(invoice.expectedDate);
        return acc;
    }, {}));
    const classes = useStyles();
    const { t } = useTranslation();

    const handleConfirmClick = () => {
        setDisabled(true);
        setSaving(true);
        dispatch(
            duplicateMultipleInvoices(
                Object.entries(cloningList).map(([invoiceId, newExpectedDate]) => ({
                    invoiceId,
                    newExpectedDate,
                }))
            )
        ).then((res) => {
            setSaving(false);
            if (res.status === 200) {
                setDuplicatedInvoices(res.data);
            }
            setSelected([]);
        });
    };
    const DuplicationDialogTitle = () => {
        if(recurringInvoices.length > 0){
            return <Typography variant="h6">{t("recurring_invoices_recap")} <Chip className={classes.countChip} label={recurringInvoices.length} /></Typography>;
        }else if(duplicatedInvoices.length > 0){
            return <Typography variant="h6">{t("cloned_invoices_recap")} <Chip className={classes.countChip} label={duplicatedInvoices.length} /></Typography>;
        }else{
            return <Typography variant="h6">{t("list_invoices_to_clone")} <Chip className={classes.countChip} label={invoices.length} /></Typography>;
        }
    };

    const handleOnChange = (date, id)=>{
        if (id) {
            setCloningList((prevList) => ({
                ...prevList,
                [id]: date,
            }));
        }
    };

    const handleDatesUpdate = (value) =>{
        const date = value ? moment(value).format("yyyy-MM-DD") : null;
        setExpectedDate(date);
        setCloningList(invoices.reduce((acc, invoice) => {
            acc[invoice.id] = date;
            return acc;
        }, {}));
    };

    const renderDuplicationDialogContent = () => (
        <Grid container>
            {(duplicatedInvoices.length  === 0) && <Grid container alignItems="center" item>
                <Typography className={classes.mainDateLabel}>
                    {t("apply_expected_date_to_all_invoices")}:
                </Typography>
                <CustomDatePicker
                    label={t("expected_date")}
                    onChange={handleDatesUpdate}
                    emptyLabel={t("expected_date")}
                    className={classes.datePicker}
                    selectedDate={expectedDate}
                    required={true}
                />
            </Grid>}
            <Grid container style={{marginTop:"2rem"}} justifyContent="flex-start" alignItems="center">
                <Grid item xs>
                    <Grid container className={classes.listHeader}>
                        {invoiceCloningColumns.map((col) => (
                            <Grid
                                key={col.id}
                                container
                                item
                                xs
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item>
                                    <Typography
                                        variant="overline"
                                        color="textSecondary"
                                        className={classes.colName}
                                    >
                                        {t(col.name)}
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="column" spacing={0}>
                                        <Grid item>
                                            <ArrowDropUp />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {duplicatedInvoices.length > 0
                    ? duplicatedInvoices
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((invoice) => (
                            <InvoiceCloningCard
                                key={invoice.id}
                                invoice={invoice}
                                columns={invoiceCloningColumns}
                            />
                        ))
                    : invoices
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((invoice) => (
                            <InvoiceCloningCard
                                editable={true}
                                cloningList={cloningList}
                                handleOnChange={handleOnChange}
                                key={invoice.id}
                                invoice={invoice}
                                columns={invoiceCloningColumns}
                            />
                        ))}
            </Grid>
        </Grid>
    );

    const renderDuplicationDialogActions = () => (
        <>
            {duplicatedInvoices.length > 0 ? (
                <>
                    <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={() => onChange && onChange(false)}
                    >
                        {t("close")}
                    </Button>
                </>
            ) : (
                <>
                    <Button
                        disableElevation
                        variant="contained"
                        color="default"
                        onClick={() => onChange && onChange(false)}
                    >
                        {t("cancel")}
                    </Button>

                    <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={handleConfirmClick}
                        disabled={disabled}
                    >
                        {t("confirm")}
                    </Button>
                </>
            )}
        </>
    );

    return (
        <CustomDialog
            open={open}
            onClose={() => onChange(false)}
            title={<DuplicationDialogTitle />}
            renderContent={renderDuplicationDialogContent}
            renderActions={renderDuplicationDialogActions}
            classes={{
                paper: classes.dialogPaper,
            }}
            saving={saving}
            disableBackdropClick={true}
        />
    );
}
