import React from "react";
import CustomDialog from "../../../App/components/CustomDialog";
import {useDispatch, useSelector} from "react-redux";
import useStyles from './style';
import {Button, Chip, Grid} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import { switchTenant} from "../../redux/actions/users";

function OrganizationChooser({open, setOpen}) {
    const tenants = useSelector(({ users }) => users.currentUserTenants);
    const classes = useStyles();
    const user = useSelector(({ Auth }) => Auth.user);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const close = () => {
        setOpen(false);
    }

    const switchOrganization = (newInstance) => () => {
        dispatch(switchTenant(user.id, newInstance)).then(() => {
            window.location.href = window.location.href;
        });
    }

    const renderContent = () => {
        return (
            <Grid container style={{flexDirection: "column", paddingBottom: "10px"}}>
                {tenants.map((tenant) => (
                    <Grid className={classes.organizationItem} item key={tenant.id}>
                        <Grid container
                            className={classes.organizationName}
                            justifyContent="space-between"
                            onClick={switchOrganization(tenant)}
                        >
                            <Typography>{tenant.name}</Typography>
                            {user.tenantId === tenant.id &&
                                <CheckCircleIcon color="primary"/>}
                        </Grid>
                        <hr style={{ margin: 0 }}/>
                    </Grid>
                ))}
            </Grid>
        )
    }

    const Title = () => {
        return (
            <Typography variant="h6">
                {t("choose_the_organization")}
            </Typography>
        )
    }

    return (
        <CustomDialog
            open={open}
            onClose={close}
            maxWidth="xs"
            className={classes.dialogPaper}
            title={<Title />}
            renderContent={renderContent}
            // renderActions={renderActions}
        />
    );
}

export default OrganizationChooser;