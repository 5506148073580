import { cloneDeep } from "lodash-es";
import moment from "moment";
import { onRequest, onSuccess } from "../../../../utils/http";
import {
    CLEAR_DASHBOARD_INVOICES_FILTER,
    CLEAR_DASHBOARD_INVOICES_PAGINATION,
    GET_FINANCIAL_INVOICING,
    GET_INVOICING_CLIENTS_PAYMENT_TIME,
    GET_TOP_INVOICING_CLIENTS,
    SET_DASHBOARD_INVOICES,
    SET_DASHBOARD_INVOICES_FILTER,
    SET_DASHBOARD_INVOICES_PAGINATION,
    SET_DASHBOARD_INVOICES_SORT,
    GET_TURNOVER_DATA,
    SET_INVOICING_CLIENTS_PAYMENT_TIME,
    SET_TOP_INVOICING_CLIENTS,
    SET_TURNOVER_DATA
} from "../../constants";
import { FINANCIAL_INVOICING_ENDPOINT, FINANCIAL_RT_ENDPOINT } from "../../constants/endpoint";

const adjustFilter = (filter) => {
    let f = cloneDeep(filter);
    
    Object.keys(f).forEach(k => {
        if(f[k] === "" || f[k] === null || f[k] === undefined){
            delete f[k];
        }else{
            switch(k){
                case 'status':
                    f[k] = f[k].map(s=>s.code ?? f[k]).join(",");
                    break;
                default:
                    break;
            }
        }
    });
    return f;
};

export const getTurnoverData = () => {
    return {
        type: GET_TURNOVER_DATA,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/turnoverData`
        },
        meta: {
            onRequest: onRequest(({ request, store }) => {
                const state = store.getState();
                const filter = state.dashboard.invoices.filter;
                
                request.params = {
                    startMonth: moment(filter.afterIssueDate).add(1, "days").format("yyyy-MM"),
                    endMonth: moment(filter.beforeIssueDate).subtract(1, "days").format("yyyy-MM")
                };
                
                return request;
            }),
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setTurnoverData(response.data));
                return response;
            })
        }
    };
};

export const setTurnoverData = (data) => {
    return {
        type: SET_TURNOVER_DATA,
        payload: data
    };
};

export const getTopInvoicingClients = (count) => {
    return {
        type: GET_TOP_INVOICING_CLIENTS,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/invoicingClient/top`
        },
        meta: {
            onRequest: onRequest(({ request, store }) => {
                const state = store.getState();
                const filter = state.dashboard.invoices.filter;
                
                if (count) {
                    request.params = {
                        startMonth: moment(filter.afterIssueDate).add(1, "days").format("yyyy-MM"),
                        endMonth: moment(filter.beforeIssueDate).subtract(1, "days").format("yyyy-MM"),
                        count: count
                    };
                } else {
                    request.params = {
                        startMonth: moment(filter.afterIssueDate).add(1, "days").format("yyyy-MM"),
                        endMonth: moment(filter.beforeIssueDate).subtract(1, "days").format("yyyy-MM")
                    };
                }
                
                return request;
            }),
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setTopInvoicingClients(response.data));
                return response;
            })
        }
    };
};

export const setTopInvoicingClients = (data) => {
    return {
        type: SET_TOP_INVOICING_CLIENTS,
        payload: data
    };
};

export const getInvoicingClientsPaymentTime = () => {
    return {
        type: GET_INVOICING_CLIENTS_PAYMENT_TIME,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/invoicingClient/paymentTime`
        },
        meta: {
            onRequest: onRequest(({ request, store }) => {
                const state = store.getState();
                const filter = state.dashboard.invoices.filter;
                
                request.params = {
                    startMonth: moment(filter.afterIssueDate).add(1, "days").format("yyyy-MM"),
                    endMonth: moment(filter.beforeIssueDate).subtract(1, "days").format("yyyy-MM")
                };
                
                return request;
            }),
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setInvoicingClientsPaymentTime(response.data));
                return response;
            })
        }
    };
};

export const setInvoicingClientsPaymentTime = (data) => {
    return {
        type: SET_INVOICING_CLIENTS_PAYMENT_TIME,
        payload: data
    };
};

export const setDashboardInvoices = (data) => {
    return {
        type: SET_DASHBOARD_INVOICES,
        payload: data
    };
};

export const setDashboardInvoicesSort = (sort) => {
    return {
        type: SET_DASHBOARD_INVOICES_SORT,
        payload: sort
    };
};

export const clearDashboardInvoicesPagination = () => {
    return {
        type: CLEAR_DASHBOARD_INVOICES_PAGINATION
    };
};

export const setDashboardInvoicesPagination = (pagination) => {
    return {
        type: SET_DASHBOARD_INVOICES_PAGINATION,
        payload: pagination
    };
};

export const getDashboardInvoices = () => {
    return {
        type: GET_FINANCIAL_INVOICING,
        request: {
            url: FINANCIAL_INVOICING_ENDPOINT,
        },
        meta: {
            onRequest: onRequest(({ request, store }) => {
                const state = store.getState();
                const filter = state.dashboard.invoices.filter;
                const sort = state.dashboard.invoices.list.sort;
                const pagination = state.dashboard.invoices.config.pagination;
                
                request.url = `${request.url}/invoices?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}&page=${pagination.page}&size=${pagination.size}`;

                request.params = adjustFilter(filter);

                return request;
            }),
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setDashboardInvoices(response.data));
                return response;
            })
        }
    };
};

export const setDashboardInvoicesFilter = (filter) => {
    return {
        type: SET_DASHBOARD_INVOICES_FILTER,
        payload: filter 
    };
};

export const clearDashboardInvoicesFilter = () => {
    return {
        type: CLEAR_DASHBOARD_INVOICES_FILTER
    };
};