import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    listHeader: {
        padding: theme.spacing(0, 2, 2, 2)
    },
    groupedActions: {
        padding: theme.spacing(0, 1, 2, 0)
    },
    deleteIcon: {
        color: theme.palette.error.main
    },
    skeleton: {
        borderRadius: "4px"
    },
    dialogPaper: {
        width: "35%",
    },
    invoiceNumberChip: {
        borderRadius: "4px",
        margin: theme.spacing(0, 1, 1, 0)
    },
    inputField: {
        "& label": {
            marginTop: "-8px"
        },
        "& label.MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, 3px) scale(0.75)"
        },
        "& .MuiInputBase-root": {
            padding: 0,
        }
    },
    endAdornment: {
        '& p': {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            fontSize: "16px",
            textAlign: "center",
            height: "36px",
            width: "30px",
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
            position: "relative",
            padding: "8px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"    
        }
    },
    statsBox: {
        borderRadius: 4,
        backgroundColor: "#eee",
        boxShadow: "none",
        height: "85%"
    },
    amounts: {
        justifyContent: "space-between",
    }
}));