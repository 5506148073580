import React from 'react';
import { BREADCRUMBS, MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE, enMonthsLower } from '../../../utils/constants';

const Users = React.lazy(() => import('../../pages/Users'));

export default [
    {
        path: `/users`,
        exact: true,
        name: 'users',
        component: Users,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        private: true,
        className: '',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true,
            },
            show: true
        }
    }
];