import React from 'react';
import { BREADCRUMBS, enMonthsLower, MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE } from '../../../utils/constants';

const Invoicing = React.lazy(() => import('../../pages/Invoicing'));
const Invoice = React.lazy(() => import('../../pages/Invoice'));
const CreateInvoice = React.lazy(() => import('../../pages/CreateInvoice'));

export default [
    {
        path: `/invoicing/create`,
        exact: true,
        name: 'new_invoice',
        component: CreateInvoice,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-invoicing',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    },
    {
        path: `/invoicing/:year`,
        exact: true,
        name: 'year_invoicing',
        component: Invoicing,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-invoicing',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.url_params,
                params: ["year"],
                display: true
            },
            show: true
        }
    },
    {
        path: `/invoicing/:year/invoices`,
        exact: true,
        name: 'invoices',
        component: Invoicing,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-invoicing',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    },
    {
        path: `/invoicing/:year/:month(${enMonthsLower.join('|')})`,
        exact: true,
        name: 'month',
        component: Invoicing,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-invoicing',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.url_params,
                params: ["month"],
                display: false
            },
            show: true
        }
    },
    {
        path: `/invoicing/:year/:month(${enMonthsLower.join('|')})/invoices`,
        exact: true,
        name: 'invoices',
        component: Invoicing,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-invoicing',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.url_params,
                params: ["month"],
                display: true
            },
            show: true
        }
    },
    {
        path: `/invoicing/:year/:month(${enMonthsLower.join('|')})/invoices/:id([0-9]+)`,
        exact: true,
        name: 'invoice',
        component: Invoice,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-invoicing',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    }
];