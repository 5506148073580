import React, { useEffect, useRef, useState } from 'react';
import { fr } from 'moment/locale/fr';
import 'chartjs-adapter-moment';
import useStyles from './style';
import {
    Checkbox,
    Grid,
    Paper,
    Typography,
    Button,
    Box,
    Switch
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Line } from "react-chartjs-2";
import { formatAmount } from '../../../utils/converters';
import { useSelector } from 'react-redux';



const options = (defaultCurrency) => ({
    scales: {
        xAxes: [{
            type: 'time',
            distribution: 'linear',
            time: {
                tooltipFormat: 'MMMM yyyy',
                minUnit: 'month',
                displayFormats: {
                    month: 'MMM YY'
                }
            },
            ticks: {
                autoSkip: true,
                maxTicksLimit: 20
            },
            gridLines: {
                color: "#eaeaea",
                zeroLineWidth: 0
            },
            adapters: {
                date: {
                    locale: fr
                }
            }
        }],
        yAxes: [{
            gridLines: {
                color: "#eaeaea",
                zeroLineWidth: 0
            },
            ticks: {
                // eslint-disable-next-line no-unused-vars
                callback: function (label, index, labels) {
                    return formatAmount(label, defaultCurrency);
                }
            }
        }],
    },
    legend: {
        display: false
    },
    layout: {
        padding: {
            right: 10
        }
    },
    tooltips: {
        mode: "index",
        intersect: false,
        backgroundColor: "#fff",
        titleFontColor: "#555",
        bodyFontColor: "#555",
        borderColor: "#777",
        borderWidth: 1,
        caretSize: 6,
        xPadding: 10,
        yPadding: 10,
        bodySpacing: 10,
        titleMarginBottom: 10,
        displayColors: false,
        callbacks: {
            // eslint-disable-next-line no-unused-vars
            label: function (tooltipItem, data) {
                return formatAmount(tooltipItem.yLabel, defaultCurrency);
            },
            labelTextColor: function (tooltipItem, chart) {
                return chart.config.data.datasets[tooltipItem.datasetIndex].borderColor;
            }
        }
    },
    hover: {
        mode: "index",
        intersect: false
    }
})

function TurnoverChart() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [chartData, setChartData] = useState([]);
    const turnoverData = useSelector(({ dashboard }) => dashboard.turnoverData);
    const defaultCurrency = useSelector(({ rt }) => rt.currencies).find(c => c.isDefault);
    const inputEl = useRef(null);
    const [legendRendered, setLegendRendered] = useState(false);
    const [, forceUpdate] = useState();
    const [displayAccumulatedTurnover, setDisplayAccumulatedTurnover] = useState(false);

    function calculateAccumulatedTurnover(data) {
        let accumulatedTurnover = 0;
        let accumulatedExpectedTurnover = 0;
        let accumulatedPendingTurnover = 0;
        let accumulatedCollectedTurnover = 0;
        return data.map((entry) => {
            accumulatedTurnover += entry.turnover;
            accumulatedExpectedTurnover += entry.expectedTurnover;
            accumulatedPendingTurnover += entry.pendingTurnover;
            accumulatedCollectedTurnover += entry.collectedTurnover;
            return {
                ...entry,
                turnover: accumulatedTurnover,
                expectedTurnover: accumulatedExpectedTurnover,
                pendingTurnover: accumulatedPendingTurnover,
                collectedTurnover: accumulatedCollectedTurnover
            };
        });
    }

    useEffect(() => {
        const data = displayAccumulatedTurnover ? calculateAccumulatedTurnover(turnoverData.list) : turnoverData.list;
        setChartData({
            turnover: data.map(t => { return { x: t.month, y: t.turnover }; }),
            pendingTurnover: data.map(t => { return { x: t.month, y: t.pendingTurnover }; }),
            expectedTurnover: data.map(t => { return { x: t.month, y: t.expectedTurnover }; }),
            collectedTurnover: data.map(t => {return { x: t.month, y: t.collectedTurnover }; }),
        });
    }, [turnoverData, displayAccumulatedTurnover]);

    const handleLegendClick = (datasetIndex) => {
        const chart = inputEl.current.chartInstance;
        chart.getDatasetMeta(datasetIndex).hidden =
            chart.getDatasetMeta(datasetIndex).hidden === null ? true : !chart.getDatasetMeta(datasetIndex).hidden;
        chart.update();
        forceUpdate({});
    };

    const plugins = [
        {
            afterDatasetsDraw() {
                if (!legendRendered) {
                    setLegendRendered(true);
                }
            },
        },
        {
            // eslint-disable-next-line no-unused-vars
            afterDraw: function (chart, easing) {
                if (chart.tooltip._active && chart.tooltip._active.length && chart.scales['y-axis-0']) {
                    const activePoint = chart.controller.tooltip._active[0];
                    const ctx = chart.ctx;
                    const x = activePoint.tooltipPosition().x;
                    const topY = chart.scales['y-axis-0'].top;
                    const bottomY = chart.scales['y-axis-0'].bottom;
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = '#777';
                    ctx.showBlur = 1;
                    ctx.globalCompositeOperation = 'color-burn';
                    ctx.stroke();
                    ctx.restore();
                }
            }
        }
    ];

    const data = {
        labels: [],
        datasets: [
            {
                label: t('chart_legend_net_turnover'),
                fill: true,
                lineTension: 0.1,
                borderColor: "#665191",
                pointBorderColor: "#665191",
                pointBackgroundColor: "#665191",
                pointHoverBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointRadius: 3,
                pointHitRadius: 10,
                data: chartData.turnover
            },
            {
                label: t('chart_legend_pending_turnover'),
                fill: true,
                lineTension: 0.1,
                borderColor: "#da1e28",
                pointBorderColor: "#da1e28",
                pointBackgroundColor: "#da1e28",
                pointHoverBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointRadius: 3,
                pointHitRadius: 10,
                data: chartData.pendingTurnover
            },
            {
                label: t('chart_legend_expected_turnover'),
                fill: true,
                lineTension: 0.1,
                borderColor: "#daab1e",
                pointBorderColor: "#daab1e",
                pointBackgroundColor: "#daab1e",
                pointHoverBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointRadius: 3,
                pointHitRadius: 10,
                data: chartData.expectedTurnover
            },
            {
                label: t('chart_legend_collected_turnover'),
                fill: true,
                lineTension: 0.1,
                borderColor: "#4bc06b",
                pointBorderColor: "#4bc06b",
                pointBackgroundColor: "#4bc06b",
                pointHoverBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointRadius: 3,
                pointHitRadius: 10,
                data: chartData.collectedTurnover
            }
        ]
    };

    return (
        <Paper className={classes.paper}>
            <Grid container justifyContent="space-between">
                <Grid item container>
                    <Typography color="textSecondary" className={classes.title}>
                        {t('turnover_evolution')}
                    </Typography>
                </Grid>
                <Grid item container justifyContent="space-between" alignItems="center">
                    {legendRendered && (
                        <Grid item>
                            {inputEl.current.chartInstance.legend.legendItems.map((tick, index) => {
                                const chart = inputEl.current.chartInstance;
                                const isHidden = chart.getDatasetMeta(tick.datasetIndex).hidden === null ? false : chart.getDatasetMeta(tick.datasetIndex).hidden;
                                return (
                                    <Button
                                        key={index}
                                        style={{ backgroundColor: tick.strokeStyle }}
                                        size="small"
                                        onClick={() => handleLegendClick(tick.datasetIndex)}
                                        className={classes.legendButton}
                                    >
                                        <Typography variant="caption"> {tick.text} </Typography>
                                        <Checkbox
                                            checked={!isHidden}
                                            size="small"
                                            className={classes.legendCheckbox}
                                            style={{ color: "#fff" }}
                                        />
                                    </Button>
                                );
                            })}
                        </Grid>
                    )}
                    <Grid item>
                        <Box className={classes.accumulatedSwitch}>
                            <Typography>{t("cumulated")}</Typography>
                            <Switch
                                checked={displayAccumulatedTurnover}
                                size="small"
                                onChange={(e) => setDisplayAccumulatedTurnover(e.target.checked)}
                                style={{ color: "#fff" }}/>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.chartContainer}>
                <Line
                    data={data}
                    responsive={true}
                    options={{ ...options(defaultCurrency), maintainAspectRatio: false }}
                    ref={inputEl}
                    plugins={plugins}
                />
            </div>
        </Paper>
    );
}

export default TurnoverChart;
